export const RepeatMail = [
  { label: "Week (s)", value: "week" },
  { label: "Month (s)", value: "month" },
  // { label: "Quarter" },
  // { label: "Year" },
];

export const TitleMail = [
  { label: "Mr." },
  { label: "Mrs." },
  { label: "Ms." },
  { label: "Miss." },
];

export const AddMailEnd = [
  { label: "None", value: "none" },
  { label: "After", value: "after" },
];

export const NavHeaderMail = [
  {
    id: "0",
    label: "Email",
    value: "email",
  },
  // {
  //   id: "1",
  //   label: "Template",
  //   value: "template",
  // },
];

export const SelectFieldsExport = [
  {
    label: "Check-in & Check-out time",
    value: "period",
  },
  {
    label: "Break time",
    value: "breaktime",
  },
  {
    label: "Total work hours",
    value: "totalWorkHouses",
  },
  {
    label: "Overtime",
    value: "overtime",
  },
  {
    label: "Paid absences",
    value: "paidAbsences",
  },
];

export const SelectTimeExport = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
];

export const Gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const Dates = [
  { label: "Mon", value: "mon" },
  { label: "Tue", value: "tue" },
  { label: "Wed", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
  { label: "Sunday", value: "sun" },
];

export enum MESSAGES {
  ERROR = "Error",
  SUCCESS = "Success",
}

export const Month = [
  {
    month: "01",
    label: "January",
  },
  {
    month: "02",
    label: "February",
  },
  {
    month: "03",
    label: "March",
  },
  {
    month: "04",
    label: "April",
  },
  {
    month: "05",
    label: "May",
  },
  {
    month: "06",
    label: "June",
  },
  {
    month: "07",
    label: "July",
  },
  {
    month: "08",
    label: "August",
  },
  {
    month: "09",
    label: "September",
  },
  {
    month: "10",
    label: "October",
  },
  {
    month: "11",
    label: "November",
  },
  {
    month: "12",
    label: "December",
  },
];

export const AbsenceType = [
  { label: "Paid absence", val: "paid" },
  { label: "Unpaid absence", val: "unpaid" },
];
