import { Dialog, Divider, Grid, TextField } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import Avatar from "../../../../assets/images/avatar-df.png";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { TimesheetService } from "../../../../services";
import { formatEnDE, FormUIUtil, renderErrorImage } from "../../../../utils";
import { TimesheetSubIcon } from "../../../components/Icons/TimesheetSubIcon";
import ModalFooter from "../../../components/ModalFooter";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../../constants";
import { MESSAGES } from "../../../constants/mock";
import { DEFAULT_COLOR } from "../../../core/Layout/routes";
import "./index.scss";

type P_Props = {
  onClose: () => void;
  data: any;
  profile: any;
};

const TimesheetModal: React.FC<P_Props> = (props) => {
  const [pushNoti] = useNoti();
  const editTimeshift = async (values: any) => {
    let val = {
      timeshiftId: props.data?._id,
      date: props.data?.date,
      employeeId: props.profile?._id,
      checkin: values.checkin || null,
      checkout: values.checkout || null,
      startTime: values.startTime || null,
      endTime: values.endTime || null,
    };

    try {
      const res = await TimesheetService.updateHistory(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        props.onClose();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  return (
    <Dialog maxWidth='md' open={true}>
      <div className='edit-timeshift'>
        <SimpleModalHeader title='Edit Timeshift' onClose={props.onClose} />
        <div className='edit-timeshift__content'>
          <div className='item-name'>
            <img
              src={Avatar}
              alt={DEFAULT_IMG_ALT}
              onError={renderErrorImage()}
            />
            <div>
              {props.profile?.fullName || TEXT_HOLDER}
              <div className='item-code'>
                {props.profile?.code || TEXT_HOLDER}
              </div>
            </div>
          </div>
          <Divider style={{ borderColor: "#E0E6F0" }} />
          <div className='edit-timeshift__content__time'>
            <TimesheetSubIcon
              color={DEFAULT_COLOR}
              size={[18, 18]}
              viewBox={[20, 20]}
            />
            &emsp;{formatEnDE(props.data.date)}
          </div>
          <Divider style={{ borderColor: "#E0E6F0", marginBottom: 39 }} />
          <Form
            onSubmit={async (values) => editTimeshift(values)}
            initialValues={{
              ...props.data,
              checkin: props.data.checkin?.slice(0, 5),
              checkout: props.data.checkout?.slice(0, 5),
            }}
          >
            {({ handleSubmit }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  id='edit-timeshift'
                  className='edit-timeshift__form'
                >
                  <Grid
                    container
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Grid item xs={5}>
                      <Field
                        name='checkin'
                        // validate={FormUtil.Rule.required(
                        //   "Please enter Checkin"
                        // )}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Checkin",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Place holder"}
                              color='success'
                              className='form-text-field'
                              inputProps={{
                                className: "input",
                              }}
                              variant='outlined'
                              onChange={(e) => input.onChange(e.target.value)}
                              type='time'
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />,
                            true
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      &#8212;
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name='checkout'
                        // validate={FormUtil.Rule.required(
                        //   "Please enter Checkout"
                        // )}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Checkout",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Place holder"}
                              color='success'
                              className='form-text-field'
                              inputProps={{ className: "input" }}
                              variant='outlined'
                              onChange={(e) => input.onChange(e.target.value)}
                              type='time'
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />,
                            true
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className='edit-timeshift__form__breaktime'
                    >
                      Breaktime
                      <Divider
                        style={{
                          borderColor: "#E0E6F0",
                          margin: "0.8rem 0 1.6rem",
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name='startTime'
                        // validate={FormUtil.Rule.required(
                        //   "Please enter Start time"
                        // )}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Start time",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Place holder"}
                              color='success'
                              className='form-text-field'
                              inputProps={{ className: "input" }}
                              variant='outlined'
                              onChange={(e) => input.onChange(e.target.value)}
                              type='time'
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />,
                            true
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={2}>
                      &#8212;
                    </Grid>
                    <Grid item xs={5}>
                      <Field
                        name='endTime'
                        // validate={FormUtil.Rule.required(
                        //   "Please enter End time"
                        // )}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "End time",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Place holder"}
                              color='success'
                              className='form-text-field'
                              inputProps={{ className: "input" }}
                              variant='outlined'
                              onChange={(e) => input.onChange(e.target.value)}
                              type='time'
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />,
                            true
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId='edit-timeshift'
          showDeleteButton={""}
          handleDeleteAction={() => {}}
          handleCancelAction={props.onClose}
          title={"Save"}
          handleSubmit={() => {}}
        />
      </div>
    </Dialog>
  );
};

export default TimesheetModal;
