import { PriorityHighRounded as PriorityHighRoundedIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { EmailService } from "../../../../services";
import FloatingBtn from "../../../components/FloatingBtn";
import { EditIcon } from "../../../components/Icons/EditIcon";
import { RemoveIcon } from "../../../components/Icons/RemoveIcon";
import Loading from "../../../components/Loading";
import NotificationModal from "../../../components/NotificationModal";
import DefaultSwitch from "../../../components/Switch";
import { TEXT_HOLDER } from "../../../constants";
import { MESSAGES } from "../../../constants/mock";
import { DEFAULT_COLOR } from "../../../core/Layout/routes";
import MailModal from "../MailModal";

type P_Props = {
  setCount: (num: number) => void;
  searchValue: string | undefined;
};

const MailListing: React.FC<P_Props> = ({ setCount, searchValue }) => {
  const [pushNoti] = useNoti();
  const [notiDelete, setNotiDelete] = useState<{ id: string; email: string }>({
    id: "",
    email: "",
  });
  // const [status, setStatus] = useState<boolean>(false);
  const [listEmail, setListEmail] = useState<any[]>([]);
  const [mailModal, setMailModal] = useState<{ visible: boolean; data: any }>({
    visible: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const result = await EmailService.getEmail();
      if (result.data.data) {
        setListEmail(result.data.data);
        setCount(result.data.data?.length);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailModal]);

  const activateMail = async (id: string, status: boolean) => {
    try {
      const res = await EmailService.activeEmail({
        _id: id,
        status: status,
      });
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({ id: "", email: notiDelete.email });
        fetchData();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const deleteEmail = async (id: string) => {
    try {
      const res = await EmailService.deleteEmail(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        fetchData();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      console.log(error);
    }
  };

  return (
    <div className='setting-listing__content__right__list'>
      <div className='header-list-page'>
        <div className='setting-listing__content__right__list__email'>
          Email
        </div>
        <div className='setting-listing__content__right__list__title'>
          Title
        </div>
        <div className='setting-listing__content__right__list__name'>
          Receiver name
        </div>
        <div className='setting-listing__content__right__list__auto-email'>
          Auto send mail
        </div>
        <div className='setting-listing__content__right__list__status'>
          Status
        </div>
        <div className='setting-listing__content__right__list__edit'></div>
        <div className='setting-listing__content__right__list__del'></div>
      </div>

      {listEmail ? (
        (searchValue
          ? listEmail?.filter(
              (f) =>
                f.code?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                f.receiverName
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase()) ||
                f.email?.toLowerCase().includes(searchValue?.toLowerCase())
            )
          : listEmail
        )?.map((email, idx: number) => {
          return (
            <div key={idx} className='item-list-page'>
              <div className='setting-listing__content__right__list__email'>
                {email.email || TEXT_HOLDER}
              </div>
              <div className='setting-listing__content__right__list__title'>
                {email.title || TEXT_HOLDER}
              </div>
              <div className='setting-listing__content__right__list__name'>
                {email.receiverName || TEXT_HOLDER}
              </div>
              <div
                className='setting-listing__content__right__list__auto-email auto-send'
                style={{ opacity: !email.autoSendMail?.status ? 0.5 : 1 }}
              >
                {email.autoSendMail
                  ? "Every " +
                    email.autoSendMail?.number +
                    " " +
                    email.autoSendMail?.unit
                  : TEXT_HOLDER}
              </div>
              <div className='setting-listing__content__right__list__status'>
                <DefaultSwitch
                  checked={email.autoSendMail?.status}
                  onChange={() => {
                    activateMail(email._id, !email.autoSendMail?.status);
                  }}
                />
              </div>
              <div className='setting-listing__content__right__list__edit'>
                <div
                  style={{ width: "fit-content" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMailModal({ visible: true, data: email });
                  }}
                >
                  <EditIcon
                    color={DEFAULT_COLOR}
                    size={[18, 18]}
                    viewBox={[18, 18]}
                  />
                </div>
              </div>
              <div className='setting-listing__content__right__list__del'>
                <div
                  style={{ width: "fit-content" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setNotiDelete({ id: email._id, email: email.email });
                  }}
                >
                  <RemoveIcon
                    color={"#272B2F"}
                    size={[18, 18]}
                    viewBox={[18, 18]}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Loading />
      )}

      <NotificationModal
        visible={notiDelete.id !== ""}
        title={"Delete Email"}
        subTitle={
          <span>
            Are you sure you want to delete email
            <span style={{ fontWeight: 700 }}> {notiDelete.email}</span>?
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className='btn-cancel-style'
              onClick={() => {
                setNotiDelete({ id: "", email: notiDelete.email });
              }}
            >
              Cancel
            </button>
            <button
              className='btn-confirm-style'
              onClick={() => {
                deleteEmail(notiDelete.id);
              }}
            >
              Delete
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete({ id: "", email: notiDelete.email });
        }}
      />

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          setMailModal({ visible: true, data: null });
        }}
        btns={[]}
      />

      {mailModal.visible && (
        <MailModal
          onClose={() => {
            setMailModal({ visible: false, data: mailModal.data });
          }}
          data={mailModal.data}
        />
      )}
    </div>
  );
};

export default MailListing;
