import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../../assets/images/avatar-df.png";
import { useQuery } from "../../../hooks/useQuery";
import { EmployeeService } from "../../../services";
import {
  YearList,
  formatEnDENoWeek,
  getMonday,
  months,
  renderErrorImage,
  checkLinkImage,
} from "../../../utils";
import ExportFile from "../../components/ExportFile";
import { AddUserIcon } from "../../components/Icons/AddUserIcon";
import { CalendarIcon } from "../../components/Icons/CalendarIcon";
import { ExportIcon } from "../../components/Icons/ExportIcon";
import { LocationIcon } from "../../components/Icons/LocationIcon";
import { DEFAULT_IMG_ALT } from "../../constants";
import { Month } from "../../constants/mock";
import { APP_ROUTES } from "../../constants/module";
import { ACTIVE_COLOR, DEFAULT_COLOR } from "../../core/Layout/routes";
import TimesheetHistoryDetail from "./Detail";
import TimesheetHistoryListing from "./Listing";
import "./index.scss";

const TimesheetHistory: React.FC = () => {
  const history = useHistory();
  const query = useQuery();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [range, setRange] = useState<any>({
    startTime: moment(),
    endTime: moment(),
  });
  const [month, setMonth] = useState<string>(
    Month.find((f) => f.month === ("0" + (new Date().getMonth() + 1)).slice(-2))
      ?.label as string
  );
  const monthNow = months(new Date()).find(
    (f: any) => f !== Month.find((f) => f.label === month)?.month
  );
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    Month.find((f) => f.month === ("0" + (new Date().getMonth() + 1)).slice(-2))
      ?.label +
      ", " +
      new Date().getFullYear().toString()
  );

  const period =
    time
      ?.replace(", ", "-")
      .slice(time?.replace(", ", "-").search("-") + 1, time?.length) +
    "-" +
    Month.find((f) =>
      f.label.includes(
        time?.replace(", ", "-").slice(0, time?.replace(", ", "-").search("-"))
      )
    )?.month;

  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [employ, setEmploy] = useState<any>(null);
  const [openExport, setOpenExport] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchData = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (monthNow) {
      setMonth(Month.find((f) => f.month === monthNow)?.label as string);
      setTime(Month.find((f) => f.month === monthNow)?.label + ", " + year);
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!query.get("period")]);

  useEffect(() => {
    if (query.get("id")) {
      setEmploy(listEmployee.find((f) => f._id === query.get("id")));
    } else {
      setEmploy(null);
    }
    if (query.get("timeOfCycle")) {
      setRange({
        startTime: moment(query.get("timeOfCycle")?.slice(0, 10)),
        endTime: moment(
          query.get("timeOfCycle")?.slice(13, query.get("timeOfCycle")?.length)
        ),
      });
    }
    if (query.get("period")) {
      setMonth(
        Month.find(
          (f) =>
            f.month ===
            query
              .get("period")
              ?.slice(
                (query.get("period")?.length as any) - 2,
                query.get("period")?.length
              )
        )?.label as string
      );
      setTime(
        Month.find(
          (f) =>
            f.month ===
            query
              .get("period")
              ?.slice(
                (query.get("period")?.length as any) - 2,
                query.get("period")?.length
              )
        )?.label +
          ", " +
          query.get("period")?.slice(0, 4)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("id"), listEmployee]);

  useEffect(() => {
    if (period && query.get("id") && !query.get("timeOfCycle")) {
      history.push(
        APP_ROUTES.TIMESHEET + "?id=" + query.get("id") + "&period=" + period
      );
    } else {
      history.push(APP_ROUTES.TIMESHEET + "?period=" + period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (range && query.get("id") && query.get("timeOfCycle")) {
      history.push(
        APP_ROUTES.TIMESHEET +
          "?id=" +
          query.get("id") +
          "&timeOfCycle=" +
          range.startTime.format("YYYY-MM-DD") +
          " - " +
          range.endTime.format("YYYY-MM-DD") +
          "&period=" +
          query.get("period")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  const _filterOptions = createFilterOptions();
  const filterOptions = (options: any, state: any) => {
    const result = _filterOptions(options, state);

    if (result.length === 0) {
      return _filterOptions(options, {
        ...state,
        getOptionLabel: (o: any) => o.code,
      });
    }

    return result;
  };

  return (
    <div className='time-sheet-history'>
      <div className='time-sheet-history__content'>
        <div className='time-sheet-history__content__action'>
          <div style={{ display: "flex", alignItems: "center" }}>
            {query.get("id") && (
              <ArrowBackIosRoundedIcon
                fontSize='large'
                style={{ marginRight: 18, color: "#6C778D", cursor: "pointer" }}
                onClick={() => {
                  if (query.get("period")) {
                    history.push(
                      APP_ROUTES.TIMESHEET + "?period=" + query.get("period")
                    );
                  } else {
                    history.push(APP_ROUTES.TIMESHEET);
                  }
                  setEmploy(null);
                }}
              />
            )}
            {query.get("timeOfCycle") && (
              <div className='range-picker-cpn'>
                <button
                  className='btn-cancel-style btn-back'
                  onClick={() => {
                    setRange({
                      startTime: moment(range?.startTime)
                        .subtract(7, "days")
                        .set({ hours: 0, minutes: 0, seconds: 0 }),
                      endTime: moment(getMonday(range?.startTime))
                        .subtract(1, "days")
                        .set({
                          hours: 23,
                          minutes: 59,
                          seconds: 59,
                        }),
                    });
                  }}
                >
                  <ArrowBackIosRoundedIcon />
                </button>
                <div className='btn-cancel-style' style={{ marginLeft: 0 }}>
                  <CalendarMonthOutlinedIcon
                    fontSize='large'
                    style={{ color: "#00529C" }}
                  />
                  &ensp;
                  {formatEnDENoWeek(range.startTime) +
                    " - " +
                    formatEnDENoWeek(range.endTime)}
                </div>
                <button
                  className='btn-cancel-style btn-forward'
                  onClick={() => {
                    setRange({
                      startTime: moment(range?.startTime)
                        .add(1, "w")
                        .set({ hours: 0, minutes: 0, seconds: 0 }),
                      endTime: moment(range?.endTime)
                        .add(1, "w")
                        .set({ hours: 23, minutes: 59, seconds: 59 }),
                    });
                  }}
                >
                  <ArrowForwardIosRoundedIcon />
                </button>
              </div>
            )}
            {!query.get("timeOfCycle") && (
              <button
                onClick={handleClick}
                className='btn-cancel-style btn--shadow time-sheet-history__content__action__btn-time'
              >
                <CalendarIcon
                  color={DEFAULT_COLOR}
                  size={[20, 20]}
                  viewBox={[20, 20]}
                />
                <span className='time-sheet-history__content__action__btn-time__text'>
                  {time}
                </span>
                <ArrowDropDownIcon />
              </button>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <Autocomplete
              disableClearable
              options={listEmployee}
              sx={{ width: 250, height: 45 }}
              getOptionLabel={(options) => options.fullName}
              filterOptions={filterOptions}
              value={employ}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={() => {
                    if (query.get("timeOfCycle")) {
                      history.push(
                        APP_ROUTES.TIMESHEET +
                          "?id=" +
                          option._id +
                          "&timeOfCycle=" +
                          query.get("timeOfCycle") +
                          "&period=" +
                          query.get("period")
                      );
                    } else {
                      history.push(
                        APP_ROUTES.TIMESHEET +
                          "?id=" +
                          option._id +
                          "&period=" +
                          period
                      );
                    }
                    setOpen(false);
                    setEmploy(option);
                  }}
                >
                  <div className='autocomplete-check'>
                    <img
                      src={checkLinkImage(option?.avatar as string) || Avatar}
                      alt={DEFAULT_IMG_ALT}
                      onError={renderErrorImage()}
                    />
                    <div className='autocomplete-check__name'>
                      {option.fullName}
                      <div className='autocomplete-check__code'>
                        {option.code}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Select employee'
                  className='autocomplete-check__text-field'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <span style={{ marginLeft: 10, marginTop: 1 }}>
                          <AddUserIcon
                            color={DEFAULT_COLOR}
                            size={[25, 18]}
                            viewBox={[25, 18]}
                          />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Select
              defaultValue={"Excel Technologies"}
              input={<OutlinedInput className='form-text-field' />}
              variant='outlined'
              className='form-select-field'
              MenuProps={{
                className: "select-menu-list",
              }}
              style={{ width: "fit-content", marginLeft: 8 }}
              inputProps={{ readOnly: true }}
              displayEmpty
              renderValue={(value) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <LocationIcon
                      color={ACTIVE_COLOR}
                      size={[24, 24]}
                      viewBox={[24, 24]}
                    />
                    {value}
                  </Box>
                );
              }}
            ></Select>
            <button
              className='btn-cancel-style btn--shadow'
              onClick={() => {
                setOpenExport(true);
              }}
            >
              <ExportIcon
                color={ACTIVE_COLOR}
                size={[24, 24]}
                viewBox={[24, 24]}
              />
              &ensp;Export
            </button>
          </div>
        </div>
        <div className='time-sheet-history__content__list'>
          {!query.get("id") ? (
            <TimesheetHistoryListing
              period={
                time
                  ?.replace(", ", "-")
                  .slice(
                    time?.replace(", ", "-").search("-") + 1,
                    time?.length
                  ) +
                "-" +
                Month.find((f) =>
                  f.label.includes(
                    time
                      ?.replace(", ", "-")
                      .slice(0, time?.replace(", ", "-").search("-"))
                  )
                )?.month
              }
              employeeIds={listEmployee.map((e) => {
                return e._id;
              })}
            />
          ) : (
            <TimesheetHistoryDetail />
          )}
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className='popover-time'>
          <div className='popover-time__text'>{month + ", " + year}</div>
          <div className='popover-time__select'>
            <Select
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              className='form-select-field'
              style={{ marginRight: 5 }}
              MenuProps={{
                disableScrollLock: true,
                sx: {
                  height: 200,
                  ".MuiButtonBase-root": {
                    borderRadius: "4px",
                    fontSize: 14,
                    fontFamily: "Nunito Sans",
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "#00529C",
                    color: "#ffffff",
                    padding: "8px",
                    margin: "4px 12px",
                  },
                },
              }}
            >
              {Month.map((m: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={m.label}>
                    {m.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Select
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
              className='form-select-field'
              style={{ marginLeft: 5 }}
              MenuProps={{
                disableScrollLock: true,
                sx: {
                  height: 200,
                  ".MuiButtonBase-root": {
                    borderRadius: "4px",
                    fontSize: 14,
                    fontFamily: "Nunito Sans",
                    width: 89,
                  },
                  "&& .Mui-selected": {
                    backgroundColor: "#00529C",
                    color: "#ffffff",
                    padding: "8px",
                    margin: "4px 12px",
                  },
                },
              }}
            >
              {YearList().map((y: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={y}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className='popover-time__btn'>
            <button className='btn-cancel-style' onClick={handleClose}>
              Cancel
            </button>
            <button
              className='btn-confirm-style'
              onClick={() => {
                handleClose();
                setTime(month + ", " + year);
              }}
            >
              Done
            </button>
          </div>
        </div>
      </Popover>

      {openExport && (
        <ExportFile
          onClose={() => {
            setOpenExport(false);
          }}
        />
      )}
    </div>
  );
};

export default TimesheetHistory;
