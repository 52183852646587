import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const AddUserIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M12.2735 10.5541C13.0394 9.95149 13.5985 9.12513 13.8729 8.18999C14.1473 7.25485 14.1234 6.25743 13.8046 5.33649C13.4857 4.41556 12.8878 3.6169 12.0939 3.05164C11.3 2.48638 10.3496 2.18262 9.37507 2.18262C8.4005 2.18262 7.45016 2.48638 6.65627 3.05164C5.86237 3.6169 5.26441 4.41556 4.94555 5.33649C4.6267 6.25743 4.60282 7.25485 4.87723 8.18999C5.15164 9.12513 5.7107 9.95149 6.47664 10.5541C5.1642 11.0799 4.01905 11.952 3.16327 13.0775C2.30749 14.2029 1.77317 15.5395 1.61726 16.9447C1.60598 17.0473 1.61501 17.1511 1.64384 17.2502C1.67268 17.3493 1.72075 17.4418 1.78532 17.5223C1.91571 17.6849 2.10537 17.7891 2.31257 17.8119C2.51977 17.8347 2.72754 17.7743 2.89017 17.6439C3.0528 17.5135 3.15697 17.3238 3.17976 17.1166C3.35131 15.5894 4.07952 14.179 5.22524 13.1547C6.37097 12.1305 7.8539 11.5643 9.3907 11.5643C10.9275 11.5643 12.4104 12.1305 13.5562 13.1547C14.7019 14.179 15.4301 15.5894 15.6016 17.1166C15.6229 17.3086 15.7145 17.4859 15.8588 17.6143C16.003 17.7427 16.1897 17.8131 16.3829 17.8119H16.4688C16.6736 17.7883 16.8608 17.6848 16.9896 17.5238C17.1184 17.3629 17.1783 17.1575 17.1563 16.9525C16.9997 15.5433 16.4625 14.2033 15.6023 13.0761C14.7421 11.949 13.5914 11.0771 12.2735 10.5541ZM9.37507 9.99941C8.75701 9.99941 8.15282 9.81613 7.63892 9.47275C7.12501 9.12937 6.72447 8.64132 6.48795 8.0703C6.25143 7.49928 6.18954 6.87094 6.31012 6.26475C6.4307 5.65856 6.72833 5.10174 7.16536 4.6647C7.6024 4.22766 8.15923 3.93004 8.76542 3.80946C9.37161 3.68888 9.99994 3.75076 10.571 3.98729C11.142 4.22381 11.63 4.62435 11.9734 5.13825C12.3168 5.65216 12.5001 6.25634 12.5001 6.87441C12.5001 7.70321 12.1708 8.49807 11.5848 9.08412C10.9987 9.67017 10.2039 9.99941 9.37507 9.99941Z'
        fill='#6C778D'
      />
      <path
        d='M20.4034 5.02023L20.4054 2.4137C20.4054 2.33573 20.3745 2.26095 20.3193 2.20582C20.2642 2.15068 20.1894 2.11971 20.1114 2.11971C20.0335 2.11971 19.9587 2.15068 19.9036 2.20582C19.8484 2.26095 19.8175 2.33573 19.8175 2.4137L19.8195 5.02023L17.213 5.01816C17.135 5.01816 17.0602 5.04913 17.0051 5.10427C16.95 5.1594 16.919 5.23418 16.919 5.31215C16.919 5.39012 16.95 5.46489 17.0051 5.52003C17.0602 5.57516 17.135 5.60613 17.213 5.60613L19.8195 5.60406L19.8175 8.2106C19.8173 8.24925 19.8248 8.28755 19.8395 8.32329C19.8542 8.35903 19.8759 8.3915 19.9032 8.41883C19.9305 8.44616 19.963 8.46781 19.9987 8.48253C20.0345 8.49725 20.0728 8.50474 20.1114 8.50458C20.1501 8.50474 20.1884 8.49725 20.2241 8.48253C20.2599 8.46781 20.2923 8.44616 20.3197 8.41883C20.347 8.3915 20.3687 8.35903 20.3834 8.32329C20.3981 8.28755 20.4056 8.24925 20.4054 8.2106L20.4034 5.60406L23.0099 5.60613C23.0485 5.60629 23.0868 5.59879 23.1226 5.58408C23.1583 5.56936 23.1908 5.54771 23.2181 5.52038C23.2455 5.49305 23.2671 5.46058 23.2818 5.42484C23.2965 5.3891 23.304 5.3508 23.3039 5.31215C23.304 5.2735 23.2965 5.2352 23.2818 5.19946C23.2671 5.16372 23.2455 5.13124 23.2181 5.10391C23.1908 5.07658 23.1583 5.05493 23.1226 5.04022C23.0868 5.0255 23.0485 5.018 23.0099 5.01816L20.4034 5.02023Z'
        fill='#6C778D'
        stroke='#6C778D'
        strokeWidth='0.9375'
      />
    </IconBase>
  );
};
