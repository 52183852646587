import React from "react";
import { RouteProps } from "react-router-dom";
import { HistoryIcon } from "../../components/Icons/HistoryIcon";
import { SettingIcon } from "../../components/Icons/SettingIcon";
import { StaffIcon } from "../../components/Icons/StaffIcon";
import { TimesheetIcon } from "../../components/Icons/TimesheetIcon";
import { TimesheetSubIcon } from "../../components/Icons/TimesheetSubIcon";
import { APP_ROUTES } from "../../constants/module";
import Setting from "../../containers/Setting";
import Staff from "../../containers/Staff";
import StaffDetail from "../../containers/Staff/StaffDetail";
import StaffModal from "../../containers/Staff/StaffModal";
import Timesheet from "../../containers/Timesheet";
import TimesheetHistory from "../../containers/TimesheetHistory";

export const DEFAULT_COLOR = "#525560";
export const ACTIVE_COLOR = "#FFFFFF";
export const DEFAULT_SIZE: [number, number] = [38, 38];

export type ModuleItem = {
  title: string;
  label: string;
  defaultIcon: React.ReactElement;
  activeIcon: React.ReactElement;
  path: string;
  subRoutes?: Array<ModuleItem>;
  enabled: boolean;
};

const moduleList: ModuleItem[] = [
  {
    title: "Personnel",
    label: "Personnel",
    defaultIcon: (
      <StaffIcon
        color={DEFAULT_COLOR}
        size={DEFAULT_SIZE}
        viewBox={DEFAULT_SIZE}
      />
    ),
    activeIcon: (
      <StaffIcon
        color={DEFAULT_COLOR}
        size={DEFAULT_SIZE}
        viewBox={DEFAULT_SIZE}
      />
    ),
    path: APP_ROUTES.STAFF,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Timesheet",
    label: "Timesheet",
    defaultIcon: (
      <TimesheetIcon
        color={DEFAULT_COLOR}
        size={DEFAULT_SIZE}
        viewBox={DEFAULT_SIZE}
      />
    ),
    activeIcon: (
      <TimesheetIcon
        color={ACTIVE_COLOR}
        size={DEFAULT_SIZE}
        viewBox={DEFAULT_SIZE}
      />
    ),
    path: APP_ROUTES.TIMESHEET,
    enabled: true,
    subRoutes: [
      {
        title: "History",
        label: "History",
        defaultIcon: (
          <HistoryIcon
            color={DEFAULT_COLOR}
            size={[20, 20]}
            viewBox={[20, 20]}
          />
        ),
        activeIcon: (
          <HistoryIcon
            color={ACTIVE_COLOR}
            size={[20, 20]}
            viewBox={[20, 20]}
          />
        ),
        path: APP_ROUTES.TIMESHEET,
        enabled: true,
      },
      {
        title: "Timesheet",
        label: "Timesheet",
        defaultIcon: (
          <TimesheetSubIcon
            color={DEFAULT_COLOR}
            size={[20, 20]}
            viewBox={[20, 20]}
          />
        ),
        activeIcon: (
          <TimesheetSubIcon
            color={ACTIVE_COLOR}
            size={[20, 20]}
            viewBox={[20, 20]}
          />
        ),
        path: APP_ROUTES.TIMESHEET_TIMEKEEPING,
        enabled: true,
      },
    ],
  },
  {
    title: "Setting",
    label: "Setting",
    defaultIcon: (
      <SettingIcon color={DEFAULT_COLOR} size={[34, 34]} viewBox={[23, 23]} />
    ),
    activeIcon: (
      <SettingIcon color={ACTIVE_COLOR} size={[34, 34]} viewBox={[23, 23]} />
    ),
    path: APP_ROUTES.SETTING,
    enabled: true,
  },
];

export const appRoutes: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.STAFF,
    component: Staff,
  },
  {
    exact: true,
    path: APP_ROUTES.STAFF_DETAIL,
    component: StaffDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.STAFF_CREATE,
    component: StaffModal,
  },
  {
    exact: true,
    path: APP_ROUTES.STAFF_EDIT,
    component: StaffModal,
  },
  {
    exact: true,
    path: APP_ROUTES.TIMESHEET_TIMEKEEPING,
    component: Timesheet,
  },
  {
    exact: true,
    path: APP_ROUTES.TIMESHEET,
    component: TimesheetHistory,
  },
  {
    exact: true,
    path: APP_ROUTES.SETTING,
    component: Setting,
  },
];

export default moduleList;
