import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../../../assets/images/avatar-df.png";
import { TimesheetService } from "../../../../services";
import {
  checkLinkImage,
  currencyFormat,
  minutes_to_hhmm,
  renderErrorImage,
} from "../../../../utils";
import Loading from "../../../components/Loading";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

type P_Props = {
  period: string;
  employeeIds: string[];
};

const TimesheetHistoryListing: React.FC<P_Props> = (props) => {
  const history = useHistory();

  const [listHistory, setListHistory] = useState<any[]>([]);

  const fetchData = async () => {
    try {
      const result = await TimesheetService.getHistory({
        period: props.period,
      });
      if (result.data.data) {
        setListHistory(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.period, props.employeeIds]);

  return (
    <div className='time-sheet-history__list'>
      <div className='header-list-page'>
        <div className='time-sheet-history__content__list__employee'>
          Employee
        </div>
        <div className='time-sheet-history__content__list__works-hours'>
          Works Hours
        </div>
        <div className='time-sheet-history__content__list__paid-hours'>
          Paid Hours
        </div>
        <div className='time-sheet-history__content__list__regular-hours'>
          Regular Hours
        </div>
        <div className='time-sheet-history__content__list__overtime'>
          Overtime
        </div>
        <div className='time-sheet-history__content__list__paid'>
          Paid Absences
        </div>
        <div className='time-sheet-history__content__list__unpaid'>
          Unpaid Absences
        </div>
        <div className='time-sheet-history__content__list__pay'>Pay (USD)</div>
      </div>
      {listHistory?.length ? (
        listHistory.map((h: any, idx: number) => {
          return (
            <div key={idx}>
              {h.employees?.length > 0 && (
                <div className='time-sheet-history__content__list__item-date'>
                  {h.timeOfCycle}
                </div>
              )}
              {h.employees.map((employ: any, index: number) => {
                return (
                  <div
                    key={index}
                    className='item-list-page'
                    onClick={() => {
                      history.push(
                        APP_ROUTES.TIMESHEET +
                          "?id=" +
                          employ._id +
                          "&timeOfCycle=" +
                          h.timeOfCycle +
                          "&period=" +
                          props.period
                      );
                    }}
                  >
                    <div className='time-sheet-history__content__list__employee item-name'>
                      <img
                        src={checkLinkImage(employ?.avatar as string) || Avatar}
                        alt={DEFAULT_IMG_ALT}
                        onError={renderErrorImage()}
                      />
                      <div>
                        {employ.fullName}
                        <div className='item-code'>{employ.code}</div>
                      </div>
                    </div>
                    <div className='time-sheet-history__content__list__works-hours'>
                      {minutes_to_hhmm(employ.total.workHours, true) ||
                        TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__paid-hours'>
                      {minutes_to_hhmm(employ.total.paidHours, true) ||
                        TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__regular-hours'>
                      {minutes_to_hhmm(employ.total.regularHours, true) ||
                        TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__overtime'>
                      {minutes_to_hhmm(employ.total.overtime) || TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__paid'>
                      {minutes_to_hhmm(employ.total.paidAbsence) || TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__unpaid'>
                      {minutes_to_hhmm(employ.total.unpaidAbsence) ||
                        TEXT_HOLDER}
                    </div>
                    <div className='time-sheet-history__content__list__pay'>
                      ${currencyFormat(employ.total.salary)}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default TimesheetHistoryListing;
