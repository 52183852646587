/*eslint-disable */
import {
  DeleteOutline as DeleteOutlineIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import { Avatar, Dialog } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import Avt from "../../../assets/images/avatar-df.png";
// import IconAvt from "../../../assets/images/icon_avatar.png";
import { UploadedImageModel } from "../../../models/other.model";
import { loadCallback, renderErrorImage } from "../../../utils/other.util";
import { DEFAULT_IMG_ALT } from "../../constants";
import { ACTIVE_COLOR, DEFAULT_SIZE } from "../../core/Layout/routes";
import { ImageUploadIcon } from "../Icons/ImageUploadIcon";
import { SelectedImageIcon } from "../Icons/SelectedImageIcon";
import { SelectedStarIcon } from "../Icons/SelectedStarIcon";
import { UnselectedStarIcon } from "../Icons/UnselectedStarIcon";

import SimpleModalHeader from "../SimpleModalHeader";
import "./index.scss";

type ImagePreview = {
  visible: boolean;
  initData: { id?: string; src?: string; alt: string; file: any };
};

type P_Props = {
  className?: string;
  max: number;
  title?: string;
  style?: React.CSSProperties;
  fileList: { id: string; src: string; alt?: string; originFileObj?: any }[];
  onChange: (data: UploadedImageModel) => void;
  onDelete: (fileId: string) => void;
  config?: {
    uploadModal?: boolean;
  };
  selectConfig?: {
    selectedIds: string[];
    onSelect: (fileId: string, fileInList: any) => void;
  };
  name?: string;
};

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: "#41AD49",
      fontSize: "3rem",
      fontWeight: "bold",
      padding: "5rem",
    },
    children: `${name?.split(" ")[0][0]}`.toUpperCase(),
  };
}

const ImageUpload: React.FC<P_Props> = (props) => {
  const classes = clsx({
    "image-upload": true,
    [props.className || ""]: Boolean(props.className),
  });
  const [imagePreview, setImagePreview] = useState<ImagePreview>({
    visible: false,
    initData: {
      file: undefined,
      alt: "",
    },
  });

  const changeImagePreviewState = <T extends keyof ImagePreview>(
    key: T,
    value: ImagePreview[T]
  ) => {
    setImagePreview({
      ...imagePreview,
      [key]: value,
    });
  };

  const closeImagePreviewModal = () => {
    setImagePreview({ visible: false, initData: { alt: "", file: undefined } });
  };

  const handleUpload = (event: any) => {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      props.onChange({ file, fileStr: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleModalUpload = (event: any) => {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function (e) {
      changeImagePreviewState("initData", {
        ...imagePreview.initData,
        file,
        src: reader.result as string,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    props.onChange({ file, fileStr: reader.result });
  };

  const handelModalSave = () => {
    props.onChange({
      id: imagePreview.initData.id,
      file: imagePreview.initData.file,
      fileStr: imagePreview.initData.src,
      alt: imagePreview.initData.alt,
    });
    setImagePreview({ visible: false, initData: { alt: "", file: undefined } });
  };

  return (
    <>
      <div className={classes} style={props.style}>
        {props.fileList.length < props.max &&
          !Boolean(props.config?.uploadModal) && (
            <div className='image-upload__input'>
              <label>
                <ImageUploadIcon
                  size={[32, 32]}
                  color='#6658F3'
                  viewBox={[32, 32]}
                />
              </label>
              <input accept='image/*' type='file' onChange={handleUpload} />
              {props.title === "Đổi ảnh đại diện" && (
                <button className='ic-up-avt'>
                  <SelectedImageIcon
                    color={ACTIVE_COLOR}
                    size={DEFAULT_SIZE}
                    viewBox={DEFAULT_SIZE}
                  />
                </button>
              )}
            </div>
          )}

        {props.fileList.length < props.max &&
          Boolean(props.config?.uploadModal) && (
            <button
              className='image-upload__upload-btn'
              onClick={(e) => {
                e.preventDefault();
                changeImagePreviewState("visible", true);
              }}
            >
              {props.title === "Đổi ảnh đại diện" ? (
                <Avatar {...stringAvatar(props.name as string)} />
              ) : (
                <ImageUploadIcon
                  size={[32, 32]}
                  color='#838283'
                  viewBox={[32, 32]}
                />
              )}
              {props.title === "Đổi ảnh đại diện" && (
                <button className='ic-up-avt'>
                  <SelectedImageIcon
                    color={ACTIVE_COLOR}
                    size={DEFAULT_SIZE}
                    viewBox={DEFAULT_SIZE}
                  />
                </button>
              )}
            </button>
          )}

        {props.fileList.map((file) => {
          let isSelected = false;
          if (props.selectConfig?.selectedIds.length) {
            isSelected = props.selectConfig.selectedIds.includes(file.id);
          }

          return (
            <div key={file.id} className='image-upload__img'>
              <img
                alt={DEFAULT_IMG_ALT}
                src={file.src || Avt}
                onError={renderErrorImage()}
              />
              {props.title === "Đổi ảnh đại diện" && (
                <button className='ic-up-avt'>
                  <SelectedImageIcon
                    color={ACTIVE_COLOR}
                    size={DEFAULT_SIZE}
                    viewBox={DEFAULT_SIZE}
                  />
                </button>
              )}

              {props.title === "Đổi ảnh đại diện" ? (
                <div className='image-upload__img__actions image-upload__img__actions__avt'>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setImagePreview({
                        visible: true,
                        initData: {
                          ...file,
                          alt: file.alt || "",
                          file: undefined,
                        },
                      });
                    }}
                  >
                    Chỉnh sửa ảnh
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      props.onDelete(file.id);
                    }}
                  >
                    Xóa ảnh
                  </button>
                </div>
              ) : (
                <div className='image-upload__img__actions'>
                  {Boolean(props.config?.uploadModal) && (
                    <button
                      className='ic-click'
                      style={{
                        marginRight: "1rem",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setImagePreview({
                          visible: true,
                          initData: {
                            ...file,
                            alt: file.alt || "",
                            file: undefined,
                          },
                        });
                      }}
                    >
                      <VisibilityOutlinedIcon
                        style={{ fontSize: "2rem", color: "#00529C" }}
                      />
                    </button>
                  )}
                  <button
                    className='ic-click'
                    style={{
                      marginLeft: "1rem",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onDelete(file.id);
                    }}
                  >
                    <DeleteOutlineIcon
                      style={{ fontSize: "2rem", color: "#00529C" }}
                    />
                  </button>
                </div>
              )}

              {props.selectConfig && (
                <div>
                  {isSelected ? (
                    <button
                      className='image-upload__img__selected'
                      onClick={(e) => {
                        e.preventDefault();
                        loadCallback(
                          props.selectConfig?.onSelect,
                          file.id,
                          file
                        );
                      }}
                    >
                      <SelectedStarIcon
                        size={[19, 19]}
                        color='#ffbc1f'
                        viewBox={[19, 19]}
                      />
                    </button>
                  ) : (
                    <button
                      className='image-upload__img__unselected'
                      onClick={(e) => {
                        e.preventDefault();
                        loadCallback(
                          props.selectConfig?.onSelect,
                          file.id,
                          file
                        );
                      }}
                    >
                      <UnselectedStarIcon
                        size={[19, 19]}
                        color='#bdc6d7'
                        viewBox={[19, 19]}
                      />
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Dialog open={imagePreview.visible} maxWidth='sm' fullWidth>
        <div className='image-upload__preview'>
          <SimpleModalHeader
            title={props.title === "Đổi ảnh đại diện" ? props.title : "Upload"}
            onClose={() => {
              closeImagePreviewModal();
            }}
          />
          <div className='image-upload__preview__body'>
            {props.title === "Đổi ảnh đại diện" ? (
              <div className='image-upload__preview__body__upload image-upload__preview__body__upload__avt'>
                {imagePreview.initData.src ? (
                  <div className='image-upload__preview__body__upload__img'>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt={DEFAULT_IMG_ALT}
                        src={imagePreview.initData.src || Avt}
                        onError={renderErrorImage()}
                        style={{ width: "10rem", height: "10rem" }}
                      />
                      {imagePreview.initData.file && (
                        <div className='des-suc'>
                          Tải lên thành công!
                          <br />
                          <span>
                            Đổi ảnh khác
                            <input
                              accept='image/*'
                              type='file'
                              onChange={handleModalUpload}
                            />
                          </span>
                        </div>
                      )}
                      {imagePreview.initData.src &&
                        !imagePreview.initData.file && (
                          <div className='des-suc'>
                            <span>
                              Đổi ảnh khác
                              <input
                                accept='image/*'
                                type='file'
                                onChange={handleModalUpload}
                              />
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                ) : (
                  <div className={"image-upload__preview__body__input"}>
                    <label style={{ display: "flex", flexDirection: "column" }}>
                      {/* <img src={IconAvt} alt={DEFAULT_IMG_ALT} /> */}
                      {props.title === "Đổi ảnh đại diện" &&
                        !imagePreview.initData.file &&
                        !imagePreview.initData.src && (
                          <div className='des' style={{ marginTop: "1.4rem" }}>
                            Kéo thả ảnh vào đây để tải lên
                            <button className='btn-cancel-style'>
                              Chọn ảnh từ máy
                            </button>
                          </div>
                        )}
                    </label>
                    <input
                      accept='image/*'
                      type='file'
                      onChange={handleModalUpload}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className='image-upload__preview__body__upload'>
                {imagePreview.initData.src ? (
                  <div className='image-upload__preview__body__upload__img'>
                    <img
                      alt={DEFAULT_IMG_ALT}
                      src={imagePreview.initData.src}
                      onError={renderErrorImage()}
                    />
                    <div className='image-upload__preview__body__upload__img__actions'>
                      <button
                        className='ic-click'
                        onClick={() => {
                          changeImagePreviewState("initData", {
                            ...imagePreview.initData,
                            src: undefined,
                            file: undefined,
                          });
                        }}
                      >
                        <DeleteOutlineIcon
                          style={{ fontSize: "2rem", color: "#00529C" }}
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={"image-upload__preview__body__input"}>
                    <label style={{ display: "flex", flexDirection: "column" }}>
                      <ImageUploadIcon
                        size={[32, 32]}
                        color='#838283'
                        viewBox={[32, 32]}
                      />
                    </label>
                    <input
                      accept='image/*'
                      type='file'
                      onChange={handleModalUpload}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {/* {props.title === "Đổi ảnh đại diện" && (
            <>
              {!imagePreview.initData.file && !imagePreview.initData.src && (
                <div className="des">Kéo thả ảnh vào đây để tải lên</div>
              )}
              {imagePreview.initData.src && !imagePreview.initData.file && (
                <div className="des">Thay đổi ảnh đại diện bằng cách kéo thả hoặc tải ảnh lên</div>
              )}
              {imagePreview.initData.file && (
                <div className="des-suc">
                  Tải lên thành công!
                  <br />
                  <span>hoặc thay đổi ảnh khác</span>
                </div>
              )}
            </>
          )} */}
          <div
            // className={`${
            //   props.title === "Đổi ảnh đại diện" && "btn-group"
            // } image-upload__preview__actions`}
            className='image-upload__preview__actions'
          >
            {/* {props.title === "Đổi ảnh đại diện" && (
              <button
                className="btn-remove-style"
                onClick={() => {
                  closeImagePreviewModal();
                }}>
                Hủy
              </button>
            )} */}
            <button
              onClick={() => {
                if (imagePreview.initData.src || imagePreview.initData.file) {
                  handelModalSave();
                }
              }}
              className={
                imagePreview.initData.src || imagePreview.initData.file
                  ? `btn-confirm-style`
                  : `hide btn-confirm-style`
              }
            >
              {props.title === "Đổi ảnh đại diện" ? "Xác nhận" : "Save"}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ImageUpload;
