import { REQUEST_URL } from "../app/constants";
import { RequestUtil } from "../utils";

export const EmployeeService = {
  getEmployee: () => {
    const url = REQUEST_URL.EMPLOYEE;
    return RequestUtil.get({ url });
  },

  getEmployeeDetailById: (id: string) => {
    const url = `${REQUEST_URL.EMPLOYEE}/${id}`;
    return RequestUtil.get({ url });
  },

  createEmployee: (data: any) => {
    const url = REQUEST_URL.EMPLOYEE;
    return RequestUtil.post({ url, data });
  },

  updateEmployee: (data: any) => {
    const url = REQUEST_URL.EMPLOYEE;
    return RequestUtil.put({ url, data });
  },

  deleteEmployee: (id: string) => {
    const url = REQUEST_URL.DELETE_EMPLOYEE.replace("_id", id);
    return RequestUtil.delete({ url });
  },

  getEmployeeTeam: () => {
    const url = REQUEST_URL.EMPLOYEE_TEAM;
    return RequestUtil.get({ url });
  },

  createEmployeeTeam: (data: any) => {
    const url = REQUEST_URL.EMPLOYEE_TEAM;
    return RequestUtil.post({ url, data });
  },

  deleteEmployeeTeam: (id: string) => {
    const url = REQUEST_URL.DELETE_EMPLOYEE_TEAM.replace("_id", id);
    return RequestUtil.get({ url });
  },

  getEmployeePosition: () => {
    const url = REQUEST_URL.EMPLOYEE_POSITION;
    return RequestUtil.get({ url });
  },

  createEmployeePosition: (data: any) => {
    const url = REQUEST_URL.EMPLOYEE_POSITION;
    return RequestUtil.post({ url, data });
  },

  deleteEmployeePosition: (id: string) => {
    const url = REQUEST_URL.DELETE_EMPLOYEE_POSITION.replace("_id", id);
    return RequestUtil.get({ url });
  },

  uploadAvatarEmployee: (data: FormData) => {
    const url = REQUEST_URL.EMPLOYEE_AVATAR;
    return RequestUtil.put({ url, data });
  },

  importEmployee: (data: FormData) => {
    const url = REQUEST_URL.EMPLOYEE_IMPORT;
    return RequestUtil.post({ url, data });
  },

  deleteAvatarEmployee: (id: string) => {
    const url = REQUEST_URL.DELETE_AVATAR_EMPLOYEE.replace("{id}", id);
    return RequestUtil.delete({ url });
  },
};
