import React from "react";
import "./NotFound.scss";

const NotFound: React.FC = () => {
  return (
    <div className="notfound">
      <div className="title">Không tìm thấy trang</div>
      <div className="label">Trang mà bạn tìm kiếm không còn tồn tại nữa</div>
      {/* <button
        type="submit"
        className="btn-confirm-style"
        style={{ borderRadius: "4rem", marginBottom: "10rem" }}
      >
        Quay lại trang chủ
      </button> */}
    </div>
  );
};

export default NotFound;
