import {
  Autocomplete,
  createFilterOptions,
  Dialog,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { EmployeeService, TimesheetService } from "../../../../services";
import {
  checkLinkImage,
  FormUIUtil,
  renderErrorImage,
} from "../../../../utils";
import ChooseAbsence from "../../../components/ChooseAbsence";
import ModalFooter from "../../../components/ModalFooter";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import SingleDatePicker from "../../../components/SinglePicker";
import "./index.scss";
import Avatar from "../../../../assets/images/avatar-df.png";
import { DEFAULT_IMG_ALT } from "../../../constants";
import { AddUserIcon } from "../../../components/Icons/AddUserIcon";
import { DEFAULT_COLOR } from "../../../core/Layout/routes";
import moment from "moment";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { MESSAGES } from "../../../constants/mock";
import { useQuery } from "../../../../hooks";

type P_Props = {
  onClose: () => void;
  data?: any;
  dateByWeek?: string;
};

const AbsenceModal: React.FC<P_Props> = (props) => {
  const query = useQuery();
  const [pushNoti] = useNoti();
  const [date, setDate] = useState<any>(null);
  const [chooseAbsence, setChooseAbsence] = useState<any[]>([
    {
      id: 0,
      startTime: "",
      endTime: "",
      type: "paid",
      note: "",
    },
  ]);
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [employ, setEmploy] = useState<any>(null);

  const fetchDataEmployee = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataEmployee();

    if (!props?.dateByWeek && query.get("date")) {
      setDate(query.get("date"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.get("employIds") && listEmployee) {
      setEmploy(
        listEmployee.find(
          (f) => f._id === query.get("employIds")?.split(",")[0]
        )
      );
    }

    setDate(
      props?.dateByWeek
        ? props.data?.timekeepings?.filter(
            (f: any) =>
              props?.dateByWeek ===
              f.date?.slice(f.date?.length - 2, f.date?.length)
          )?.[0]?.date
        : props.data?.timekeepings?.[0]?.date
    );

    if (props.data && listEmployee) {
      setChooseAbsence(
        (props?.dateByWeek
          ? props.data?.timekeepings?.filter(
              (f: any) =>
                props?.dateByWeek ===
                f.date?.slice(f.date?.length - 2, f.date?.length)
            )?.[0]?.absences
          : props.data?.timekeepings?.[0]?.absences
        )?.map((a: any, idx: number) => {
          return {
            id: idx,
            startTime: a.startTime,
            endTime: a.endTime,
            type: a.type,
            note: a.note,
          };
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEmployee, props.data]);

  const addAbsence = async () => {
    if (!employ) {
      pushNoti(AppNotifications.ERROR, "Please select employee");
      return;
    }

    let val = {
      date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      employeeId: employ?._id,
      absences: chooseAbsence.map((c) => {
        return {
          startTime: c.startTime,
          endTime: c.endTime,
          type: c.type,
          note: c.note,
        };
      }),
    };

    try {
      const res = await TimesheetService.createAbsence(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        props.onClose();
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const updateAbsence = async () => {
    let val = {
      _id: props?.dateByWeek
        ? props.data?.timekeepings?.filter(
            (f: any) =>
              props?.dateByWeek ===
              f.date?.slice(f.date?.length - 2, f.date?.length)
          )?.[0]?._id
        : props.data?.timekeepings?.[0]?._id,
      absences: chooseAbsence.map((c) => {
        return {
          startTime: c.startTime,
          endTime: c.endTime,
          type: c.type,
          note: c.note,
        };
      }),
    };

    try {
      const res = await TimesheetService.updateTimekeeping(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        props.onClose();
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const _filterOptions = createFilterOptions();
  const filterOptions = (options: any, state: any) => {
    const result = _filterOptions(options, state);

    if (result.length === 0) {
      return _filterOptions(options, {
        ...state,
        getOptionLabel: (o: any) => o.code,
      });
    }

    return result;
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      // style={{ zIndex: 1501 }}
    >
      <div className="absence-modal">
        <SimpleModalHeader
          title={props.data ? "Edit absence" : "Add absence"}
          onClose={props.onClose}
        />
        <div className="absence-modal__content">
          <Form
            onSubmit={async () => {
              props.data ? updateAbsence() : addAbsence();
            }}
            // initialValues={{}}
          >
            {({ handleSubmit }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  id="absence-modal"
                  className="absence-modal__form"
                >
                  {FormUIUtil.renderFormItem(
                    "Employee",
                    <Autocomplete
                      fullWidth
                      value={employ}
                      readOnly={props.data}
                      disableClearable
                      options={listEmployee}
                      getOptionLabel={(options) => options.fullName}
                      onChange={(e, value) => setEmploy(value)}
                      filterOptions={filterOptions}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <div className="autocomplete-check">
                            <img
                              src={
                                checkLinkImage(option?.avatar as string) ||
                                Avatar
                              }
                              alt={DEFAULT_IMG_ALT}
                              onError={renderErrorImage()}
                            />
                            <div className="autocomplete-check__name">
                              {option.fullName}
                              <div className="autocomplete-check__code">
                                {option.code}
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <div style={{ position: "relative" }}>
                          {params.inputProps.value && (
                            <span
                              style={{
                                position: "absolute",
                                transform: "translateY(50%)",
                                marginLeft: "16px",
                                marginTop: "3px",
                              }}
                            >
                              <img
                                src={
                                  checkLinkImage(
                                    listEmployee.find(
                                      (f) =>
                                        f.fullName === params.inputProps.value
                                    )?.avatar
                                  ) || Avatar
                                }
                                alt={DEFAULT_IMG_ALT}
                                onError={renderErrorImage()}
                                style={{
                                  width: 22,
                                  height: 22,
                                  borderRadius: "50%",
                                }}
                              />
                            </span>
                          )}
                          <TextField
                            {...params}
                            placeholder="Select employee"
                            className="autocomplete-check__text-field"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              style: { paddingLeft: "20px" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <span
                                    style={{ marginLeft: 10, marginTop: 1 }}
                                  >
                                    <AddUserIcon
                                      color={DEFAULT_COLOR}
                                      size={[25, 18]}
                                      viewBox={[25, 18]}
                                    />
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      )}
                      style={{ background: props.data ? "#f2f2f2" : "" }}
                    />,
                    false
                  )}
                  {FormUIUtil.renderFormItem(
                    "Select Date",
                    <SingleDatePicker
                      day={date}
                      onChangeDate={(value) => {
                        setDate(value);
                      }}
                      disabled={props.data}
                    />,
                    false
                  )}
                  <div className="absence-modal__form__title">
                    Choose absence shift
                  </div>
                  <ChooseAbsence
                    data={chooseAbsence}
                    onChange={(absence: any) => {
                      setChooseAbsence(absence);
                    }}
                  />
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="absence-modal"
          showDeleteButton={""}
          handleDeleteAction={() => {}}
          handleCancelAction={props.onClose}
          title={props.data ? "Save" : "Add"}
          handleSubmit={() => {}}
        />
      </div>
    </Dialog>
  );
};

export default AbsenceModal;
