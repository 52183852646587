import {
  AddRounded as AddRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useNoti from "../../../../hooks/useNoti";
import {
  AppNotifications,
  FormUploadImageModel,
  UploadedImageModel,
} from "../../../../models";
import { EmployeeService } from "../../../../services/employee.service";
import {
  FormUIUtil,
  FormUtil,
  checkLinkImage,
  minutes_to_hhmm,
  preventCharacterNumber,
} from "../../../../utils";
import ImageUpload from "../../../components/ImageUpload";
import ModalFooter from "../../../components/ModalFooter";
import NotificationModal from "../../../components/NotificationModal";
import OtherInsuranc from "../../../components/OtherInsuranc";
import OvertimeSalary from "../../../components/OvertimeSalary";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import SingleDatePicker from "../../../components/SinglePicker";
import { REGEX } from "../../../constants";
import { Gender, MESSAGES } from "../../../constants/mock";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

const StaffModal: React.FC = () => {
  const history = useHistory();
  const [pushNoti] = useNoti();
  const { staffId }: { staffId: string } = useParams();
  const [avatar, setAvatar] = useState<
    Array<FormUploadImageModel & { file: any }>
  >([]);
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);
  const [firstDayOfWork, setFirstDayOfWork] = useState<any>(null);
  const [listTeam, setListTeam] = useState<any[]>([]);
  const [listPosition, setListPosition] = useState<any[]>([]);
  const [overtimes, setOvertimes] = useState<any[]>([]);
  const [others, setOthers] = useState<any[]>([]);
  const [createSuccess, setCreateSuccess] = useState<{
    id: string;
    fullName: string;
  }>({ id: "", fullName: "" });
  const [employeeDetail, setEmployeeDetail] = useState<any>(null);
  const [openAddTeam, setOpenAddTeam] = useState<boolean>(false);
  const [teamName, setTeamName] = useState<string>("");
  const [openAddPosition, setOpenAddPosition] = useState<boolean>(false);
  const [positionName, setPositionName] = useState<string>("");
  const [notiDelete, setNotiDelete] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await EmployeeService.getEmployeeDetailById(staffId);
      if (result.data.data) {
        setEmployeeDetail(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataTeam = async () => {
    try {
      const result = await EmployeeService.getEmployeeTeam();
      if (result.data.data) {
        setListTeam(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataPosition = async () => {
    try {
      const result = await EmployeeService.getEmployeePosition();
      if (result.data.data) {
        setListPosition(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataTeam();
    fetchDataPosition();
    if (staffId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId]);

  const updateAvatar = async () => {
    if (!avatar || !avatar?.[0]?.file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("avatar", avatar?.[0]?.file);
      employeeDetail && formData.append("employeeId", employeeDetail?._id);
      const res = await EmployeeService.uploadAvatarEmployee(formData);
      if (res.status === 200 || res.status === 201) {
        setFileName(res?.data?.data?.fileName);
      }
    } catch (error) {}
  };

  const deleteAvatarEmployee = async (id: string) => {
    try {
      const res = await EmployeeService.deleteAvatarEmployee(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const createTeam = async () => {
    try {
      const res = await EmployeeService.createEmployeeTeam({ name: teamName });
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        fetchDataTeam();
        setTeamName("");
        setOpenAddTeam(false);
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const createPosition = async () => {
    try {
      const res = await EmployeeService.createEmployeePosition({
        name: positionName,
      });
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        fetchDataPosition();
        setPositionName("");
        setOpenAddPosition(false);
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const createEmployee = async (values: any) => {
    if (avatar && avatar?.[0]?.file) {
      updateAvatar();
    }

    let val = {
      avatar: fileName ? fileName : undefined,
      code: values.code,
      email: values.email,
      phone: values.phone,
      fullName: values.fullName,
      gender: values.gender,
      dateOfBirth: dateOfBirth,
      address: values.address,
      firstDayOfWork: firstDayOfWork,
      teamId: values.teamId,
      positionId: values.positionId,
      insurance:
        others.length > 0 || values.social || values.health
          ? {
              social: values.social,
              health: values.health,
              others:
                others.length > 0 ? others.filter((f) => f !== "") : undefined,
            }
          : undefined,
      salary: {
        current: +values.current,
        paidLeaveHours: values.paidLeaveHours
          ? +values.paidLeaveHours?.slice(0, 2) * 60 +
            +values.paidLeaveHours?.slice(3, 5)
          : undefined,
        weeklyWorkHours: values.weeklyWorkHours
          ? +values.weeklyWorkHours?.slice(0, 2) * 60 +
            +values.weeklyWorkHours?.slice(3, 5)
          : undefined,
        overtimes: overtimes.length > 0 ? overtimes : undefined,
      },
    };

    try {
      const res = await EmployeeService.createEmployee(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        if (res.data.data) {
          setCreateSuccess({
            id: res.data?.data?.code,
            fullName: res.data?.data?.fullName,
          });
        }
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  useEffect(() => {
    setAvatar(
      employeeDetail?.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(employeeDetail?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
    setOthers(employeeDetail?.insurance.others);
    setOvertimes(employeeDetail?.salary.overtimes);
    setDateOfBirth(employeeDetail?.dateOfBirth);
    setFirstDayOfWork(employeeDetail?.firstDayOfWork);
  }, [employeeDetail]);

  const dataEmployeeDetail = employeeDetail && {
    ...employeeDetail,
    social: employeeDetail?.insurance.social,
    health: employeeDetail?.insurance.health,
    current: employeeDetail?.salary.current,
    paidLeaveHours: minutes_to_hhmm(employeeDetail?.salary.paidLeaveHours),
    weeklyWorkHours: minutes_to_hhmm(employeeDetail?.salary.weeklyWorkHours),
    teamId: employeeDetail?.team?._id,
    positionId: employeeDetail?.position?._id,
    phone: employeeDetail?.phone ? employeeDetail?.phone : "",
  };

  const updateEmployee = async (values: any) => {
    if (avatar && avatar?.[0]?.file) {
      updateAvatar();
    }

    if (avatar?.length === 0 && employeeDetail?.avatar) {
      deleteAvatarEmployee(employeeDetail._id);
    }

    let val = {
      _id: employeeDetail._id,
      email: values.email,
      phone: values.phone || "",
      fullName: values.fullName,
      gender: values.gender,
      dateOfBirth: dateOfBirth || undefined,
      address: values.address || "",
      firstDayOfWork: firstDayOfWork || undefined,
      teamId: values.teamId,
      positionId: values.positionId,
      insurance:
        others.length > 0 || values.social || values.health
          ? {
              social: values.social,
              health: values.health,
              others:
                others.length > 0 ? others.filter((f) => f !== "") : undefined,
            }
          : undefined,
      salary: {
        current: +values.current,
        paidLeaveHours: values.paidLeaveHours
          ? +values.paidLeaveHours?.slice(0, 2) * 60 +
            +values.paidLeaveHours?.slice(3, 5)
          : undefined,
        weeklyWorkHours: values.weeklyWorkHours
          ? +values.weeklyWorkHours?.slice(0, 2) * 60 +
            +values.weeklyWorkHours?.slice(3, 5)
          : undefined,
        overtimes: overtimes.length > 0 ? overtimes : undefined,
      },
    };

    try {
      const res = await EmployeeService.updateEmployee(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        history.push(APP_ROUTES.STAFF);
      } else if (res && res.status === 400) {
        if (res.data?.message === "NOTHING_CHANGED" && avatar?.[0]?.file) {
          pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
          return history.push(APP_ROUTES.STAFF);
        } else if (
          res.data?.message === "NOTHING_CHANGED" &&
          avatar?.length === 0 &&
          employeeDetail?.avatar
        ) {
          return history.push(APP_ROUTES.STAFF);
        }
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const deleteEmployee = async (id: string) => {
    try {
      const res = await EmployeeService.deleteEmployee(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        history.push(APP_ROUTES.STAFF);
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      console.log(error);
    }
  };

  return (
    <Dialog open fullScreen className='staff-modal'>
      <SimpleModalHeader
        title={staffId ? "Edit Employee" : "New Employee"}
        onClose={() => {
          history.push(APP_ROUTES.STAFF);
        }}
      />
      <div className='content'>
        <ImageUpload
          className='product-form-avatar'
          fileList={avatar}
          max={1}
          onChange={(data: UploadedImageModel) => {
            setAvatar([
              {
                id: uuidv4(),
                src: data.fileStr,
                alt: data.alt || "",
                file: data.file,
              },
            ]);
          }}
          onDelete={() => {
            setAvatar([]);
          }}
          config={{ uploadModal: true }}
        />
        <Form
          onSubmit={async (values) => {
            staffId ? updateEmployee(values) : createEmployee(values);
          }}
          initialValues={dataEmployeeDetail}
        >
          {({ handleSubmit }) => {
            return (
              <form
                onSubmit={handleSubmit}
                id='staff-form'
                className='staff-modal__form'
              >
                <div className='staff-modal__form__title'>
                  General Information
                </div>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <Field
                      name='code'
                      validate={FormUtil.Rule.required("Please enter Code")}
                    >
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Employee ID",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant={"outlined"}
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                            disabled={staffId !== undefined}
                            style={{
                              background:
                                staffId !== undefined ? "#F0F3F8" : "",
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='email'
                      validate={FormUtil.composeValidators([
                        FormUtil.Rule.required("Please enter Email"),
                        FormUtil.Rule.pattern(REGEX.EMAILVER2, {
                          errorMessage: "Invalid Email",
                        }),
                      ])}
                    >
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Email",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='fullName'
                      validate={FormUtil.Rule.required(
                        "Please enter Full name"
                      )}
                    >
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Full name",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='gender'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Gender",
                          <Select
                            {...input}
                            {...rest}
                            onChange={(e) => {
                              input.onChange(e.target.value as string);
                            }}
                            input={
                              <OutlinedInput className='form-text-field' />
                            }
                            variant='outlined'
                            className='form-select-field'
                            MenuProps={{
                              className: "select-menu-list",
                            }}
                            displayEmpty
                            renderValue={
                              input.value !== ""
                                ? undefined
                                : () => (
                                    <span className='form__placeholder'>
                                      Place holder
                                    </span>
                                  )
                            }
                            // style={{ width: 199, marginRight: 8, height: 40 }}
                          >
                            {Gender.map((t: any, idx: number) => {
                              return (
                                <MenuItem key={idx} value={t.value}>
                                  {t.label}
                                </MenuItem>
                              );
                            })}
                          </Select>,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    {FormUIUtil.renderFormItem(
                      "Date of birth",
                      <SingleDatePicker
                        day={dateOfBirth}
                        onChangeDate={(value) => {
                          setDateOfBirth(value);
                        }}
                      />,
                      true
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='phone'
                      // validate={FormUtil.Rule.pattern(REGEX.PHONE_NUMBER_VER2, {
                      //   errorMessage: "Invalid Phone",
                      // })}
                    >
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Phone",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='address'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Address",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    {FormUIUtil.renderFormItem(
                      "First day of work",
                      <SingleDatePicker
                        day={firstDayOfWork}
                        onChangeDate={(value) => {
                          setFirstDayOfWork(value);
                        }}
                        disabled={employeeDetail?.firstDayOfWork}
                      />,
                      true
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='teamId'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Team",
                          <Select
                            {...input}
                            {...rest}
                            onChange={(e) => {
                              input.onChange(e.target.value as string);
                            }}
                            input={
                              <OutlinedInput className='form-text-field' />
                            }
                            variant='outlined'
                            className='form-select-field'
                            MenuProps={{
                              className: "select-menu-list",
                              disableScrollLock: true,
                              disableAutoFocus: true,
                              autoFocus: false,
                            }}
                            displayEmpty
                            renderValue={
                              input.value !== ""
                                ? undefined
                                : () => (
                                    <span className='form__placeholder'>
                                      Place holder
                                    </span>
                                  )
                            }
                          >
                            <ListSubheader className='staff-modal__list-sub-header'>
                              {!openAddTeam ? (
                                <div
                                  className='staff-modal__title-add'
                                  onClick={() => {
                                    setOpenAddTeam(true);
                                  }}
                                >
                                  <AddRoundedIcon fontSize='large' />
                                  &ensp;Add new team
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <TextField
                                    fullWidth
                                    placeholder={"Enter team name"}
                                    color='success'
                                    className='form-text-field'
                                    inputProps={{ className: "input" }}
                                    variant='outlined'
                                    autoComplete='off'
                                    value={teamName}
                                    autoFocus
                                    onChange={(e) =>
                                      setTeamName(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                      }
                                    }}
                                  />
                                  <button
                                    className='btn-confirm-style'
                                    onClick={() => {
                                      if (teamName !== "") {
                                        createTeam();
                                      }
                                    }}
                                  >
                                    Create
                                  </button>
                                </div>
                              )}
                              <Divider
                                style={{
                                  borderColor: "#F0F3F8",
                                  margin: "1rem 0",
                                }}
                              />
                            </ListSubheader>
                            {listTeam.map((item, idx: number) => (
                              <MenuItem key={idx} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='positionId'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Position",
                          <Select
                            {...input}
                            {...rest}
                            onChange={(e) => {
                              input.onChange(e.target.value as string);
                            }}
                            input={
                              <OutlinedInput className='form-text-field' />
                            }
                            variant='outlined'
                            className='form-select-field'
                            MenuProps={{
                              className: "select-menu-list",
                              disableScrollLock: true,
                              disableAutoFocus: true,
                              autoFocus: false,
                            }}
                            displayEmpty
                            renderValue={
                              input.value !== ""
                                ? undefined
                                : () => (
                                    <span className='form__placeholder'>
                                      Place holder
                                    </span>
                                  )
                            }
                          >
                            <ListSubheader className='staff-modal__list-sub-header'>
                              {!openAddPosition ? (
                                <div
                                  className='staff-modal__title-add'
                                  onClick={() => {
                                    setOpenAddPosition(true);
                                  }}
                                >
                                  <AddRoundedIcon fontSize='large' />
                                  &ensp;Add new position
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  <TextField
                                    fullWidth
                                    placeholder={"Enter position name"}
                                    color='success'
                                    className='form-text-field'
                                    inputProps={{ className: "input" }}
                                    variant='outlined'
                                    autoComplete='off'
                                    value={positionName}
                                    onChange={(e) =>
                                      setPositionName(e.target.value)
                                    }
                                    autoFocus
                                    onKeyDown={(e) => {
                                      if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                      }
                                    }}
                                  />
                                  <button
                                    className='btn-confirm-style'
                                    onClick={() => {
                                      if (positionName !== "") {
                                        createPosition();
                                      }
                                    }}
                                  >
                                    Create
                                  </button>
                                </div>
                              )}
                              <Divider
                                style={{
                                  borderColor: "#F0F3F8",
                                  margin: "1rem 0",
                                }}
                              />
                            </ListSubheader>
                            {listPosition.map((p: any, idx: number) => {
                              return (
                                <MenuItem key={idx} value={p._id}>
                                  {p.name}
                                </MenuItem>
                              );
                            })}
                          </Select>,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
                <div className='staff-modal__form__title'>
                  Insurance Information
                </div>
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <Field name='social'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Social insurance",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='health'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Health insurance",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    {FormUIUtil.renderFormItem(
                      "Other insurance policies",
                      <OtherInsuranc
                        data={others}
                        onChangeOtherInsuranc={(o) => {
                          setOthers(o);
                        }}
                      />,
                      true
                    )}
                  </Grid>
                </Grid>
                <div className='staff-modal__form__title'>
                  Salary Information
                </div>
                <Grid container columnSpacing={3}>
                  <Grid item xs={12}>
                    <Field name='current'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Current salary",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  USD/hour
                                </InputAdornment>
                              ),
                            }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            autoComplete='off'
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='paidLeaveHours'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Paid leave hours",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input", maxLength: 5 }}
                            variant='outlined'
                            // onChange={(e) => input.onChange(e.target.value)}
                            onChange={(e) => {
                              const val = e.target.value?.replaceAll(":", "");
                              if (val.length >= 2) {
                                input.onChange(
                                  val.slice(0, 2) +
                                    ":" +
                                    e.target.value.slice(3, 5)
                                );
                              } else {
                                input.onChange(val);
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value?.length < 5) {
                                input.onChange(
                                  minutes_to_hhmm(
                                    +e.target.value.slice(0, 2) * 60 +
                                      +e.target.value.slice(3, 5)
                                  )
                                );
                              }
                            }}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              onKeyDown: (e) => {
                                preventCharacterNumber(e);
                              },
                            }}
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name='weeklyWorkHours'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Weekly work hours",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input", maxLength: 5 }}
                            variant='outlined'
                            onChange={(e) => {
                              const val = e.target.value?.replaceAll(":", "");
                              if (val.length >= 2) {
                                input.onChange(
                                  val.slice(0, 2) +
                                    ":" +
                                    e.target.value.slice(3, 5)
                                );
                              } else {
                                input.onChange(val);
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value?.length < 5) {
                                input.onChange(
                                  minutes_to_hhmm(
                                    +e.target.value.slice(0, 2) * 60 +
                                      +e.target.value.slice(3, 5)
                                  )
                                );
                              }
                            }}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              onKeyDown: (e) => {
                                preventCharacterNumber(e);
                              },
                            }}
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <OvertimeSalary
                      overtimes={overtimes}
                      onChangeOvertimes={(ot, status: boolean) => {
                        if (status) {
                          setOvertimes(ot);
                        } else {
                          setOvertimes([]);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </div>

      <ModalFooter
        formId='staff-form'
        showDeleteButton={staffId ? "Delete account" : ""}
        handleDeleteAction={() => {
          setNotiDelete(true);
        }}
        handleCancelAction={() => history.push(APP_ROUTES.STAFF)}
        title={"Create New"}
        handleSubmit={() => {}}
      />

      <NotificationModal
        visible={createSuccess.id !== ""}
        title={"Employee Successfully created"}
        subTitle={
          <span>
            Employee
            <span style={{ fontWeight: 700 }}> {createSuccess?.fullName}</span>
            <span style={{ color: "#00529C" }}> #{createSuccess?.id} </span>has
            been created
          </span>
        }
        icon={
          <div className={`notification-icon-box blue`}>
            <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className='btn-confirm-style'
            onClick={() => {
              history.push(APP_ROUTES.STAFF);
            }}
          >
            Done
          </button>
        }
        onClose={() => {
          setCreateSuccess({ id: "", fullName: createSuccess.fullName });
        }}
      />

      <NotificationModal
        visible={notiDelete}
        title={"Delete Employee"}
        subTitle={
          <span>
            Are you sure you want to
            <br />
            delete employee
            <span style={{ fontWeight: 700 }}> {employeeDetail?.fullName}</span>
            <span style={{ color: "#00529C" }}> #{employeeDetail?.code} </span>
            has been created
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className='btn-cancel-style'
              onClick={() => {
                setNotiDelete(false);
              }}
            >
              Cancel
            </button>
            <button
              className='btn-confirm-style'
              onClick={() => {
                deleteEmployee(staffId);
              }}
            >
              Delete
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete(false);
        }}
      />
    </Dialog>
  );
};

export default StaffModal;
