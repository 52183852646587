import { AddRounded as AddRoundedIcon } from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { flatten, preventCharacter } from "../../../utils";
import { Dates } from "../../constants/mock";
import DefaultSwitch from "../Switch";
import "./index.scss";
import { RemoveIcon } from "../Icons/RemoveIcon";
import { DEFAULT_COLOR } from "../../core/Layout/routes";

type P_Props = {
  overtimes?: any[];
  onChangeOvertimes: (overtimes: any, status: boolean) => void;
};

const OvertimeSalary: React.FC<P_Props> = (props) => {
  const [overtime, setOvertime] = useState<boolean>(false);

  const [value, setValue] = useState<number>(1);
  const [overList, setOverList] = useState<any[]>([
    {
      id: 0,
      onDays: [],
      startAfter: "0",
      multiplier: "0",
    },
    ...[],
  ]);

  useEffect(() => {
    if (props.overtimes && props.overtimes?.length > 0) {
      setOvertime(true);
      setOverList(
        props.overtimes.map((d, idx) => {
          return {
            id: idx,
            onDays: d.onDays,
            startAfter: d.startAfter,
            multiplier: d.multiplier,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.overtimes && props.overtimes?.length > 0]);

  const onAddOT = (
    id: number,
    onDays: string[],
    startAfter: string,
    multiplier: string,
    addNew: boolean = false
  ) => {
    const idx = overList.findIndex((item) => item.id === id);
    let data = [...overList];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      if (onDays?.length < 1) {
        return;
      }

      data[idx] = {
        ...data[idx],
        id: id,
        onDays: onDays,
        startAfter: startAfter,
        multiplier: multiplier,
      };
    } else {
      data.push({ id, onDays, startAfter, multiplier });
    }

    setOverList(data);
  };

  useEffect(() => {
    props.onChangeOvertimes(
      overList.map((o) => {
        return {
          onDays: o.onDays,
          startAfter: +o.startAfter,
          multiplier: +o.multiplier,
        };
      }),
      overtime
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overList, overtime]);

  const checkData = overList
    ? flatten(
        overList?.map((o: any) => {
          return o?.onDays;
        })
      )
    : [];

  return (
    <div
      className='overtime-salary-modal'
      style={{ opacity: !overtime ? "0.5" : "" }}
    >
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <span
            className='overtime-salary-modal__label'
            style={{ paddingRight: 24 }}
          >
            Overtime salary
          </span>
          <DefaultSwitch
            checked={overtime}
            onChange={() => {
              setOvertime(!overtime);
            }}
          />
        </Grid>

        {overList.map((item, id: number) => {
          return (
            <Grid
              item
              xs={12}
              container
              rowSpacing={2}
              display='flex'
              alignItems='center'
              key={id}
            >
              <Grid item xs={3}>
                On days
              </Grid>
              <Grid item xs={9} display='flex'>
                {Dates.map((d, idx: number) => {
                  return (
                    <div key={idx}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!overtime}
                            checked={item.onDays?.some(
                              (e: any) => e === d.value
                            )}
                            onChange={() => {
                              onAddOT(
                                item.id,
                                checkData.includes(d.value)
                                  ? item.onDays.filter(
                                      (e: any) => e !== d.value
                                    )
                                  : [...item.onDays, d.value],
                                item.startAfter,
                                item.multiplier
                              );
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                                color: "#10BED2",
                              },
                            }}
                            name={d.value}
                          />
                        }
                        label={
                          <span
                            className={`overtime-salary-modal__check ${
                              item.onDays?.includes(d.value) &&
                              "overtime-salary-modal__active"
                            }`}
                          >
                            {d.label}
                          </span>
                        }
                      />
                    </div>
                  );
                })}
              </Grid>
              <Grid item xs={3}>
                Start after
              </Grid>
              <Grid
                item
                xs={9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  placeholder={"0"}
                  color='success'
                  className='form-text-field'
                  inputProps={{ className: "input" }}
                  variant='outlined'
                  onChange={(e) => {
                    onAddOT(
                      item.id,
                      item.onDays,
                      e.target.value,
                      item.multiplier
                    );
                  }}
                  value={item.startAfter}
                  autoComplete='off'
                  style={{ width: 75 }}
                  type='number'
                  InputProps={{
                    readOnly: !overtime ? true : false,
                    onKeyDown: (e) => {
                      preventCharacter(e);
                    },
                  }}
                />
                &ensp;Hours/day
              </Grid>
              <Grid item xs={3}>
                Multiplier
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    placeholder={"0"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    onChange={(e) => {
                      onAddOT(
                        item.id,
                        item.onDays,
                        item.startAfter,
                        e.target.value
                      );
                    }}
                    value={item.multiplier}
                    autoComplete='off'
                    style={{ width: 75 }}
                    type='number'
                    InputProps={{
                      readOnly: !overtime ? true : false,
                      onKeyDown: (e) => {
                        preventCharacter(e);
                      },
                    }}
                  />
                  &ensp;x&emsp;Current salary
                </div>
                {overList?.length > 1 && (
                  <div
                    className='btn-remove'
                    onClick={() => {
                      setOverList(overList.filter((_, index) => index !== id));
                    }}
                  >
                    <RemoveIcon
                      color={DEFAULT_COLOR}
                      size={[18, 18]}
                      viewBox={[18, 18]}
                    />
                    &ensp;Delete rule
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ borderColor: "#F0F3F8" }} />
              </Grid>
            </Grid>
          );
        })}

        <Grid
          item
          xs={12}
          className='overtime-salary-modal__title-add'
          onClick={() => {
            const newValue = value + 1;
            setValue(newValue);
            onAddOT(value, [], "0", "0", true);
          }}
        >
          <AddRoundedIcon fontSize='large' />
          &ensp;Add more daily rule
        </Grid>
      </Grid>
    </div>
  );
};

export default OvertimeSalary;
