import React from "react";
import clsx from "clsx";
import "./index.scss";
import { Dialog } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

type P_Props = {
  visible: boolean;
  icon: React.ReactNode;
  title: string;
  subTitle: string | React.ReactNode;
  actions: React.ReactNode;
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
};

const NotificationModal: React.FC<P_Props> = (props) => {
  const classes = clsx({
    "notification-comp": true,
    [props.className || ""]: Boolean(props.className),
  });

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      className={classes}
      open={props.visible}
      style={{ zIndex: 1502 }}
    >
      <ClearIcon
        style={{
          fontSize: "3rem",
          cursor: "pointer",
          margin: "2rem 2rem",
          position: "absolute",
          top: "1.6rem",
          right: "1.6rem",
        }}
        onClick={() => props.onClose()}
      />
      <div className='notification-comp__inner' style={props.style}>
        <div className='notification-comp__inner__icon'>{props.icon}</div>
        <div className='notification-comp__inner__title'>{props.title}</div>
        <div className='notification-comp__inner__sub-title'>
          {props.subTitle}
        </div>
        <div className='notification-comp__inner__actions'>{props.actions}</div>
      </div>
    </Dialog>
  );
};

export default NotificationModal;
