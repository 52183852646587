/* eslint-disable */
import React from "react";
import "./index.scss";

type Props = {
  title: string;
  formId: string;
  showDeleteButton: string;
  handleDeleteAction: (...args: any[]) => void;
  handleCancelAction: (...args: any[]) => void;
  handleSubmit: (...args: any[]) => void;
};

const ModalFooter: React.FC<Props> = ({
  handleCancelAction,
  showDeleteButton,
  handleDeleteAction,
  formId,
  title,
  handleSubmit,
}) => {
  return (
    <div className='modal-footer'>
      {showDeleteButton !== "" && (
        <button
          type='button'
          className='btn-delete'
          onClick={() => {
            handleDeleteAction();
          }}
        >
          {showDeleteButton}
        </button>
      )}
      <button
        type='button'
        className='btn-cancel'
        onClick={() => {
          handleCancelAction();
        }}
      >
        Cancel
      </button>
      <input
        value={showDeleteButton !== "" ? "Save" : title ? title : "Add"}
        aria-label='submit supplier form'
        form={formId}
        type='submit'
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export default React.memo(ModalFooter);
