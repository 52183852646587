import { useSnackbar } from "notistack";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

const useNoti = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const pushNoti = (
    notiType: "error" | "success" | "warning" | "info",
    message: string
  ): void => {
    enqueueSnackbar(message, {
      variant: notiType,
      action: (key) => {
        return (
          <Button onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ fontSize: "2.2rem", color: "#fff" }} />
          </Button>
        );
      },
    });
  };

  return [pushNoti] as const;
};

export default useNoti;
