import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const AbsenceIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M0.99205 12.0517C0.99205 9.82481 0.99205 7.59797 0.99205 5.35241C0.99205 4.58518 1.14175 3.87409 1.59086 3.25656C1.68443 3.12557 1.62829 3.06943 1.55344 2.99458C1.16047 2.60161 0.767495 2.20864 0.374523 1.81566C-0.0933009 1.34784 -0.112014 0.730313 0.299671 0.318628C0.711356 -0.130483 1.3476 -0.0930566 1.81542 0.374767C7.91584 6.47519 14.035 12.5756 20.1354 18.676C20.6968 19.2374 21.2395 19.7801 21.8009 20.3415C22.0816 20.6409 22.1751 20.9777 22.0628 21.3707C21.9506 21.7637 21.6699 21.9882 21.2769 22.0631C20.9026 22.1379 20.5845 22.0069 20.3225 21.745C20.0044 21.4268 20.0044 21.4456 19.6302 21.6514C19.1249 21.9508 18.5822 22.0818 18.0021 22.0818C13.38 22.0818 8.75793 22.0818 4.11711 22.0818C2.3581 22.0818 1.01076 20.7532 1.01076 18.9754C0.99205 16.6737 0.99205 14.3533 0.99205 12.0517ZM11.0409 20.0608C13.2865 20.0608 15.532 20.0608 17.7776 20.0608C17.9086 20.0608 18.0583 20.0421 18.1893 20.0234C18.2641 20.0047 18.3764 20.0234 18.4138 19.9298C18.4325 19.855 18.339 19.7988 18.2828 19.7427C15.139 16.5802 11.9578 13.4177 8.79535 10.2552C8.68307 10.1429 8.55208 10.0868 8.38367 10.0868C6.71821 10.0868 5.05276 10.0868 3.38731 10.0868C3.06919 10.0868 3.06919 10.0868 3.06919 10.4049C3.06919 12.3885 3.06919 14.3721 3.06919 16.3556C3.06919 17.2726 3.06919 18.1895 3.06919 19.1064C3.06919 19.593 3.3686 19.9485 3.85513 20.0421C3.98612 20.0795 4.13583 20.0795 4.26682 20.0795C6.53109 20.0608 8.79535 20.0608 11.0409 20.0608ZM6.53109 7.99094C6.47495 7.9348 6.41881 7.85995 6.36267 7.80381C5.40831 6.84945 4.43523 5.87638 3.48087 4.92202C3.38731 4.82845 3.31246 4.62261 3.16275 4.69746C3.03176 4.7536 3.06919 4.95944 3.06919 5.10914C3.06919 5.98865 3.06919 6.86816 3.06919 7.76638C3.06919 7.99094 3.14404 8.04708 3.34988 8.04708C4.34167 8.04708 5.31474 8.04708 6.30653 8.04708C6.38138 8.02837 6.45623 8.04708 6.53109 7.99094Z'
        fill='white'
      />
      <path
        d='M21.1084 9.75027C21.1084 11.3034 21.1084 12.8566 21.1084 14.4098C21.1084 14.7092 21.0523 15.0086 20.8465 15.2332C20.5658 15.5326 20.2102 15.6448 19.8172 15.5513C19.4243 15.4577 19.181 15.177 19.0687 14.7841C19.0313 14.6531 19.0313 14.5034 19.0313 14.3724C19.0313 13.0437 19.0313 11.7338 19.0313 10.4052C19.0313 10.0871 19.0313 10.0871 18.7319 10.0871C17.3659 10.0871 15.9998 10.0871 14.615 10.0871C14.1472 10.0871 13.773 9.84383 13.6045 9.45086C13.3239 8.77719 13.8104 8.04739 14.5402 8.04739C15.925 8.04739 17.3284 8.04739 18.7132 8.04739C18.9377 8.04739 19.0313 7.99125 19.0313 7.74798C19.0126 6.86847 19.0313 5.98896 19.0313 5.10945C19.0313 4.4545 18.6383 4.08024 18.0021 4.06153C17.4594 4.06153 16.9167 4.08024 16.3741 4.06153C16.1495 4.06153 16.0747 4.13638 16.0934 4.36094C16.1121 4.58549 16.0934 4.81005 16.0934 5.0346C16.0747 5.6147 15.6255 6.06382 15.0642 6.06382C14.5028 6.06382 14.0537 5.6147 14.0537 5.01589C14.0537 4.77262 14.0537 4.52935 14.0537 4.28608C14.0537 4.11767 13.9975 4.04282 13.8291 4.06153C13.7543 4.06153 13.6794 4.06153 13.6233 4.06153C11.9578 4.06153 10.2924 4.06153 8.6269 4.06153C7.87838 4.06153 7.37313 3.38786 7.59769 2.7142C7.74739 2.2838 8.12165 2.02182 8.58948 2.02182C10.3111 2.02182 12.0327 2.02182 13.7543 2.02182C14.0724 2.02182 14.0724 2.02182 14.0724 1.7037C14.0724 1.47914 14.0724 1.2733 14.0724 1.04874C14.0724 0.468642 14.5215 0.0195312 15.0642 0.0195312C15.6255 0.0195312 16.0934 0.449929 16.1121 1.03003C16.1121 1.25459 16.1121 1.47914 16.1121 1.7037C16.1121 2.02182 16.1121 2.02182 16.4489 2.02182C17.029 2.02182 17.6091 2.02182 18.1892 2.02182C19.6675 2.04053 20.9587 3.21945 21.1084 4.69777C21.1272 4.94104 21.1459 5.18431 21.1459 5.42758C21.1084 6.86847 21.1084 8.30937 21.1084 9.75027Z'
        fill='white'
      />
    </IconBase>
  );
};
