import { createTheme } from "@mui/material/styles";

export const StatusUIUtil = {
  renderStatusStaff,
  renderStatusStaffLabel,
  stringAvatarSite,
};

function renderStatusStaff(value: string) {
  switch (value) {
    case "Active":
      return (
        <div className='statusStaff' style={{ background: "#1AB759" }}></div>
      );
    case "Unregistered":
      return (
        <div className='statusStaff' style={{ background: "#E93C3C" }}></div>
      );
    case "Disable":
      return (
        <div className='statusStaff' style={{ background: "#6C778D" }}></div>
      );
    case "Send Invitation":
      return (
        <div className='statusStaff' style={{ background: "#E89806" }}></div>
      );
    default:
      return (
        <div className='statusStaff' style={{ background: "#000000" }}></div>
      );
  }
}

function renderStatusStaffLabel(value: string) {
  switch (value) {
    case "Active":
      return (
        <span
          className='statusStaffLabel'
          style={{ color: "#1AB759", background: "#E3FFEE" }}
        >
          Active
        </span>
      );
    case "Unregistered":
      return (
        <span className='statusStaffLabel' style={{ color: "#E93C3C" }}>
          Unregistered
        </span>
      );
    case "Disable":
      return (
        <span className='statusStaffLabel' style={{ color: "#6C778D" }}>
          Disable
        </span>
      );
    case "Send Invitation":
      return (
        <span className='statusStaffLabel' style={{ color: "#E89806" }}>
          Send Invitation
        </span>
      );
    default:
      return <span className='statusStaffLabel'></span>;
  }
}

// style ava site
function stringAvatarSite(name: string) {
  return {
    sx: {
      bgcolor: "#41AD49",
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    children: name.split(" ")?.[1]?.[0]
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase()
      : `${name.split(" ")[0][0]}`.toUpperCase(),
  };
}

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.3rem",
          lineHeight: "1.9rem",
          color: " #FFFFFF",
          backgroundColor: "#312F30",
          fontFamily: "SVN-Gotham",
          fontWeight: 400,
          boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
          borderRadius: "0.4rem",
        },
      },
    },
  },
});
