/* eslint-disable */
export const FormUIUtil = {
  renderFormItem,
};

function renderFormItem(label: string, value: any, withoutIcon = false) {
  const icon = (
    <span
      style={{
        color: !withoutIcon ? "#E93C3C" : "transparent",
        paddingLeft: 2,
      }}
    >
      *
    </span>
  );

  return (
    <div className='form-item'>
      <div className='form-label'>
        {label}
        {icon}
      </div>
      {value}
    </div>
  );
}
