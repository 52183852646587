import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./SubNavbar.scss";
import { ModuleItem } from "./routes";

export type Props = {
  routes?: ModuleItem[];
};

const SubNavbar: React.FC<Props> = (props) => {
  const location = useLocation();
  const isActiveRoute = (pathname: string): boolean => {
    return location.pathname === pathname;
  };

  return (
    <div className="wrapper">
      <div className={"sub-route-menu"}>
        <div style={{ display: "flex" }}>
          {props.routes?.map(({ title, defaultIcon, activeIcon, path }) => {
            const isActive = isActiveRoute(path);
            
            return (
              <Link key={title} to={path}>
                <div
                  className={
                    isActive
                      ? "nav-item nav-item__active btn--shiny"
                      : "nav-item nav-item-hover"
                  }
                >
                  <div className="icon">
                    {isActive ? activeIcon : defaultIcon}
                  </div>
                  <span className="title">{title}</span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubNavbar;
