import {
  ClearRounded as ClearRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Avatar from "../../../../assets/images/avatar-df.png";
import { useQuery } from "../../../../hooks";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { EmployeeService, TimesheetService } from "../../../../services";
import {
  checkLinkImage,
  formatEnDE,
  FormUIUtil,
  hhmm_to_hhmmaMINUTE,
  renderErrorImage,
} from "../../../../utils";
import ChooseTimeshift from "../../../components/ChooseTimeshift";
import ModalFooter from "../../../components/ModalFooter";
import NotificationModal from "../../../components/NotificationModal";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import SingleDatePicker from "../../../components/SinglePicker";
import { DEFAULT_IMG_ALT } from "../../../constants";
import { MESSAGES } from "../../../constants/mock";
import "./index.scss";

type P_Props = {
  onClose: () => void;
  data?: any;
  dateByWeek?: string;
};

const ShiftModal: React.FC<P_Props> = (props) => {
  const query = useQuery();
  const [pushNoti] = useNoti();
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [checkEmployee, setCheckEmployee] = useState<any[]>([]);
  const [chooseTimeshift, setChooseTimeshift] = useState<any[]>([
    {
      id: 0,
      title: "",
      startTime: "",
      endTime: "",
      breaktimeStart: "",
      breaktimeEnd: "",
      breaktime: false,
    },
  ]);
  const [date, setDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [repeat, setRepeat] = useState<string>("NONE");
  const [overlap, setOverlap] = useState<number | null>(null);
  const [overlapAbsence, setOverlapAbsence] = useState<any[] | null>(null);

  const fetchData = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (query.get("employIds")) {
      setCheckEmployee([
        listEmployee.find(
          (f) => f._id === query.get("employIds")?.split(",")[0]
        ),
      ]);
    }
    if (props.data && listEmployee) {
      setDate(
        props?.dateByWeek
          ? props.data?.timekeepings?.filter(
              (f: any) =>
                props?.dateByWeek ===
                f.date?.slice(f.date?.length - 2, f.date?.length)
            )?.[0]?.date
          : props.data?.timekeepings?.[0]?.date
      );
      setCheckEmployee([listEmployee.find((f) => f._id === props.data?._id)]);
      setChooseTimeshift(
        (props?.dateByWeek
          ? props.data?.timekeepings?.filter(
              (f: any) =>
                props?.dateByWeek ===
                f.date?.slice(f.date?.length - 2, f.date?.length)
            )?.[0]?.timeshifts
          : props.data?.timekeepings?.[0]?.timeshifts
        )?.map((d: any, idx: number) => {
          return {
            id: idx,
            title: d.title,
            startTime: d.startTime,
            endTime: d.endTime,
            breaktimeStart: d.breaktime?.startTime,
            breaktimeEnd: d.breaktime?.endTime,
            breaktime: d.breaktime ? true : false,
          };
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, listEmployee]);

  const addShift = async (values: any) => {
    const employeeIds = checkEmployee.map((e) => {
      return e._id;
    });

    if (employeeIds.length < 1) {
      pushNoti(AppNotifications.ERROR, "Please select employee");
      return;
    }

    let val = {
      ...values,
      repeat:
        repeat !== "NONE"
          ? {
              number: +repeat,
              endDate: endDate
                ? moment(endDate).format("YYYY-MM-DD")
                : moment().format("YYYY-MM-DD"),
            }
          : undefined,
      date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      employeeIds: employeeIds,
      timeshifts: chooseTimeshift.map((c) => {
        return {
          title: c.title,
          startTime: c.startTime,
          endTime: c.endTime,
          breaktime: c.breaktime
            ? { startTime: c.breaktimeStart, endTime: c.breaktimeEnd }
            : undefined,
        };
      }),
    };

    try {
      const res = await TimesheetService.createTimekeeping(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setOverlap(null);
        if (res.data?.data?.overlapAbsences?.length > 0) {
          setOverlapAbsence(res.data?.data?.overlapAbsences);
        } else {
          // eslint-disable-next-line
          window.location.href = window.location.href;
          props.onClose();
        }
      } else if (res && res.status === 400) {
        if (res.data?.message === "OVERLAP_SHIFT") {
          setOverlap(res.data?.data?.shift);
        } else if (res.data?.data?.overlapAbsences?.length > 0) {
          setOverlapAbsence(res.data?.data?.overlapAbsences);
        } else {
          pushNoti(AppNotifications.ERROR, res.data?.message);
        }
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const updateShift = async (values: any) => {
    let val = {
      _id: props?.dateByWeek
        ? props.data?.timekeepings?.filter(
            (f: any) =>
              props?.dateByWeek ===
              f.date?.slice(f.date?.length - 2, f.date?.length)
          )?.[0]?._id
        : props.data?.timekeepings?.[0]?._id,
      timeshifts: chooseTimeshift.map((c) => {
        return {
          title: c.title,
          startTime: c.startTime,
          endTime: c.endTime,
          breaktime: c.breaktime
            ? { startTime: c.breaktimeStart, endTime: c.breaktimeEnd }
            : undefined,
        };
      }),
    };

    try {
      const res = await TimesheetService.updateTimekeeping(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        props.onClose();
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        if (res.data?.message === "OVERLAP_SHIFT") {
          setOverlap(res.data?.data?.shift);
        } else {
          pushNoti(AppNotifications.ERROR, res.data?.message);
        }
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      // style={{ zIndex: 1501 }}
    >
      <div className="shift-modal">
        <SimpleModalHeader
          title={props.data ? "Edit Timeshift" : "Add shift"}
          onClose={props.onClose}
        />
        <div className="shift-modal__content">
          <Form
            onSubmit={async (values) => {
              props.data ? updateShift(values) : addShift(values);
            }}
            initialValues={{ repeat: "NONE" }}
          >
            {({ handleSubmit }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  id="shift-modal"
                  className="shift-modal__form"
                >
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12}>
                      {FormUIUtil.renderFormItem(
                        "Employee",
                        <Autocomplete
                          fullWidth
                          multiple
                          disableClearable
                          readOnly={props.data}
                          value={checkEmployee}
                          // defaultValue={listEmployee?.[0]}
                          id="checkboxes-tags-demo"
                          options={listEmployee}
                          getOptionLabel={(options) =>
                            options.fullName + " " + options?.code
                          }
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                avatar={
                                  <img
                                    src={
                                      checkLinkImage(
                                        option?.avatar as string
                                      ) || Avatar
                                    }
                                    alt={DEFAULT_IMG_ALT}
                                    onError={renderErrorImage()}
                                    style={{ borderRadius: "50%" }}
                                  />
                                }
                                label={option?.fullName}
                                style={{
                                  borderRadius: 5,
                                  fontSize: 14,
                                  background: "#F0F3F8",
                                  fontFamily: "Nunito Sans",
                                }}
                                deleteIcon={
                                  <ClearRoundedIcon
                                    style={{ color: "#BDC6D7" }}
                                  />
                                }
                                onDelete={
                                  props.data
                                    ? undefined
                                    : () => {
                                        checkEmployee.some(
                                          (e) => e.code === option.code
                                        )
                                          ? setCheckEmployee(
                                              checkEmployee.filter(
                                                (e) => e.code !== option.code
                                              )
                                            )
                                          : setCheckEmployee([
                                              ...checkEmployee,
                                              option,
                                            ]);
                                      }
                                }
                              />
                            ));
                          }}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                    color: "#10BED2",
                                  },
                                }}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                onChange={() => {
                                  checkEmployee.some(
                                    (e) => e.code === option.code
                                  )
                                    ? setCheckEmployee(
                                        checkEmployee.filter(
                                          (e) => e.code !== option.code
                                        )
                                      )
                                    : setCheckEmployee([
                                        ...checkEmployee,
                                        option,
                                      ]);
                                }}
                              />
                              <div className="autocomplete-check">
                                <img
                                  src={
                                    checkLinkImage(option?.avatar as string) ||
                                    Avatar
                                  }
                                  alt={DEFAULT_IMG_ALT}
                                  onError={renderErrorImage()}
                                />
                                <div className="autocomplete-check__name">
                                  {option.fullName}
                                  <div className="autocomplete-check__code">
                                    {option.code}
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                          PaperComponent={({ children }) => {
                            return (
                              <Paper>
                                <div className="autocomplete-check__header">
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 20,
                                        color: "#10BED2",
                                      },
                                    }}
                                    style={{ marginRight: 8 }}
                                    checked={
                                      checkEmployee.length ===
                                      listEmployee?.length
                                    }
                                    onMouseDown={() => {
                                      if (
                                        checkEmployee.length !==
                                        listEmployee?.length
                                      ) {
                                        setCheckEmployee(listEmployee);
                                      } else {
                                        setCheckEmployee([]);
                                      }
                                    }}
                                  />
                                  All employee ({listEmployee?.length})
                                </div>
                                <Divider
                                  style={{
                                    borderColor: "#F0F3F8",
                                    marginTop: 12,
                                  }}
                                />
                                {children}
                              </Paper>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={props.data ? "" : "Select employee"}
                              className="autocomplete-check__text-field"
                            />
                          )}
                          style={{ background: props.data ? "#f2f2f2" : "" }}
                        />,
                        false
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {FormUIUtil.renderFormItem(
                        "Select Date",
                        <SingleDatePicker
                          day={date}
                          onChangeDate={(value) => {
                            setDate(value);
                          }}
                          disabled={props.data}
                        />,
                        false
                      )}
                    </Grid>
                    {!props.data && (
                      <Grid item xs={12}>
                        {FormUIUtil.renderFormItem(
                          "Repeat",
                          <RadioGroup
                            defaultValue={repeat}
                            style={{ flexDirection: "row" }}
                            onChange={(e) => {
                              setRepeat(e.target.value);
                              if (e.target.value !== "NONE") {
                                setTimeout(() => {
                                  if (date) {
                                    setEndDate(
                                      moment(date)
                                        .add(1 * +e.target.value, "w")
                                        .format("YYYY-MM-DD")
                                    );
                                  } else {
                                    setEndDate(
                                      moment()
                                        .add(1 * +e.target.value, "w")
                                        .format("YYYY-MM-DD")
                                    );
                                  }
                                }, 300);
                              } else {
                                setEndDate(null);
                              }
                            }}
                          >
                            <FormControlLabel
                              value="NONE"
                              control={<Radio style={{ color: "#10BED2" }} />}
                              label={
                                <span className="shift-modal__form__radio">
                                  No repeat
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio style={{ color: "#10BED2" }} />}
                              label={
                                <span className="shift-modal__form__radio">
                                  1 week
                                </span>
                              }
                              sx={{ marginLeft: "1rem" }}
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio style={{ color: "#10BED2" }} />}
                              label={
                                <span className="shift-modal__form__radio">
                                  2 weeks
                                </span>
                              }
                              sx={{ marginLeft: "1rem" }}
                            />
                          </RadioGroup>,
                          true
                        )}
                      </Grid>
                    )}
                    {repeat !== "NONE" && (
                      <Grid item xs={12}>
                        {FormUIUtil.renderFormItem(
                          "End Date",
                          <SingleDatePicker
                            day={endDate}
                            onChangeDate={(value) => {
                              setEndDate(value);
                            }}
                          />,
                          true
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className="shift-modal__form__title">
                        Choose timeshift
                      </div>
                      <ChooseTimeshift
                        data={chooseTimeshift}
                        onChange={(shift: any) => {
                          setChooseTimeshift(shift);
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="shift-modal"
          showDeleteButton={""}
          handleDeleteAction={() => {}}
          handleCancelAction={props.onClose}
          title={props.data ? "Save" : "Add"}
          handleSubmit={() => {}}
        />
      </div>

      <NotificationModal
        visible={overlap !== null}
        title={"Overlap Shift"}
        subTitle={
          <span>
            You have overlap <b>{overlap} shifts</b>. Do you want to replace?
          </span>
        }
        icon={
          <div className={`notification-icon-box warning`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className="btn-cancel-style"
              onClick={() => {
                addShift({ overlap: "skip" });
              }}
            >
              Skip All
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                addShift({ overlap: "replace" });
              }}
            >
              Replace All
            </button>
          </>
        }
        onClose={() => {
          setOverlap(null);
        }}
      />

      <NotificationModal
        visible={overlapAbsence !== null}
        title={"Overlap Absence"}
        subTitle={
          <span>
            <div>
              Your shifts are overlapping with
              <b> {overlapAbsence?.length} absence shifts</b>:
            </div>
            <div className="notification-absence">
              {overlapAbsence?.map((ab, idx: number) => {
                return (
                  <div key={idx} className="notification-absence__item">
                    {formatEnDE(moment(ab?.date).format("YYYY-MM-DD"))}{" "}
                    {hhmm_to_hhmmaMINUTE(ab?.startTime) +
                      " - " +
                      hhmm_to_hhmmaMINUTE(ab?.endTime)}
                  </div>
                );
              })}
            </div>
          </span>
        }
        icon={
          <div className={`notification-icon-box warning`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              setOverlapAbsence(null);
              // eslint-disable-next-line
              window.location.href = window.location.href;
              props.onClose();
            }}
          >
            Close
          </button>
        }
        onClose={() => {
          setOverlapAbsence(null);
          // eslint-disable-next-line
          window.location.href = window.location.href;
          props.onClose();
        }}
      />
    </Dialog>
  );
};

export default ShiftModal;
