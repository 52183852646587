import { AddRounded as AddRoundedIcon } from "@mui/icons-material";
import { Divider, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormUIUtil } from "../../../utils";
import { RemoveIcon } from "../Icons/RemoveIcon";
import "./index.scss";

type P_Props = {
  data?: any;
  onChange: (shift: any) => void;
};

const ChooseTimeshift: React.FC<P_Props> = (props) => {
  const [value, setValue] = useState<number>(1);

  const [shiftList, setShiftList] = useState<any[]>([]);

  useEffect(() => {
    setShiftList(props.data);
  }, [props.data]);

  const onChooseTimeshift = (
    id: number,
    title: string,
    startTime: string,
    endTime: string,
    breaktimeStart: string,
    breaktimeEnd: string,
    breaktime: boolean = false,
    addNew: boolean = false
  ) => {
    const idx = shiftList.findIndex((item) => item.id === id);
    let data = [...shiftList];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      data[idx] = {
        ...data[idx],
        id: id,
        title: title,
        startTime: startTime,
        endTime: endTime,
        breaktimeStart: breaktimeStart,
        breaktimeEnd: breaktimeEnd,
        breaktime: breaktime,
      };
    } else {
      data.push({
        id,
        title,
        startTime,
        endTime,
        breaktimeStart,
        breaktimeEnd,
        breaktime,
      });
    }

    props.onChange(data);
  };

  return (
    <div className='choose-timeshift'>
      {shiftList?.map((o, idx: number) => {
        return (
          <div key={idx} className='choose-timeshift__item'>
            <Grid
              container
              className='choose-timeshift__item__form'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={12}>
                {FormUIUtil.renderFormItem(
                  "Shift Title",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    onChange={(e) =>
                      onChooseTimeshift(
                        o.id,
                        e.target.value,
                        o.startTime,
                        o.endTime,
                        o.breaktimeStart,
                        o.breaktimeEnd,
                        o.breaktime
                      )
                    }
                    autoComplete='off'
                    value={o.title}
                  />,
                  true
                )}
              </Grid>
              <Grid item xs={5}>
                {FormUIUtil.renderFormItem(
                  "Start time",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{
                      className: "input",
                    }}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      onChooseTimeshift(
                        o.id,
                        o.title,
                        e.target.value,
                        o.endTime,
                        o.breaktimeStart,
                        o.breaktimeEnd,
                        o.breaktime
                      )
                    }
                    type='time'
                    defaultValue={
                      o.startTime ||
                      shiftList[idx]?.startTime ||
                      props.data[idx]?.startTime
                    }
                  />,
                  true
                )}
              </Grid>
              <Grid item xs={2}>
                &#8212;
              </Grid>
              <Grid item xs={5}>
                {FormUIUtil.renderFormItem(
                  "End time",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      onChooseTimeshift(
                        o.id,
                        o.title,
                        o.startTime,
                        e.target.value,
                        o.breaktimeStart,
                        o.breaktimeEnd,
                        o.breaktime
                      )
                    }
                    type='time'
                    defaultValue={
                      o.endTime ||
                      shiftList[idx]?.endTime ||
                      props.data[idx]?.endTime
                    }
                  />,
                  true
                )}
              </Grid>
              {o.breaktime && (
                <Grid
                  item
                  xs={12}
                  container
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Grid
                    item
                    xs={12}
                    className='choose-timeshift__item__form__breaktime'
                  >
                    Breaktime
                    <Divider
                      style={{
                        borderColor: "#E0E6F0",
                        margin: "0.8rem 0 1.6rem",
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {FormUIUtil.renderFormItem(
                      "Start time",
                      <TextField
                        fullWidth
                        placeholder={"Place holder"}
                        color='success'
                        className='form-text-field'
                        inputProps={{ className: "input" }}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          onChooseTimeshift(
                            o.id,
                            o.title,
                            o.startTime,
                            o.endTime,
                            e.target.value,
                            o.breaktimeEnd,
                            o.breaktime
                          )
                        }
                        type='time'
                        defaultValue={o.breaktimeStart}
                      />,
                      true
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    &#8212;
                  </Grid>
                  <Grid item xs={5}>
                    {FormUIUtil.renderFormItem(
                      "End time",
                      <TextField
                        fullWidth
                        placeholder={"Place holder"}
                        color='success'
                        className='form-text-field'
                        inputProps={{ className: "input" }}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          onChooseTimeshift(
                            o.id,
                            o.title,
                            o.startTime,
                            o.endTime,
                            o.breaktimeStart,
                            e.target.value,
                            o.breaktime
                          )
                        }
                        type='time'
                        defaultValue={o.breaktimeEnd}
                      />,
                      true
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      style={{ marginTop: 20, cursor: "pointer" }}
                      onClick={() => {
                        onChooseTimeshift(
                          o.id,
                          o.title,
                          o.startTime,
                          o.endTime,
                          o.breaktimeStart,
                          o.breaktimeEnd,
                          false
                        );
                      }}
                    >
                      <RemoveIcon
                        color={"#6C778D"}
                        size={[18, 18]}
                        viewBox={[18, 18]}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {(shiftList?.length > 1 || !o.breaktime) && (
              <div className='choose-timeshift__item__group'>
                {!o.breaktime && (
                  <div
                    className='choose-timeshift__item__group__btn'
                    onClick={() => {
                      onChooseTimeshift(
                        o.id,
                        o.title,
                        o.startTime,
                        o.endTime,
                        o.breaktimeStart,
                        o.breaktimeEnd,
                        true
                      );
                    }}
                  >
                    <AddRoundedIcon fontSize='large' />
                    &ensp;Add breaktime
                  </div>
                )}
                {shiftList?.length > 1 && (
                  <div
                    className='choose-timeshift__item__group__btn'
                    onClick={() => {
                      setShiftList(
                        shiftList.filter((_, index) => index !== idx)
                      );
                    }}
                  >
                    &emsp;
                    <RemoveIcon
                      color={"#6C778D"}
                      size={[18, 18]}
                      viewBox={[18, 18]}
                    />
                    &ensp;Delete timeshift
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
      <div
        className='choose-timeshift__title-add'
        onClick={() => {
          const newValue = value + 1;
          setValue(newValue);
          onChooseTimeshift(value, "", "", "", "", "", false, true);
        }}
      >
        <AddRoundedIcon fontSize='large' />
        &ensp;Add more timeshift
      </div>
    </div>
  );
};

export default ChooseTimeshift;
