import { REQUEST_URL } from "../app/constants";
import { RequestUtil } from "../utils";

export const EmailService = {
  getEmail: () => {
    const url = REQUEST_URL.EMAIL;
    return RequestUtil.get({ url });
  },

  createEmail: (data: any) => {
    const url = REQUEST_URL.EMAIL;
    return RequestUtil.post({ url, data });
  },

  updateEmail: (data: any) => {
    const url = REQUEST_URL.EMAIL;
    return RequestUtil.put({ url, data });
  },

  deleteEmail: (id: string) => {
    const url = REQUEST_URL.DELETE_EMAIL.replace("_id", id);
    return RequestUtil.delete({ url });
  },

  activeEmail: (data: any) => {
    const url = REQUEST_URL.ACTIVE_EMAIL;
    return RequestUtil.put({ url, data });
  },
};
