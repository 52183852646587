import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const HistoryIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M9.5333 1.66699C7.39695 1.67309 5.34454 2.49942 3.79997 3.97533V2.50033C3.79997 2.27931 3.71217 2.06735 3.55589 1.91107C3.39961 1.75479 3.18765 1.66699 2.96663 1.66699C2.74562 1.66699 2.53366 1.75479 2.37738 1.91107C2.2211 2.06735 2.1333 2.27931 2.1333 2.50033V6.25033C2.1333 6.47134 2.2211 6.6833 2.37738 6.83958C2.53366 6.99586 2.74562 7.08366 2.96663 7.08366H6.71663C6.93765 7.08366 7.14961 6.99586 7.30589 6.83958C7.46217 6.6833 7.54997 6.47134 7.54997 6.25033C7.54997 6.02931 7.46217 5.81735 7.30589 5.66107C7.14961 5.50479 6.93765 5.41699 6.71663 5.41699H4.71663C5.47639 4.6145 6.42372 4.01346 7.47341 3.66794C8.52309 3.32242 9.64221 3.24326 10.7301 3.43758C11.818 3.6319 12.8405 4.0936 13.7056 4.78115C14.5708 5.4687 15.2514 6.36054 15.6864 7.37643C16.1213 8.39233 16.2969 9.50041 16.1974 10.601C16.0978 11.7016 15.7262 12.7602 15.116 13.6815C14.5058 14.6029 13.6761 15.3581 12.7016 15.8792C11.7271 16.4003 10.6384 16.6711 9.5333 16.667C9.31229 16.667 9.10033 16.7548 8.94405 16.9111C8.78777 17.0674 8.69997 17.2793 8.69997 17.5003C8.69997 17.7213 8.78777 17.9333 8.94405 18.0896C9.10033 18.2459 9.31229 18.3337 9.5333 18.3337C11.7434 18.3337 13.8631 17.4557 15.4259 15.8929C16.9887 14.3301 17.8666 12.2105 17.8666 10.0003C17.8666 7.79019 16.9887 5.67057 15.4259 4.10777C13.8631 2.54497 11.7434 1.66699 9.5333 1.66699ZM9.5333 6.66699C9.31229 6.66699 9.10033 6.75479 8.94405 6.91107C8.78777 7.06735 8.69997 7.27931 8.69997 7.50033V10.0003C8.69997 10.2213 8.78777 10.4333 8.94405 10.5896C9.10033 10.7459 9.31229 10.8337 9.5333 10.8337H11.2C11.421 10.8337 11.6329 10.7459 11.7892 10.5896C11.9455 10.4333 12.0333 10.2213 12.0333 10.0003C12.0333 9.77931 11.9455 9.56735 11.7892 9.41107C11.6329 9.25479 11.421 9.16699 11.2 9.16699H10.3666V7.50033C10.3666 7.27931 10.2788 7.06735 10.1226 6.91107C9.96628 6.75479 9.75431 6.66699 9.5333 6.66699Z"
        fill={props.color}
      />
    </IconBase>
  );
};
