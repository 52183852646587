/*eslint-disable */
import {
  ArrowBackIosRounded as ArrowBackIosRoundedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../../assets/images/avatar-df.png";
import Line from "../../../assets/images/icons/line.png";
import { useQuery } from "../../../hooks";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications } from "../../../models";
import { EmployeeService, TimesheetService } from "../../../services";
import {
  checkLinkImage,
  dates,
  formatEnDE,
  formatEnDENoWeek,
  hhmm_to_hhmma,
  hhmm_to_hhmmaMINUTE,
  minutes_to_hhmm,
  renderErrorImage,
  TimeList,
} from "../../../utils";
import ExportFile from "../../components/ExportFile";
import FloatingBtn from "../../components/FloatingBtn";
import { AbsenceIcon } from "../../components/Icons/AbsenceIcon";
import { AddUserIcon } from "../../components/Icons/AddUserIcon";
import { ExportIcon } from "../../components/Icons/ExportIcon";
import { LocationIcon } from "../../components/Icons/LocationIcon";
import { TimesheetSubIcon } from "../../components/Icons/TimesheetSubIcon";
import NotificationModal from "../../components/NotificationModal";
import SimpleModalHeader from "../../components/SimpleModalHeader";
import SingleDatePicker from "../../components/SinglePicker";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../constants";
import { MESSAGES, SelectTimeExport } from "../../constants/mock";
import { APP_ROUTES } from "../../constants/module";
import { ACTIVE_COLOR, DEFAULT_COLOR } from "../../core/Layout/routes";
import AbsenceModal from "./AbsenceModal";
import DailyList from "./DailyList";
import "./index.scss";
import ShiftModal from "./ShiftModal";
import CheckIn from "../../../assets/images/icons/icon_checkin.png";
import CheckOut from "../../../assets/images/icons/icon_checkout.png";
import { EditIcon } from "../../components/Icons/EditIcon";

const Timesheet: React.FC = () => {
  const history = useHistory();
  const [pushNoti] = useNoti();
  const query = useQuery();
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [time, setTime] = useState<string>("weekly");
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [date, setDate] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [visibleFloatingBtn, setVisibleFloatingBtn] = useState<boolean>(false);
  const [shiftModal, setShiftModal] = useState<boolean>(false);
  const [absenceModal, setAbsenceModal] = useState<boolean>(false);
  const [checkEmployee, setCheckEmployee] = useState<any[]>([]);
  const [shiftEdit, setShiftEdit] = useState<{ data: any; date: string }>({
    data: null,
    date: "",
  });
  const [absenceEdit, setAbsenceEdit] = useState<{ data: any; date: string }>({
    data: null,
    date: "",
  });
  const [notiDelete, setNotiDelete] = useState<{ title: string; id: string }>({
    title: "",
    id: "",
  });
  const [detailDaily, setDetailDaily] = useState<{
    type: string;
    val: any;
    date: string;
  }>({
    type: "",
    val: null,
    date: "",
  });

  const listId = listEmployee.map((i) => {
    return i._id;
  });

  const fetchDataEmployee = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      const result = await TimesheetService.getTimekeeping({
        date: moment(date).format("YYYY-MM-DD"),
        employeeIds: checkEmployee.length > 0 ? checkEmployee : listId,
        type: time,
      });
      if (result.data.data) {
        setList(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataEmployee();
    setDate(moment().format("YYYY-MM-DD"));
    if (query.get("time")) {
      setTime(query.get("time") as string);
    }
    if (query.get("date")) {
      setDate(query.get("date"));
    }
    history.push(
      APP_ROUTES.TIMESHEET_TIMEKEEPING +
        "?time=" +
        time +
        "&date=" +
        moment().format("YYYY-MM-DD")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (date) {
      setDate(moment(date).format("YYYY-MM-DD"));
      history.push(
        APP_ROUTES.TIMESHEET_TIMEKEEPING + "?time=" + time + "&date=" + date
      );
    }
    if (date && listEmployee && listEmployee?.length > 0) {
      fetchData();
    }

    if (checkEmployee && checkEmployee?.length > 0) {
      history.push(
        APP_ROUTES.TIMESHEET_TIMEKEEPING +
          "?time=" +
          time +
          "&date=" +
          date +
          "&employIds=" +
          checkEmployee
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, listEmployee, time, checkEmployee]);

  useEffect(() => {
    if (listEmployee && query.get("employIds")) {
      setCheckEmployee(query.get("employIds")?.split(",") as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listEmployee]);

  const detail = list[0];

  const delelteTimekeeping = async (id: string) => {
    try {
      const res = await TimesheetService.delelteTimekeeping(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({
          title: notiDelete.title,
          id: "",
        });
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const delelteAbsence = async (id: string) => {
    try {
      const res = await TimesheetService.delelteAbsence(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({
          title: notiDelete.title,
          id: "",
        });
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  return (
    <div className="time-sheet">
      <div className="time-sheet__content">
        <div className="time-sheet__content__action">
          <div className="range-picker-cpn">
            <button
              className="btn-cancel-style btn-back"
              onClick={() => {
                if (time === "weekly") {
                  setDate(moment(date).subtract(7, "days"));
                } else {
                  setDate(moment(date).subtract(1, "days"));
                }
              }}
            >
              <ArrowBackIosRoundedIcon />
            </button>
            <div
              className={`time-sheet__content__action__date ${
                time !== "weekly" && "date__val__daily"
              }`}
            >
              <SingleDatePicker
                day={date}
                onChangeDate={(value) => {
                  setDate(value);
                }}
                iconStart
              />
              <div className="time-sheet__content__action__date__val">
                {time === "weekly"
                  ? formatEnDENoWeek(dates(new Date(date))[0]) +
                    " - " +
                    formatEnDENoWeek(dates(new Date(date))[6])
                  : formatEnDENoWeek(new Date(date) as any)}
              </div>
            </div>
            <button
              className="btn-cancel-style btn-forward"
              onClick={() => {
                if (time === "weekly") {
                  setDate(moment(date).add(1, "w"));
                } else {
                  setDate(moment(date).add(1, "days"));
                }
              }}
            >
              <ArrowForwardIosRoundedIcon />
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Autocomplete
              multiple
              sx={{ width: 270, height: 45 }}
              disableClearable
              value={checkEmployee}
              id="checkboxes-tags-demo"
              options={listEmployee}
              getOptionLabel={(options) =>
                options.fullName + " " + options?.code
              }
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              PaperComponent={({ children }) => {
                return (
                  <Paper>
                    {time !== "daily" && (
                      <>
                        <div className="autocomplete-check__header">
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                                color: "#10BED2",
                              },
                            }}
                            style={{ marginRight: 8 }}
                            checked={
                              checkEmployee.length === listEmployee?.length
                            }
                            onMouseDown={() => {
                              if (
                                checkEmployee.length !== listEmployee?.length
                              ) {
                                setCheckEmployee(listId);
                              } else {
                                setCheckEmployee([]);
                              }
                            }}
                          />
                          All employee ({listEmployee?.length})
                        </div>
                        <Divider
                          style={{ borderColor: "#F0F3F8", marginTop: 12 }}
                        />
                      </>
                    )}
                    {children}
                  </Paper>
                );
              }}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={() => {
                    setOpen(true);
                    checkEmployee.some((e) => e === option._id)
                      ? setCheckEmployee(
                          checkEmployee.filter((e) => e !== option._id)
                        )
                      : time === "daily"
                      ? checkEmployee?.length < 5 &&
                        setCheckEmployee([...checkEmployee, option._id])
                      : setCheckEmployee([...checkEmployee, option._id]);
                  }}
                >
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 20,
                        color: "#10BED2",
                      },
                    }}
                    style={{ marginRight: 8 }}
                    checked={checkEmployee.some((e) => e === option._id)}
                  />
                  <div className="autocomplete-check">
                    <img
                      src={checkLinkImage(option?.avatar as string) || Avatar}
                      alt={DEFAULT_IMG_ALT}
                      onError={renderErrorImage()}
                    />
                    <div className="autocomplete-check__name">
                      {option.fullName}
                      <div className="autocomplete-check__code">
                        {option.code}
                      </div>
                    </div>
                  </div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`Select employee ${
                    checkEmployee?.length > 0
                      ? "(" + checkEmployee?.length + ")"
                      : ""
                  }`}
                  className="autocomplete-check__text-field employee-check"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ marginLeft: 10, marginTop: 1 }}>
                          <AddUserIcon
                            color={DEFAULT_COLOR}
                            size={[25, 18]}
                            viewBox={[25, 18]}
                          />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Select
              value={time}
              onChange={(e) => {
                setTime(e.target.value);
                setCheckEmployee([]);
                history.push(
                  APP_ROUTES.TIMESHEET_TIMEKEEPING +
                    "?time=" +
                    e.target.value +
                    "&date=" +
                    date
                );
              }}
              input={<OutlinedInput className="form-text-field" />}
              variant="outlined"
              className="form-select-field"
              MenuProps={{
                className: "select-menu-list",
              }}
              style={{ width: "fit-content", marginRight: 8 }}
            >
              {SelectTimeExport.map((t: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={t.value}>
                    {t.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Select
              defaultValue={"Excel Technologies"}
              input={<OutlinedInput className="form-text-field" />}
              variant="outlined"
              className="form-select-field"
              MenuProps={{
                className: "select-menu-list",
              }}
              style={{ width: "fit-content", marginRight: 8 }}
              inputProps={{ readOnly: true }}
              displayEmpty
              renderValue={(value) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <LocationIcon
                      color={ACTIVE_COLOR}
                      size={[24, 24]}
                      viewBox={[24, 24]}
                    />
                    {value}
                  </Box>
                );
              }}
            ></Select>
            <button
              className="btn-cancel-style btn--shadow"
              onClick={() => {
                setOpenExport(true);
              }}
            >
              <ExportIcon
                color={ACTIVE_COLOR}
                size={[24, 24]}
                viewBox={[24, 24]}
              />
              &ensp;Export
            </button>
          </div>
        </div>
        {time === "weekly" ? (
          <div className="time-sheet__content__list">
            <div
              style={{
                position: "sticky",
                top: 0,
                zIndex: 2,
                width: "100%",
                background: "#f0f3f8",
              }}
            >
              {checkEmployee?.length === 1 && (
                <div
                  className="header-list-page header-list-page__employ"
                  style={{ justifyContent: "flex-start" }}
                >
                  <div
                    className={`time-sheet__content__list__item__col ${
                      checkEmployee?.length === 1 && "timeList"
                    }`}
                  ></div>
                  <div className="time-sheet__content__list__item__col employ">
                    <img
                      src={checkLinkImage(detail?.avatar as string) || Avatar}
                      alt={DEFAULT_IMG_ALT}
                      onError={renderErrorImage()}
                    />
                    <div className="name">
                      {detail?.fullName || TEXT_HOLDER}
                      <div className="totalTime">
                        Total time:&ensp;
                        {minutes_to_hhmm(
                          detail?.timekeepings.reduce(
                            (sum: number, item: any) => {
                              sum += +item.totalTime;
                              return sum;
                            },
                            0
                          ),
                          false,
                          true
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="header-list-page">
                <div
                  className={`time-sheet__content__list__item__col ${
                    checkEmployee?.length === 1 && "timeList"
                  }`}
                ></div>
                {dates(new Date(date)).map((d, index: number) => {
                  return (
                    <div
                      key={index}
                      className="time-sheet__content__list__item__col"
                      style={{
                        color:
                          new Date(d).getDate() === new Date().getDate()
                            ? "#10BED2"
                            : "",
                      }}
                    >
                      <span className="dateVal">
                        {d
                          .split(" ")
                          .reverse()
                          .join(" ")
                          .slice(8, d?.length - 1)
                          .match(/\d/g)}
                      </span>
                      {d
                        .split(" ")
                        .reverse()
                        .join(" ")
                        .slice(8, d?.length - 1)
                        .replace(/\d/g, "")}
                    </div>
                  );
                })}
              </div>
            </div>
            {checkEmployee.length !== 1
              ? list.map((item, idx: number) => {
                  return (
                    <div key={idx} className="time-sheet__content__list__item">
                      <div className={`time-sheet__content__list__item__col`}>
                        <img
                          src={checkLinkImage(item?.avatar as string) || Avatar}
                          alt={DEFAULT_IMG_ALT}
                          onError={renderErrorImage()}
                        />
                        <div className="name">
                          {item.fullName}
                          <div className="code">{item.code}</div>
                        </div>
                      </div>

                      {dates(new Date(date)).map((d) => {
                        const itemDate = item?.timekeepings?.reduce(
                          (result: any) => {
                            const ob = item?.timekeepings.find(
                              (odl: any) =>
                                moment(d).format("DD") ===
                                odl.date.slice(
                                  odl.date?.length - 2,
                                  odl.date?.length
                                )
                            );
                            if (ob) {
                              result = ob;
                            }
                            return result;
                          },
                          null
                        );

                        return (
                          <div
                            key={idx}
                            className="time-sheet__content__list__item__col"
                            style={{
                              background: idx % 2 !== 0 ? "#F9FBFF" : "white",
                            }}
                          >
                            {itemDate?.timeshifts?.length > 0 && (
                              <div
                                className="time-sheet__content__list__item__col"
                                style={{
                                  background:
                                    idx % 2 !== 0 ? "#F9FBFF" : "white",
                                }}
                              >
                                <Tooltip
                                  title={
                                    <span>
                                      <div className="tooltip-time-sheet-detail">
                                        <div className="item-name">
                                          <img
                                            src={
                                              checkLinkImage(
                                                item?.avatar as string
                                              ) || Avatar
                                            }
                                            alt={DEFAULT_IMG_ALT}
                                            onError={renderErrorImage()}
                                          />
                                          <div>
                                            {item?.fullName || TEXT_HOLDER}
                                            <div className="item-code">
                                              {item?.code || TEXT_HOLDER}
                                            </div>
                                          </div>
                                        </div>
                                        <Divider
                                          style={{ borderColor: "#E0E6F0" }}
                                        />
                                        <div className="tooltip-time-sheet-detail__time">
                                          <TimesheetSubIcon
                                            color={DEFAULT_COLOR}
                                            size={[18, 18]}
                                            viewBox={[20, 20]}
                                          />
                                          &emsp;
                                          {formatEnDE(itemDate?.date)}
                                        </div>
                                        <Divider
                                          style={{
                                            borderColor: "#E0E6F0",
                                            marginBottom: 24,
                                          }}
                                        />
                                        <div className="tooltip-time-sheet-detail__list">
                                          {item?.timekeepings
                                            ?.filter((f: any) =>
                                              f.date
                                                ?.slice(
                                                  f.date?.length - 2,
                                                  f.date?.length
                                                )
                                                .includes(new Date(d).getDate())
                                            )
                                            ?.map((time: any, i: number) => {
                                              return (
                                                <div key={i}>
                                                  {time.timeshifts.map(
                                                    (
                                                      t: any,
                                                      listId: number
                                                    ) => {
                                                      return (
                                                        <Grid
                                                          key={listId}
                                                          container
                                                          className="tooltip-time-sheet-detail__item"
                                                        >
                                                          <Grid item xs={6}>
                                                            <div className="tooltip-time-sheet-detail__item__label">
                                                              Shift title
                                                            </div>
                                                            {t?.title}
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            style={{
                                                              paddingLeft: 30,
                                                            }}
                                                          >
                                                            <div className="tooltip-time-sheet-detail__item__label">
                                                              Total time
                                                            </div>
                                                            {minutes_to_hhmm(
                                                              time?.totalTime,
                                                              false,
                                                              true
                                                            )}
                                                          </Grid>
                                                          <Grid item xs={6}>
                                                            <div className="tooltip-time-sheet-detail__item__label">
                                                              Timeshift
                                                            </div>
                                                            {hhmm_to_hhmmaMINUTE(
                                                              t?.startTime
                                                            ) +
                                                              " - " +
                                                              hhmm_to_hhmmaMINUTE(
                                                                t?.endTime
                                                              )}
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={6}
                                                            style={{
                                                              paddingLeft: 30,
                                                            }}
                                                          >
                                                            <div className="tooltip-time-sheet-detail__item__label">
                                                              Break time
                                                            </div>
                                                            {t?.breaktime
                                                              ? t?.breaktime
                                                                  ?.total +
                                                                " minutes"
                                                              : TEXT_HOLDER}
                                                          </Grid>
                                                        </Grid>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </span>
                                  }
                                  arrow
                                  placement="right-end"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        maxWidth: 464,
                                        bgcolor: "#ffffff",
                                        color: "#272B2F",
                                        boxShadow:
                                          "0px 8px 30px rgba(0, 0, 0, 0.12)",
                                        "& .MuiTooltip-arrow": {
                                          color: "#ffffff",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <div className="tag">
                                    <div>Total time working</div>
                                    <div className="tag__time">
                                      {minutes_to_hhmm(
                                        itemDate?.totalTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              : TimeList().map((item, idx: number) => {
                  return (
                    <div key={idx} className="time-sheet__content__list__item">
                      <div
                        className={`time-sheet__content__list__item__col ${
                          checkEmployee?.length === 1 && "timeList"
                        }`}
                      >
                        {checkEmployee?.length === 1 && item}
                      </div>

                      {dates(new Date(date)).map((d) => {
                        const list =
                          detail &&
                          detail?.timekeepings?.reduce((result: any) => {
                            const ob = detail?.timekeepings.find(
                              (odl: any) =>
                                moment(d).format("DD") ===
                                odl.date.slice(
                                  odl.date?.length - 2,
                                  odl.date?.length
                                )
                            );
                            if (ob) {
                              result = ob;
                            }
                            return result;
                          }, null);

                        const itemStart =
                          detail &&
                          detail?.timekeepings?.reduce(
                            (result: object | null, od: any) => {
                              const orderL = od.timeshifts.find(
                                (odl: any) =>
                                  moment(d).format("DD") ===
                                    od.date.slice(
                                      od.date?.length - 2,
                                      od.date?.length
                                    ) && item === hhmm_to_hhmma(odl?.startTime)
                              );
                              if (orderL) {
                                result = orderL;
                              }
                              return result;
                            },
                            null
                          );

                        const itemCheckin =
                          detail &&
                          detail?.timekeepings?.reduce(
                            (result: object | null, od: any) => {
                              const orderL = od.timeshifts.find(
                                (odl: any) =>
                                  moment(d).format("DD") ===
                                    od.date.slice(
                                      od.date?.length - 2,
                                      od.date?.length
                                    ) &&
                                  item === hhmm_to_hhmma(odl?.period?.checkin)
                              );
                              if (orderL) {
                                result = orderL;
                              }
                              return result;
                            },
                            null
                          );

                        const itemAbsences = detail?.timekeepings?.reduce(
                          (result: object | null, od: any) => {
                            const orderL = od.absences.find(
                              (odl: any) =>
                                moment(d).format("DD") ===
                                  od.date?.slice(
                                    od.date?.length - 2,
                                    od.date?.length
                                  ) && item === hhmm_to_hhmma(odl?.startTime)
                            );
                            if (orderL) {
                              result = orderL;
                            }
                            return result;
                          },
                          null
                        );

                        return (
                          <div
                            key={idx}
                            className="time-sheet__content__list__item__col"
                          >
                            {itemStart && checkEmployee?.length === 1 && (
                              <Tooltip
                                title={
                                  <span>
                                    <div
                                      className="tooltip__text"
                                      onClick={() => {
                                        setDetailDaily({
                                          type: "Working time detail",
                                          val: {
                                            ...detail,
                                            detail: detail,
                                            timeshifts:
                                              detail.timekeepings.filter(
                                                (f: any) =>
                                                  moment(d).format("DD") ===
                                                  f.date?.slice(
                                                    f.date?.length - 2,
                                                    f.date?.length
                                                  )
                                              ),
                                          },
                                          date: d,
                                        });
                                      }}
                                    >
                                      View detail
                                    </div>
                                    <div
                                      className="tooltip__text"
                                      onClick={() => {
                                        setShiftEdit({
                                          data: detail,
                                          date: d,
                                        });
                                      }}
                                    >
                                      Edit shift
                                    </div>
                                    <div
                                      className="tooltip__text tooltip__text__delete"
                                      onClick={() => {
                                        setNotiDelete({
                                          title: "shift",
                                          id: itemStart?._id,
                                        });
                                      }}
                                    >
                                      Delete shift
                                    </div>
                                  </span>
                                }
                                arrow
                                placement="right-end"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "#ffffff",
                                      fontSize: 16,
                                      color: "#272B2F",
                                      padding: 3,
                                      boxShadow:
                                        "0px 6px 19px rgba(146, 151, 163, 0.25)",
                                      "& .MuiTooltip-arrow": {
                                        color: "#ffffff",
                                      },
                                    },
                                  },
                                }}
                              >
                                <div
                                  className="tagWeek tagGreen"
                                  style={{
                                    marginTop:
                                      (
                                        +itemStart?.startTime?.slice(3, 5) *
                                        0.64
                                      ).toString() + "px",
                                    height: "fit-content",
                                    minHeight:
                                      (
                                        (+itemStart?.endTime?.slice(0, 2) -
                                          +itemStart?.startTime?.slice(0, 2)) *
                                        64
                                      ).toString() + "px",
                                  }}
                                >
                                  <div className="tagWeek__item">
                                    <div>
                                      {hhmm_to_hhmmaMINUTE(
                                        itemStart?.startTime
                                      ) +
                                        " - " +
                                        hhmm_to_hhmmaMINUTE(itemStart?.endTime)}
                                    </div>
                                    <div className="tagWeek__title">
                                      {itemStart?.title || TEXT_HOLDER}
                                    </div>
                                    <div className="tagWeek__time">
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check in
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemStart.period?.checkin
                                        )}
                                      </div>
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check out
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemStart.period?.checkout
                                        )}
                                      </div>
                                    </div>
                                    <div className="tagWeek__time__item">
                                      <div className="tagWeek__time__item__label">
                                        Total time
                                      </div>
                                      {minutes_to_hhmm(
                                        list?.totalTime,
                                        false,
                                        true
                                      )}
                                    </div>
                                  </div>
                                  {itemStart?.breaktime && (
                                    <img src={Line} alt={DEFAULT_IMG_ALT} />
                                  )}
                                </div>
                              </Tooltip>
                            )}
                            {itemCheckin &&
                              !itemCheckin?.startTime &&
                              checkEmployee?.length === 1 && (
                                <div
                                  className="tagWeek tagRed"
                                  style={{
                                    marginTop:
                                      (
                                        +itemCheckin?.period?.checkin?.slice(
                                          3,
                                          5
                                        ) * 0.64
                                      ).toString() + "px",
                                    height: "fit-content",
                                    minHeight:
                                      (
                                        (+itemCheckin?.period?.checkout?.slice(
                                          0,
                                          2
                                        ) -
                                          +itemCheckin?.period?.checkin?.slice(
                                            0,
                                            2
                                          )) *
                                        64
                                      ).toString() + "px",
                                  }}
                                >
                                  <div className="tagWeek__item">
                                    <div>
                                      {hhmm_to_hhmmaMINUTE(
                                        itemCheckin?.startTime
                                      ) +
                                        " - " +
                                        hhmm_to_hhmmaMINUTE(
                                          itemCheckin?.endTime
                                        )}
                                    </div>
                                    <div className="tagWeek__time">
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check in
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemCheckin.period?.checkin
                                        )}
                                      </div>
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check out
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemCheckin.period?.checkout
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {itemAbsences && checkEmployee?.length === 1 && (
                              <Tooltip
                                title={
                                  <span>
                                    <div
                                      className="tooltip__text"
                                      onClick={() => {
                                        setDetailDaily({
                                          type: "Absence Detail",
                                          val: {
                                            ...detail,
                                            detail: detail,
                                            itemAbsences: itemAbsences,
                                            absences:
                                              detail.timekeepings.filter(
                                                (f: any) =>
                                                  moment(d).format("DD") ===
                                                  f.date?.slice(
                                                    f.date?.length - 2,
                                                    f.date?.length
                                                  )
                                              ),
                                          },
                                          date: d,
                                        });
                                      }}
                                    >
                                      View detail
                                    </div>
                                    <div
                                      className="tooltip__text"
                                      onClick={() => {
                                        setAbsenceEdit({
                                          data: detail,
                                          date: d,
                                        });
                                      }}
                                    >
                                      Edit absence
                                    </div>
                                    <div
                                      className="tooltip__text tooltip__text__delete"
                                      onClick={() => {
                                        setNotiDelete({
                                          title: "absence",
                                          id: itemAbsences?._id,
                                        });
                                      }}
                                    >
                                      Delete absence
                                    </div>
                                  </span>
                                }
                                arrow
                                placement="right-end"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "#ffffff",
                                      fontSize: 16,
                                      color: "#272B2F",
                                      padding: 3,
                                      boxShadow:
                                        "0px 6px 19px rgba(146, 151, 163, 0.25)",
                                      "& .MuiTooltip-arrow": {
                                        color: "#ffffff",
                                      },
                                    },
                                  },
                                }}
                              >
                                <div
                                  className={`tagWeek ${
                                    itemAbsences?.type === "paid"
                                      ? "tagPaid"
                                      : "tagUnpaid"
                                  }`}
                                  style={{
                                    marginTop:
                                      (
                                        +itemAbsences?.startTime?.slice(3, 5) *
                                        0.64
                                      ).toString() + "px",
                                    height: "fit-content",
                                    minHeight:
                                      (
                                        (+itemAbsences?.endTime?.slice(0, 2) -
                                          +itemAbsences?.startTime?.slice(
                                            0,
                                            2
                                          )) *
                                        64
                                      ).toString() + "px",
                                  }}
                                >
                                  <div className="tagWeek__item">
                                    <div>
                                      {hhmm_to_hhmmaMINUTE(
                                        itemAbsences?.startTime
                                      ) +
                                        " - " +
                                        hhmm_to_hhmmaMINUTE(
                                          itemAbsences?.endTime
                                        )}
                                    </div>
                                    <div className="tagWeek__title">
                                      {itemAbsences?.note || TEXT_HOLDER}
                                    </div>
                                    {/* <div className="tagWeek__time">
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check in
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemAbsences.period?.checkin
                                        )}
                                      </div>
                                      <div className="tagWeek__time__item">
                                        <div className="tagWeek__time__item__label">
                                          Check out
                                        </div>
                                        {hhmm_to_hhmmaMINUTE(
                                          itemAbsences.period?.checkout
                                        )}
                                      </div>
                                    </div>
                                    <div className="tagWeek__time__item">
                                      <div className="tagWeek__time__item__label">
                                        Total time
                                      </div>
                                      {minutes_to_hhmm(
                                        list?.totalTime,
                                        false,
                                        true
                                      )}
                                    </div> */}
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
          </div>
        ) : (
          <DailyList listEmployee={checkEmployee?.length > 0 ? list : []} />
        )}
      </div>

      <FloatingBtn
        visible={visibleFloatingBtn}
        onClickPrimary={() => {
          setVisibleFloatingBtn(!visibleFloatingBtn);
        }}
        btns={[
          {
            color: "dardBlue",
            title: "Add Shift",
            icon: (
              <CalendarMonthOutlinedIcon
                fontSize="large"
                style={{ color: "#FFFFFF" }}
              />
            ),
            onClick: () => {
              setShiftModal(true);
              setVisibleFloatingBtn(!visibleFloatingBtn);
            },
          },
          {
            color: "red",
            title: "Add Absence",
            icon: (
              <AbsenceIcon
                color={DEFAULT_COLOR}
                size={[18, 18]}
                viewBox={[23, 23]}
              />
            ),
            onClick: () => {
              setAbsenceModal(true);
              setVisibleFloatingBtn(!visibleFloatingBtn);
            },
          },
        ]}
      />

      {openExport && (
        <ExportFile
          onClose={() => {
            setOpenExport(false);
          }}
          time={time}
        />
      )}

      {(shiftModal || shiftEdit.data) && (
        <ShiftModal
          onClose={() => {
            setShiftModal(false);
            setShiftEdit({ data: null, date: "" });
          }}
          data={shiftEdit ? shiftEdit.data : undefined}
          dateByWeek={moment(shiftEdit.date).format("DD")}
        />
      )}

      {(absenceModal || absenceEdit.data) && (
        <AbsenceModal
          onClose={() => {
            setAbsenceModal(false);
            setAbsenceEdit({ data: null, date: "" });
          }}
          data={absenceEdit ? absenceEdit.data : undefined}
          dateByWeek={moment(absenceEdit.date).format("DD")}
        />
      )}

      <NotificationModal
        visible={notiDelete.id !== ""}
        title={notiDelete.title === "shift" ? "Delete Shift" : "Delete Absence"}
        subTitle={
          "Are you sure you want to delete this " + notiDelete.title + " ?"
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setNotiDelete({
                  title: notiDelete.title,
                  id: "",
                });
              }}
            >
              Cancel
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                notiDelete.title === "shift"
                  ? delelteTimekeeping(notiDelete.id)
                  : delelteAbsence(notiDelete.id);
              }}
            >
              Delete
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete({
            title: notiDelete.title,
            id: "",
          });
        }}
      />

      <Dialog
        maxWidth="sm"
        open={detailDaily.type !== ""}
        style={{ zIndex: 1501 }}
      >
        <div className="dialog-daily-detail">
          <SimpleModalHeader
            title={detailDaily.type}
            onClose={() => {
              setDetailDaily({ type: "", val: null, date: "" });
            }}
          />
          <div className="dialog-daily-detail__content">
            <div className="item-name">
              <img
                src={
                  checkLinkImage(detailDaily.val?.avatar as string) || Avatar
                }
                alt={DEFAULT_IMG_ALT}
                onError={renderErrorImage()}
              />
              <div>
                {detailDaily.val?.fullName || TEXT_HOLDER}
                <div className="item-code">
                  {detailDaily.val?.code || TEXT_HOLDER}
                </div>
              </div>
            </div>
            <Divider style={{ borderColor: "#E0E6F0" }} />
            <div className="dialog-daily-detail__content__time">
              <TimesheetSubIcon
                color={DEFAULT_COLOR}
                size={[18, 18]}
                viewBox={[20, 20]}
              />
              &emsp;
              {formatEnDE(moment(detailDaily?.date).format("YYYY-MM-DD"))}
            </div>
            <Divider style={{ borderColor: "#E0E6F0", marginBottom: 39 }} />
            {detailDaily.type === "Working time detail" ? (
              <Grid
                container
                rowSpacing={3}
                className="dialog-daily-detail__table"
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Shift Title
                  </div>
                  {detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]?.title}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15, paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Total time
                  </div>
                  {minutes_to_hhmm(
                    detailDaily.val?.timekeepings?.[0]?.totalTime,
                    false,
                    true
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Timeshift
                  </div>
                  {hhmm_to_hhmmaMINUTE(
                    detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]
                      ?.startTime
                  ) +
                    " - " +
                    hhmm_to_hhmmaMINUTE(
                      detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]
                        ?.endTime
                    )}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">
                    Breaktime
                  </div>
                  {detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]
                    ?.breaktime
                    ? detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]
                        ?.breaktime?.total + " minutes"
                    : TEXT_HOLDER}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ borderColor: "#E0E6F0" }} />
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Check in
                  </div>
                  <img src={CheckIn} alt={DEFAULT_IMG_ALT} />
                  &emsp;
                  {hhmm_to_hhmmaMINUTE(
                    detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]?.period
                      ?.checkin
                  )}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">
                    Check out
                  </div>
                  <img src={CheckOut} alt={DEFAULT_IMG_ALT} />
                  &emsp;
                  {hhmm_to_hhmmaMINUTE(
                    detailDaily.val?.timekeepings?.[0]?.timeshifts?.[0]?.period
                      ?.checkout
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                rowSpacing={3}
                className="dialog-daily-detail__table"
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Absence type
                  </div>
                  {detailDaily.val?.itemAbsences?.type}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15, paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Total time
                  </div>
                  {minutes_to_hhmm(
                    detailDaily.val?.absences?.[0]?.totalTime,
                    false,
                    true
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Timeshift
                  </div>
                  {hhmm_to_hhmmaMINUTE(
                    detailDaily.val?.itemAbsences?.startTime
                  ) +
                    " - " +
                    hhmm_to_hhmmaMINUTE(detailDaily.val?.itemAbsences?.endTime)}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">Note</div>
                  {detailDaily.val?.itemAbsences?.note || TEXT_HOLDER}
                </Grid>
              </Grid>
            )}

            <div className="dialog-daily-detail__group">
              <div
                className="dialog-daily-detail__group__btn"
                onClick={() => {
                  if (detailDaily.type === "Working time detail") {
                    setShiftEdit({
                      data: detailDaily.val.detail,
                      date: detailDaily.date,
                    });
                  } else {
                    setAbsenceEdit({
                      data: detailDaily.val.detail,
                      date: detailDaily.date,
                    });
                  }
                  setDetailDaily({
                    type: "",
                    val: detailDaily.val,
                    date: detailDaily.date,
                  });
                }}
              >
                <EditIcon
                  color={"#6C778D"}
                  size={[18, 18]}
                  viewBox={[18, 18]}
                />
                &emsp;
                {detailDaily.type === "Working time detail"
                  ? "Edit timeshift"
                  : "Edit absence"}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Timesheet;
