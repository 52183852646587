/*eslint-disable */
import {
  ErrorRounded as ErrorRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Avatar from "../../../../assets/images/avatar-df.png";
import CheckIn from "../../../../assets/images/icons/icon_checkin.png";
import CheckOut from "../../../../assets/images/icons/icon_checkout.png";
import Line from "../../../../assets/images/icons/line.png";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { TimesheetService } from "../../../../services";
import {
  checkLinkImage,
  formatEnDE,
  hhmm_to_hhmma,
  hhmm_to_hhmmaMINUTE,
  minutes_to_hhmm,
  renderErrorImage,
  TimeList,
} from "../../../../utils";
import { EditIcon } from "../../../components/Icons/EditIcon";
import { TimesheetSubIcon } from "../../../components/Icons/TimesheetSubIcon";
import NotificationModal from "../../../components/NotificationModal";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../../constants";
import { MESSAGES } from "../../../constants/mock";
import { DEFAULT_COLOR } from "../../../core/Layout/routes";
import AbsenceModal from "../AbsenceModal";
import ShiftModal from "../ShiftModal";
import "./index.scss";

type P_Props = {
  listEmployee: any;
};

const DailyList: React.FC<P_Props> = (props) => {
  const [pushNoti] = useNoti();
  const [notiDelete, setNotiDelete] = useState<{ title: string; id: string }>({
    title: "",
    id: "",
  });
  const [detail, setDetail] = useState<{ type: string; val: any }>({
    type: "",
    val: false,
  });
  const [shiftModal, setShiftModal] = useState<any>(null);
  const [absenceModal, setAbsenceModal] = useState<any>(null);

  const delelteTimekeeping = async (id: string) => {
    try {
      const res = await TimesheetService.delelteTimekeeping(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({
          title: notiDelete.title,
          id: "",
        });
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const delelteAbsence = async (id: string) => {
    try {
      const res = await TimesheetService.delelteAbsence(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({
          title: notiDelete.title,
          id: "",
        });
        // eslint-disable-next-line
        window.location.href = window.location.href;
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  return (
    <div className="daily-list">
      <div className="daily-list__header">
        {props.listEmployee?.map((e: any, idx: number) => {
          return (
            <div key={idx} className="time-sheet__content__list__item__col">
              <img
                src={checkLinkImage(e?.avatar as string) || Avatar}
                alt={DEFAULT_IMG_ALT}
                onError={renderErrorImage()}
              />
              <div className="name">
                {e.fullName || TEXT_HOLDER}
                <div className="totalTime">
                  Total time:&ensp;
                  {minutes_to_hhmm(
                    e?.timekeepings?.[0]?.totalTime
                      ? e?.timekeepings?.[0]?.totalTime
                      : 0,
                    false,
                    true
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {TimeList().map((item, idx: number) => {
        return (
          <div key={idx} className="daily-list__item">
            <div className="daily-list__item__col">{item}</div>
            {props.listEmployee?.length > 0 ? (
              props.listEmployee?.map((e: any) => {
                const itemStart = e?.timekeepings?.reduce(
                  (result: object | null, od: any) => {
                    const orderL = od.timeshifts.find(
                      (odl: any) => item === hhmm_to_hhmma(odl?.startTime)
                    );
                    if (orderL) {
                      result = orderL;
                    }
                    return result;
                  },
                  null
                );

                const listAbsences = e?.timekeepings?.reduce(
                  (result: object | null, od: any) => {
                    const orderL = od.absences.find(
                      (odl: any) => item === hhmm_to_hhmma(odl?.startTime)
                    );
                    if (orderL) {
                      result = orderL;
                    }
                    return result;
                  },
                  null
                );

                const listTimeshiftsNoStart = e?.timekeepings?.reduce(
                  (result: object | null, od: any) => {
                    const orderL = od.timeshifts.find(
                      (odl: any) => item === hhmm_to_hhmma(odl?.period?.checkin)
                    );
                    if (orderL) {
                      result = orderL;
                    }
                    return result;
                  },
                  null
                );

                return (
                  <div key={idx} className="daily-list__item__col">
                    {itemStart && (
                      <Tooltip
                        title={
                          <span>
                            <div
                              className="tooltip__text"
                              onClick={() => {
                                setDetail({
                                  type: "Working time detail",
                                  val: e,
                                });
                              }}
                            >
                              View detail
                            </div>
                            <div
                              className="tooltip__text"
                              onClick={() => {
                                setShiftModal(e);
                              }}
                            >
                              Edit shift
                            </div>
                            <div
                              className="tooltip__text tooltip__text__delete"
                              onClick={() => {
                                setNotiDelete({
                                  title: "shift",
                                  id: itemStart?._id,
                                });
                              }}
                            >
                              Delete shift
                            </div>
                          </span>
                        }
                        arrow
                        placement="right-end"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#ffffff",
                              fontSize: 16,
                              color: "#272B2F",
                              padding: 3,
                              boxShadow:
                                "0px 6px 19px rgba(146, 151, 163, 0.25)",
                              "& .MuiTooltip-arrow": {
                                color: "#ffffff",
                              },
                            },
                          },
                        }}
                      >
                        <div
                          className="tag tagGreen"
                          style={{
                            marginTop:
                              (
                                +itemStart?.startTime?.slice(3, 5) * 0.64
                              ).toString() + "px",
                            height: "fit-content",
                            minHeight:
                              (
                                (+(itemStart?.endTime).slice(0, 2) -
                                  +(itemStart?.startTime).slice(0, 2)) *
                                64
                              ).toString() + "px",
                          }}
                        >
                          <div className="tag__item">
                            {hhmm_to_hhmmaMINUTE(itemStart?.startTime) +
                              " - " +
                              hhmm_to_hhmmaMINUTE(itemStart?.endTime)}
                            <div className="tag__title">
                              {itemStart?.title || TEXT_HOLDER}
                            </div>
                            <div className="tag__time">
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check in
                                </div>
                                {hhmm_to_hhmmaMINUTE(itemStart.period?.checkin)}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check out
                                </div>
                                {hhmm_to_hhmmaMINUTE(
                                  itemStart.period?.checkout
                                )}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Breaktime
                                </div>
                                {itemStart?.breaktime
                                  ? itemStart?.breaktime?.total + " mins"
                                  : TEXT_HOLDER}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Total time
                                </div>
                                {minutes_to_hhmm(
                                  e?.timekeepings?.[0]?.totalTime,
                                  false,
                                  true
                                )}
                              </div>
                            </div>
                          </div>
                          {itemStart?.breaktime && (
                            <img src={Line} alt={DEFAULT_IMG_ALT} />
                          )}
                        </div>
                      </Tooltip>
                    )}

                    {listAbsences && (
                      <Tooltip
                        title={
                          <span>
                            <div
                              className="tooltip__text"
                              onClick={() => {
                                setDetail({
                                  type: "Absence Detail",
                                  val: { ...e, listAbsences: listAbsences },
                                });
                              }}
                            >
                              View detail
                            </div>
                            <div
                              className="tooltip__text"
                              onClick={() => {
                                setAbsenceModal(e);
                              }}
                            >
                              Edit absence
                            </div>
                            <div
                              className="tooltip__text tooltip__text__delete"
                              onClick={() => {
                                setNotiDelete({
                                  title: "absence",
                                  id: listAbsences?._id,
                                });
                              }}
                            >
                              Delete absence
                            </div>
                          </span>
                        }
                        arrow
                        placement="right-end"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#ffffff",
                              fontSize: 16,
                              color: "#272B2F",
                              padding: 3,
                              boxShadow:
                                "0px 6px 19px rgba(146, 151, 163, 0.25)",
                              "& .MuiTooltip-arrow": {
                                color: "#ffffff",
                              },
                            },
                          },
                        }}
                      >
                        <div
                          className={`tag ${
                            listAbsences?.type === "unpaid"
                              ? "tagUnpaid"
                              : "tagPaid"
                          }`}
                          style={{
                            marginTop:
                              (
                                +listAbsences?.startTime?.slice(3, 5) * 0.64
                              ).toString() + "px",
                            height: "fit-content",
                            minHeight:
                              (
                                (+(listAbsences?.endTime).slice(0, 2) -
                                  +(listAbsences?.startTime).slice(0, 2)) *
                                64
                              ).toString() + "px",
                          }}
                        >
                          <div className="tag__item">
                            <div>
                              {hhmm_to_hhmmaMINUTE(listAbsences?.startTime) +
                                " - " +
                                hhmm_to_hhmmaMINUTE(listAbsences?.endTime)}
                            </div>
                            <div className="tag__title">
                              {listAbsences?.note || TEXT_HOLDER}
                            </div>
                            {/* <div className="tag__time">
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check in
                                </div>
                                {hhmm_to_hhmmaMINUTE(
                                  listAbsences.period?.checkin
                                )}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check out
                                </div>
                                {hhmm_to_hhmmaMINUTE(
                                  listAbsences.period?.checkin
                                )}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Breaktime
                                </div>
                                {listAbsences?.breaktime
                                  ? listAbsences?.breaktime?.total + " mins"
                                  : TEXT_HOLDER}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Total time
                                </div>
                                {minutes_to_hhmm(
                                  e?.timekeepings?.[0]?.totalTime,
                                  false,
                                  true
                                )}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </Tooltip>
                    )}

                    {!listTimeshiftsNoStart?.startTime &&
                      item ===
                        hhmm_to_hhmma(
                          listTimeshiftsNoStart?.period?.checkin
                        ) && (
                        <div className="tag tagRed">
                          <div className="tag__item">
                            <div>
                              {hhmm_to_hhmmaMINUTE(
                                listTimeshiftsNoStart?.period?.checkin
                              ) +
                                " - " +
                                hhmm_to_hhmmaMINUTE(
                                  listTimeshiftsNoStart?.period?.checkout
                                )}
                            </div>
                            <div className="tag__time">
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check in
                                </div>
                                {hhmm_to_hhmmaMINUTE(
                                  listTimeshiftsNoStart?.period?.checkin
                                )}
                              </div>
                              <div className="tag__time__item">
                                <div className="tag__time__item__label">
                                  Check out
                                </div>
                                {hhmm_to_hhmmaMINUTE(
                                  listTimeshiftsNoStart?.period?.checkout
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="tagRed__icon">
                            <Tooltip
                              title="Shift not created yet"
                              arrow
                              placement="right-end"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#ffffff",
                                    fontSize: 16,
                                    color: "#272B2F",
                                    padding: 3,
                                    boxShadow:
                                      "0px 6px 19px rgba(146, 151, 163, 0.25)",
                                    "& .MuiTooltip-arrow": {
                                      color: "#ffffff",
                                    },
                                  },
                                },
                              }}
                            >
                              <ErrorRoundedIcon
                                style={{ color: "#FF6262", fontSize: 24 }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })
            ) : (
              <div className="time-sheet__content__list__item__col"></div>
            )}
          </div>
        );
      })}

      <Dialog maxWidth="sm" open={detail.val} style={{ zIndex: 1501 }}>
        <div className="dialog-daily-detail">
          <SimpleModalHeader
            title={detail.type}
            onClose={() => {
              setDetail({ type: detail.type, val: null });
            }}
          />
          <div className="dialog-daily-detail__content">
            <div className="item-name">
              <img
                src={checkLinkImage(detail.val?.avatar as string) || Avatar}
                alt={DEFAULT_IMG_ALT}
                onError={renderErrorImage()}
              />
              <div>
                {detail.val?.fullName || TEXT_HOLDER}
                <div className="item-code">
                  {detail.val?.code || TEXT_HOLDER}
                </div>
              </div>
            </div>
            <Divider style={{ borderColor: "#E0E6F0" }} />
            <div className="dialog-daily-detail__content__time">
              <TimesheetSubIcon
                color={DEFAULT_COLOR}
                size={[18, 18]}
                viewBox={[20, 20]}
              />
              &emsp;
              {formatEnDE(
                moment(detail.val?.timekeepings?.[0]?.date).format("YYYY-MM-DD")
              )}
            </div>
            <Divider style={{ borderColor: "#E0E6F0", marginBottom: 39 }} />
            {detail.type === "Working time detail" ? (
              <Grid
                container
                rowSpacing={3}
                className="dialog-daily-detail__table"
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Shift Title
                  </div>
                  {detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.title}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15, paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Total time
                  </div>
                  {minutes_to_hhmm(
                    detail.val?.timekeepings?.[0]?.totalTime,
                    false,
                    true
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Timeshift
                  </div>
                  {hhmm_to_hhmmaMINUTE(
                    detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.startTime
                  ) +
                    " - " +
                    hhmm_to_hhmmaMINUTE(
                      detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.endTime
                    )}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">
                    Breaktime
                  </div>
                  {detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.breaktime
                    ? detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.breaktime
                        ?.total + " minutes"
                    : TEXT_HOLDER}
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ borderColor: "#E0E6F0" }} />
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Check in
                  </div>
                  <img src={CheckIn} alt={DEFAULT_IMG_ALT} />
                  &emsp;
                  {hhmm_to_hhmmaMINUTE(
                    detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.period
                      ?.checkin
                  )}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">
                    Check out
                  </div>
                  <img src={CheckOut} alt={DEFAULT_IMG_ALT} />
                  &emsp;
                  {hhmm_to_hhmmaMINUTE(
                    detail.val?.timekeepings?.[0]?.timeshifts?.[0]?.period
                      ?.checkout
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                rowSpacing={3}
                className="dialog-daily-detail__table"
              >
                <Grid item xs={6} style={{ paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Absence type
                  </div>
                  {detail.val?.listAbsences?.type}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15, paddingTop: 0 }}>
                  <div className="dialog-daily-detail__table__label">
                    Total time
                  </div>
                  {minutes_to_hhmm(
                    detail.val?.timekeepings?.[0]?.totalTime,
                    false,
                    true
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className="dialog-daily-detail__table__label">
                    Timeshift
                  </div>
                  {hhmm_to_hhmmaMINUTE(detail.val?.listAbsences?.startTime) +
                    " - " +
                    hhmm_to_hhmmaMINUTE(detail.val?.listAbsences?.endTime)}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 15 }}>
                  <div className="dialog-daily-detail__table__label">Note</div>
                  {detail.val?.listAbsences?.note || TEXT_HOLDER}
                </Grid>
              </Grid>
            )}
            {detail.type === "Working time detail" && (
              <div className="dialog-daily-detail__group">
                <div
                  className="dialog-daily-detail__group__btn"
                  onClick={() => {
                    setShiftModal(detail.val);
                    setDetail({ type: detail.type, val: null });
                  }}
                >
                  <EditIcon
                    color={"#6C778D"}
                    size={[18, 18]}
                    viewBox={[18, 18]}
                  />
                  &emsp;Edit timeshift
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>

      <NotificationModal
        visible={notiDelete.id !== ""}
        title={notiDelete.title === "shift" ? "Delete Shift" : "Delete Absence"}
        subTitle={
          "Are you sure you want to delete this " + notiDelete.title + " ?"
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setNotiDelete({
                  title: notiDelete.title,
                  id: "",
                });
              }}
            >
              Cancel
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                notiDelete.title === "shift"
                  ? delelteTimekeeping(notiDelete.id)
                  : delelteAbsence(notiDelete.id);
              }}
            >
              Delete
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete({
            title: notiDelete.title,
            id: "",
          });
        }}
      />

      {shiftModal && (
        <ShiftModal
          onClose={() => {
            setShiftModal(false);
          }}
          data={shiftModal}
        />
      )}

      {absenceModal && (
        <AbsenceModal
          onClose={() => {
            setAbsenceModal(false);
          }}
          data={absenceModal}
        />
      )}
    </div>
  );
};

export default DailyList;
