import { CalendarMonthOutlined as CalendarMonthOutlinedIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import { Moment } from "moment";
import "react-dates/lib/css/_datepicker.css";
import "./index.scss";
import moment from "moment";

type P_Props = {
  day: any;
  onChangeDate: (value: any) => void;
  iconStart?: boolean;
  disabled?: boolean;
};

const SinglePicker: React.FC<P_Props> = ({
  day,
  onChangeDate,
  iconStart,
  disabled,
}) => {
  const [value, setValue] = useState<Moment>(day);

  useEffect(() => {
    if (day) {
      setValue(moment(day));
    } else {
      setValue(moment());
    }
  }, [day]);

  const [focusedInput, setFocusedInput] = useState(false);

  const handleDatesChange = (date: any) => {
    setValue(date);
    if (date) {
      onChangeDate(date.format("YYYY-MM-DD"));
    }
  };

  return (
    <div
      style={{
        fontFamily: "Nunito Sans",
        fontSize: 16,
        color: "#272B2F",
      }}
      className='single-range'
    >
      <SingleDatePicker
        id='date_input'
        date={value}
        onDateChange={handleDatesChange}
        focused={focusedInput}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
        numberOfMonths={1}
        displayFormat='MM/DD/YYYY'
        customInputIcon={
          <CalendarMonthOutlinedIcon
            fontSize='large'
            style={{ color: iconStart ? "#00529C" : "#BDC6D7" }}
          />
        }
        inputIconPosition={iconStart ? "before" : "after"}
        isOutsideRange={() => false}
        placeholder='mm/dd/yyyy'
        hideKeyboardShortcutsPanel
        firstDayOfWeek={1}
        disabled={disabled}
      />
    </div>
  );
};

export default React.memo(SinglePicker);
