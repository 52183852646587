const NUMBER_ONLY = /^[0-9\s]*$/;
// eslint-disable-next-line
const PHONE_NUMBER = /^\d+$/;
const PHONE_NUMBER_VER2 = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
const PHONE_NUMBER_VN = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
// eslint-disable-next-line
const EMAIL =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAILVER2 = /^[a-z0-9][-a-z0-9._]+@([-a-z0-9]+\.)+[a-z]{2,5}$/;
const UPPERCASE = /[A-Z]/;
const DIGITS = /[0-9]/;
const SPECIAL_CHARACTER = /[!@#$%^&*(),.?":{}|<>]/;
const CHARACTER_LENGTH = /.{8,}/;
const PASSWORD = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
const NUMBER_CHARACTER = /^[0-9\s*,-]*$/;
const DATE_FORMAT =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const NUMBER_AND_DOT = /[0-9]+(\.[0-9]+)?/g;
// eslint-disable-next-line
const CHARACTER_ONLY = /[0-9\.]/g;

export const REGEX = {
  NUMBER_ONLY,
  PHONE_NUMBER,
  PHONE_NUMBER_VER2,
  PHONE_NUMBER_VN,
  EMAIL,
  UPPERCASE,
  DIGITS,
  SPECIAL_CHARACTER,
  CHARACTER_LENGTH,
  PASSWORD,
  NUMBER_CHARACTER,
  DATE_FORMAT,
  NUMBER_AND_DOT,
  CHARACTER_ONLY,
  EMAILVER2,
};
