import {
  Dialog,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { EmailService } from "../../../../services";
import { FormUIUtil, FormUtil, preventCharacter } from "../../../../utils";
import ModalFooter from "../../../components/ModalFooter";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import SingleDatePicker from "../../../components/SinglePicker";
import DefaultSwitch from "../../../components/Switch";
import {
  AddMailEnd,
  MESSAGES,
  RepeatMail,
  TitleMail,
} from "../../../constants/mock";
import "./index.scss";
import { REGEX } from "../../../constants";

type P_Props = {
  onClose: () => void;
  data?: any;
};

const MailModal: React.FC<P_Props> = ({ onClose, data }) => {
  const [pushNoti] = useNoti();
  const [status, setStatus] = useState<boolean>(true);
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);

  const createMail = async (values: any) => {
    let val = {
      email: values.email,
      title: values.title,
      receiverName: values.receiverName,
      autoSendMail: {
        number: status ? +values.number : 1,
        unit: status ? values.unit : "week",
        status: status,
        start: start || moment(new Date()).format("YYYY-MM-DD"),
        end:
          values.addEnd === "none"
            ? null
            : end || moment(new Date()).format("YYYY-MM-DD"),
      },
    };

    try {
      const res = await EmailService.createEmail(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        onClose();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  const emailDetail = data && {
    email: data.email,
    title: data.title,
    receiverName: data.receiverName,
    number: data?.autoSendMail.number.toString(),
    unit: data?.autoSendMail.unit,
    addEnd: !data?.autoSendMail.end ? "none" : "after",
  };

  useEffect(() => {
    if (data) {
      setStatus(data?.autoSendMail.status);
      setStart(moment(data?.autoSendMail.start));
      data?.autoSendMail.end && setEnd(moment(data?.autoSendMail.end));
    }
  }, [data]);

  const updateMail = async (values: any) => {
    let val = {
      _id: data?._id,
      title: values.title,
      receiverName: values.receiverName,
      autoSendMail: {
        number: status ? +values.number : 1,
        unit: status ? values.unit : "week",
        status: status,
        start:
          start instanceof moment ? moment(start).format("YYYY-MM-DD") : start,
        end:
          values.addEnd === "none" || !status
            ? null
            : end || moment(new Date()).format("YYYY-MM-DD"),
      },
    };

    try {
      const res = await EmailService.updateEmail(val);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        onClose();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
    }
  };

  return (
    <Dialog open maxWidth='md' className='mail-modal'>
      <SimpleModalHeader
        title={data ? "Edit Email" : "Add Email"}
        onClose={onClose}
      />
      <div className='content'>
        <Form
          onSubmit={async (values) => {
            data ? updateMail(values) : createMail(values);
          }}
          initialValues={
            data
              ? emailDetail
              : {
                  title: TitleMail?.[0]?.label,
                  unit: RepeatMail?.[0]?.value,
                  addEnd: AddMailEnd?.[0]?.value,
                }
          }
          validate={(values: any) => {
            const errors: Record<string, unknown> = {};
            if (status && !values.number) {
              errors.number = "Please enter number";
            }
            return errors;
          }}
        >
          {({ handleSubmit, values }) => {
            const unit = values.unit;
            return (
              <form
                onSubmit={handleSubmit}
                id='mail-form'
                className='mail-modal__form'
              >
                <Field
                  name='email'
                  validate={FormUtil.composeValidators([
                    FormUtil.Rule.required("Please enter Email"),
                    FormUtil.Rule.pattern(REGEX.EMAILVER2, {
                      errorMessage: "Invalid Email",
                    }),
                  ])}
                >
                  {({ input, meta, ...rest }) => {
                    return FormUIUtil.renderFormItem(
                      "Email",
                      <TextField
                        {...input}
                        {...rest}
                        fullWidth
                        placeholder={"Place holder"}
                        color='success'
                        className='form-text-field'
                        inputProps={{ className: "input" }}
                        variant='outlined'
                        onChange={(e) => input.onChange(e.target.value)}
                        helperText={meta.touched ? meta.error : ""}
                        error={meta.error && meta.touched}
                      />,
                      true
                    );
                  }}
                </Field>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field name='title'>
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Title",
                          <Select
                            {...input}
                            {...rest}
                            value={input.value}
                            displayEmpty
                            onChange={(e) => {
                              input.onChange(e.target.value);
                            }}
                            input={
                              <OutlinedInput className='form-text-field' />
                            }
                            variant='outlined'
                            className='form-select-field'
                            MenuProps={{
                              className:
                                "select-menu-list mail-modal__select-menu-list",
                            }}
                          >
                            {TitleMail.map((title, idx: number) => {
                              return (
                                <MenuItem key={idx} value={title.label}>
                                  {title.label}
                                </MenuItem>
                              );
                            })}
                          </Select>,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      name='receiverName'
                      validate={FormUtil.Rule.required(
                        "Please enter Receiver name"
                      )}
                    >
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Receiver name",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Place holder"}
                            color='success'
                            className='form-text-field'
                            inputProps={{ className: "input" }}
                            variant='outlined'
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                          />,
                          true
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
                <div
                  className='setting-send'
                  style={{ opacity: !status ? "0.5" : "" }}
                >
                  <Grid
                    container
                    spacing={3}
                    display='flex'
                    alignItems='center'
                  >
                    <Grid item xs={3} className='setting-send__title'>
                      Auto send email
                    </Grid>
                    <Grid item xs={9}>
                      <DefaultSwitch
                        checked={status}
                        onChange={() => {
                          setStatus(!status);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      Starts
                    </Grid>
                    <Grid item xs={9}>
                      <SingleDatePicker
                        day={start}
                        onChangeDate={(value) => {
                          setStart(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      Sends every
                    </Grid>
                    <Grid item xs={9} container display='flex'>
                      <Field name='number'>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              variant='outlined'
                              className='form-text-field'
                              inputProps={{ className: "input" }}
                              autoComplete='off'
                              value={input.value}
                              placeholder='1'
                              onChange={(e) => {
                                let value = parseInt(e.target.value, 10);
                                const max = unit === "week" ? 4 : 12;
                                if (value > max) value = max;
                                if (value < 1) value = 1;
                                input.onChange(value);
                                if (values.addEnd !== "none" && value) {
                                  if (start) {
                                    setEnd(
                                      moment(start)
                                        .add(
                                          1 * +value,
                                          unit === "week" ? "w" : "M"
                                        )
                                        .format("YYYY-MM-DD")
                                    );
                                  } else {
                                    setEnd(
                                      moment()
                                        .add(
                                          1 * +value,
                                          unit === "week" ? "w" : "M"
                                        )
                                        .format("YYYY-MM-DD")
                                    );
                                  }
                                }
                              }}
                              onBlur={(e) => {
                                if (
                                  !e.target.value ||
                                  e.target.value[0].includes("0")
                                ) {
                                  input.onChange(1);
                                }
                              }}
                              InputProps={{
                                readOnly: !status ? true : false,
                                onKeyDown: (e) => {
                                  preventCharacter(e);
                                },
                              }}
                              type='number'
                              style={{ width: 88 }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      <Field name='unit'>
                        {({ input, meta, ...rest }) => {
                          return (
                            <Select
                              {...input}
                              {...rest}
                              value={input.value}
                              displayEmpty
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                // setValue("number", 1);
                                if (values.addEnd !== "none" && values.number) {
                                  if (start) {
                                    setEnd(
                                      moment(start)
                                        .add(
                                          1 * +values.number,
                                          e.target.value === "week" ? "w" : "M"
                                        )
                                        .format("YYYY-MM-DD")
                                    );
                                  } else {
                                    setEnd(
                                      moment()
                                        .add(
                                          1 * +values.number,
                                          e.target.value === "week" ? "w" : "M"
                                        )
                                        .format("YYYY-MM-DD")
                                    );
                                  }
                                }
                              }}
                              input={
                                <OutlinedInput className='form-text-field' />
                              }
                              variant='outlined'
                              className='form-select-field'
                              MenuProps={{
                                className:
                                  "select-menu-list mail-modal__select-menu-list",
                              }}
                              style={{ width: 131, marginLeft: 8 }}
                              readOnly={!status ? true : false}
                            >
                              {RepeatMail.map((rep, idx: number) => {
                                return (
                                  <MenuItem key={idx} value={rep.value}>
                                    {rep.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      End
                    </Grid>
                    <Grid item xs={9}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Field name='addEnd'>
                          {({ input, meta, ...rest }) => {
                            return (
                              <Select
                                {...input}
                                {...rest}
                                value={input.value}
                                displayEmpty
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  if (e.target.value === "none") {
                                    setEnd(null);
                                  } else if (values.number && values.unit) {
                                    if (start) {
                                      setTimeout(() => {
                                        setEnd(
                                          moment(start)
                                            .add(
                                              1 * +values.number,
                                              values.unit === "week" ? "w" : "M"
                                            )
                                            .format("YYYY-MM-DD")
                                        );
                                      }, 300);
                                    } else {
                                      setTimeout(() => {
                                        setEnd(
                                          moment()
                                            .add(
                                              1 * +values.number,
                                              values.unit === "week" ? "w" : "M"
                                            )
                                            .format("YYYY-MM-DD")
                                        );
                                      }, 300);
                                    }
                                  }
                                }}
                                input={
                                  <OutlinedInput className='form-text-field' />
                                }
                                variant='outlined'
                                className='form-select-field'
                                MenuProps={{
                                  className:
                                    "select-menu-list mail-modal__select-menu-list",
                                }}
                                style={{ width: 88, marginRight: 8 }}
                                readOnly={!status ? true : false}
                              >
                                {AddMailEnd.map((rep, idx: number) => {
                                  return (
                                    <MenuItem key={idx} value={rep.value}>
                                      {rep.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            );
                          }}
                        </Field>
                        {values.addEnd !== "none" && (
                          <SingleDatePicker
                            day={end}
                            onChangeDate={(value) => {
                              setEnd(value);
                            }}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <ModalFooter
        formId='mail-form'
        showDeleteButton={""}
        handleDeleteAction={() => {}}
        handleCancelAction={onClose}
        title={data ? "Save" : "Add"}
        handleSubmit={() => {}}
      />
    </Dialog>
  );
};

export default MailModal;
