/*eslint-disable */
import React, { useRef } from "react";
import clsx from "clsx";
import { Add as AddIcon } from "@mui/icons-material";
import "./index.scss";
import { useClickOutside } from "../../../hooks";

const colors: any = {
  blue: "blue",
  blueGreen: "blue-green",
  red: "red",
  dardBlue: "dard-blue",
} as const;

const padding = 50;

type P_Props = {
  visible: boolean;
  onClickPrimary: () => void;
  onBlurPrimary?: () => void;
  btns: FloatingBtn[];
  className?: string;
};

type FloatingBtn = {
  title: string;
  color: string;
  icon: React.ReactNode;
  onClick: (e: any) => void;
};

const FloatingButton: React.FC<P_Props> = (props) => {
  const floatingBtnWidth: number | string = props.visible
    ? 74 * (props.btns.length + 1) + padding
    : "auto";

  const izVisibleBtns = props.visible && Boolean(props.btns.length);

  const primaryBtnRef: any = useRef(null);
  useClickOutside(primaryBtnRef, () => {
    props.onBlurPrimary && props.onBlurPrimary();
  });

  const classes = clsx({
    "floating-button": true,
    [props.className || ""]: Boolean(props.className),
  });

  return (
    <React.Fragment>
      <div
        className={`${izVisibleBtns ? "floating-button-background" : ""}`}
      ></div>
      <div className={classes} style={{ width: floatingBtnWidth }}>
        <div
          ref={primaryBtnRef}
          onClick={props.onClickPrimary}
          className='floating-button__item'
        >
          <div
            className={`floating-button__item__circle primary btn--shiny ${
              props.visible ? "active" : ""
            }`}
          >
            <AddIcon
              style={{
                fontSize: "2.2rem",
                color: !props.visible ? "#fff" : "#272B2F",
              }}
            />
          </div>
          <div className='floating-button__item__text hide'>Primary</div>
        </div>
        {izVisibleBtns &&
          props.btns.map((btn: any, i) => {
            const circleClass = clsx({
              "floating-button__item": true,
              [colors[btn.color]]: true,
            });

            return (
              <div
                key={i}
                onClick={btn.onClick}
                className='floating-button__item'
              >
                <div className={circleClass}>
                  <div
                    className={`floating-button__item__circle ${
                      colors[btn.color]
                    } btn--shiny`}
                  >
                    {btn.icon}
                  </div>
                  <div className='floating-button__item__text'>{btn.title}</div>
                </div>
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export default React.memo(FloatingButton);
