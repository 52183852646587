/* eslint-disable*/
// import moment from "moment";
import { AxiosResponse } from "axios";
import moment from "moment";
import { BASE_LINK_IMAGE, TEXT_HOLDER } from "../app/constants";
import Avatar from "../assets/images/avatar-df.png";

export const loadCallback = <T extends (...P: any[]) => any>(
  cb?: T,
  ...data: Parameters<T>
): ReturnType<T> => {
  return cb && cb(...data);
};

/**
 * Hiện giá trị theo định dạng tiền tệ của US
 * @param number kiểu dữ liệu number
 */
export function currencyFormat(number: number) {
  // output: 5,000,000
  // return new Intl.NumberFormat("en-US").format(number);

  // output: 5.0000.000
  return new Intl.NumberFormat("en-DE").format(Math.round(number));
}

export function getFirstChar(str: string): string {
  return str.charAt(0);
}

export function convertTagString(
  tagStr: string | undefined | null,
  config?: {
    seperator?: string;
  }
): string[] {
  if (!tagStr) return [];

  const seperator = config?.seperator || "|";
  let tags: string[] = tagStr.split(seperator);

  tags = tags.filter((tag) => {
    return !!tag.trim();
  });

  return tags;
}

export function loadToPage(path: string) {
  window.location.href = path;
}

export function capitalizeFirstLetter(str: string): string {
  return getFirstChar(str).toUpperCase() + str.slice(1);
}

export const listener = (
  type: string,
  handler: Function,
  target: any = window
) => {
  target.addEventListener(type, handler, { passive: false });
  return () => {
    target?.removeEventListener(type, handler);
  };
};

export function checkLinkImage(link: string) {
  if (link === undefined || link === null) {
    return;
  }
  return BASE_LINK_IMAGE + link;
}

export const renderErrorImage = () => {
  const setDefaultImageAvatar = (e: any) => {
    e.target.src = Avatar;
  };

  return setDefaultImageAvatar;
};

// tự động get api phân trang đến hết
// thường dùng cho export data
// input đầu tiên lúc nào cũng là filter
// input params là những params còn lại của service khi gọi api
export async function autoLoadingPagination(
  action: (...P: any[]) => Promise<void | AxiosResponse<any>>,
  input: { filter: any; params?: any },
  inputPage?: number,
  inputPageSize?: number,
  data?: any
) {
  let returnData: any[] = data || [];
  const limit = inputPageSize || 10;
  const page = inputPage || 1;
  let payload: any;

  try {
    // thêm phân trang vào input filter
    const query = [{ ...input.filter, page, limit }, input.params];
    const res = await action(...query);

    if (res && res.status && res.status === 200) {
      if (page === 1) {
        returnData.length = 0;
      }

      returnData.length > 0 && returnData.length >= res.data.totalRecords
        ? returnData
        : returnData.push(...res.data.data);

      payload = res.data;

      return { returnData, payload };
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const preventCharacter = (e: any) => {
  const { ctrlKey, key } = e;
  if (
    /[0-9]|Arrow|Backspace|Delete|Tab|\./.test(key) ||
    (ctrlKey && /^a|c|v|x$/.test(key))
  ) {
    return true;
  }
  e.preventDefault();
  return true;
};

export const preventCharacterNumber = (e: any) => {
  const { ctrlKey, key } = e;
  if (
    /[0-9]|Arrow|Backspace|Delete/.test(key) ||
    (ctrlKey && /^a|c|v|x$/.test(key))
  ) {
    return true;
  }
  e.preventDefault();
  return true;
};

export function YearList() {
  const fullYear = [];

  for (let year = new Date().getFullYear(); year >= 2023; year--) {
    fullYear.push(year);
  }

  return fullYear;
}

export function TimeList() {
  const locale = "en"; // or whatever you want...
  const hours = [];

  moment.locale(locale); // optional - can remove if you are only dealing with one locale

  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format("hh:mm A"));
  }

  return hours;
}

export function getMonday(d: any) {
  d = new Date(d);
  let day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function minutes_to_hhmm(
  numberOfMinutes: number,
  seconds?: boolean,
  text?: boolean
) {
  //calculate hours
  let hh = Math.floor(numberOfMinutes / 60);

  //get minutes
  let mm = numberOfMinutes % 60;

  let hhmm;

  let ss = ":" + "00";

  if (hh < 10 && mm < 10) {
    hhmm = "0" + hh + ":" + "0" + mm;
  } else if (hh < 10) {
    hhmm = "0" + hh + ":" + mm;
  } else if (mm < 10) {
    hhmm = hh + ":" + "0" + mm;
  } else {
    hhmm = hh + ":" + mm;
  }

  if (seconds) {
    return hhmm + ss;
  } else if (text) {
    return hh + " hrs " + mm + " mins";
  } else {
    return hhmm;
  }
}

export function hhmm_to_hhmma(time: any) {
  if (!time) return TEXT_HOLDER;
  if (+time.slice(0, 2) === 12) {
    return "12:00 PM";
  } else if (+time.slice(0, 2) === 0) {
    return "12:00 AM";
  } else if (+time.slice(0, 2) > 12) {
    return "0" + (time.slice(0, 2) - 12) + ":00 PM";
  } else {
    return time.slice(0, 2) + ":00 AM";
  }
}

export function hhmm_to_hhmmaMINUTE(time: any) {
  if (!time) return TEXT_HOLDER;
  if (+time.slice(0, 2) === 12) {
    return time + " PM";
  } else if (+time.slice(0, 2) === 0) {
    return "12:" + time.slice(3, 5) + " AM";
  } else if (+time.slice(0, 2) > 12) {
    if (time.length > 1) {
      return time.slice(0, 2) - 12 + ":" + time.slice(3, 5) + " PM";
    } else {
      return "0" + (time.slice(0, 2) - 12) + ":" + time.slice(3, 5) + " PM";
    }
  } else {
    return time.slice(0, 2) + ":" + time.slice(3, 5) + " AM";
  }
}

export function formatMDY(inputDate: string) {
  if (inputDate) {
    let date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      const day = date.getDate();
      const month = date.getMonth() + 1;

      const checkMonth =
        month.toString().length > 1 ? month.toString() : "0" + month.toString();

      const checkDate =
        day.toString().length > 1 ? day.toString() : "0" + day.toString();

      return checkMonth + "/" + checkDate + "/" + date.getFullYear();
    }
  } else {
    return TEXT_HOLDER;
  }
}

//array
export function flatten(arr: any) {
  return arr.reduce(function (flat: any, toFlatten: any) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

//get all day of week
export function dates(current: any) {
  let week = new Array();

  current.setDate(
    current.getDate() - current.getDay() + (current.getDay() === 0 ? -6 : 1)
  );
  for (let i = 0; i < 7; i++) {
    week.push(
      new Date(current).toLocaleDateString("en-DE", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    );
    current.setDate(current.getDate() + 1);
  }
  return week;
}

export function months(current: any) {
  let week = new Array();
  // Starting Monday not Sunday
  current.setDate(current.getDate() - current.getDay() + 1);
  for (let i = 0; i < 7; i++) {
    week.push(moment(current).format("MM"));
    current.setDate(current.getDate() + 1);
  }
  return week;
}

export function formatEnDE(date: string) {
  let d = new Date(date).toLocaleDateString("en-DE", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return d;
}

export function formatEnDENoWeek(date: string) {
  let d = new Date(date).toLocaleDateString("en-DE", {
    // weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return d;
}
