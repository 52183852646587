import {
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material";
import { Moment } from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./index.scss";
// import { Calendar1Icon } from "../Icons/Calendar1";
// import "moment/locale/vi";

type P_Props = {
  startDate: any;
  endDate: any;
  onChangeDate: (value: any[]) => void;
  iconStart?: boolean;
};

const RangePicker: React.FC<P_Props> = ({
  startDate,
  endDate,
  onChangeDate,
  iconStart,
}) => {
  const [value, setValue] = useState<{
    startDate: Moment;
    endDate: Moment;
  }>({
    startDate: startDate,
    endDate: endDate || "",
  });

  const [focusedInput, setFocusedInput] = useState(null);

  // useEffect(() => {
  //   setValue({
  //     startDate: startDate,
  //     endDate: endDate,
  //   });
  // }, [startDate, endDate]);

  const handleDatesChange = ({ startDate, endDate }: any) => {
    setValue(() => ({
      startDate,
      endDate,
    }));
    onChangeDate([value.startDate, endDate]);
  };

  return (
    <div
      style={{
        fontFamily: "Nunito Sans",
        fontSize: 16,
        color: "#272B2F",
      }}
      className='date-range'
    >
      <DateRangePicker
        customInputIcon={
          <CalendarMonthOutlinedIcon
            fontSize='large'
            style={{ color: iconStart ? "#00529C" : "#BDC6D7" }}
          />
        }
        inputIconPosition={iconStart ? "before" : "after"}
        startDate={value.startDate}
        startDateId='startDate'
        endDate={value.endDate}
        isOutsideRange={() => false}
        minimumNights={0}
        endDateId='endDate'
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        // displayFormat='ll'
        customArrowIcon={<RemoveIcon />}
        startDatePlaceholderText='mm/dd/yyyy'
        endDatePlaceholderText='mm/dd/yyyy'
        readOnly
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};

export default React.memo(RangePicker);
