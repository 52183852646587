import { Logout as LogoutIcon } from "@mui/icons-material";
import { Popover } from "@mui/material";
import React from "react";
import Avatar from "../../../../assets/images/avatar-df.png";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { RequestUtil, loadState, removeState, renderErrorImage } from "../../../../utils";
import { DEFAULT_IMG_ALT } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

type P_Props = {
  onClose: () => void;
  anchorEl: HTMLElement | null;
  avatarImg: string;
};
const SignOut: React.FC<P_Props> = (props) => {
  const [pushNoti] = useNoti();
  // const history = useHistory();
  const token = loadState();
  // const dispatchProfile = useAppDispatch();
  // const { data: profile } = useAppSelector(LoadMe);

  // useEffect(() => {
  //   dispatchProfile(loadProfile());
  // }, [dispatchProfile]);

  // const deActiveToken = async () => {
  //   await AuthService.onLogout({
  //     authorization: `Bearer ${token}`,
  //   });
  // };

  const onLogout = () => {
    if (token) {
      // deActiveToken();
      // localStorage.removeItem("accessToken");
      RequestUtil.removeAuthHeader();
      removeState();
    }
    pushNoti(AppNotifications.SUCCESS, "Sign out successful");
    setTimeout(() => {
      window.location.href = `${APP_ROUTES.SIGN_IN}`;
    }, 500);
  };

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <div className="app-toolbar-right-profile">
        <div className="name">
          <img
            src={Avatar}
            alt={DEFAULT_IMG_ALT}
            onError={renderErrorImage()}
          />
          <div>
            {/* {profile?.name || TEXT_HOLDER}
            <div className="email">{profile?.email || TEXT_HOLDER}</div> */}
            admin
            <div className="email">admin@gmail.com</div>
          </div>
        </div>
        <div className="menu-list">
          {/* <button
            onClick={() => {
              history.push(APP_ROUTES.PROFILE);
              props.onClose();
            }}>
            <UserIcon color={"#200E32"} size={[28, 28]} viewBox={[28, 18]} />
            Hồ sơ cá nhân
          </button>
          <button
            onClick={() => {
              history.push(APP_ROUTES.PROFILE_REPORT);
              props.onClose();
            }}>
            <SettingsIcon color={"#200E32"} size={[28, 28]} viewBox={[28, 18]} />
            Cài đặt gửi báo cáo
          </button> */}
          <button onClick={onLogout}>
            <LogoutIcon />
            &ensp;Sign out
          </button>
        </div>
      </div>
    </Popover>
  );
};

export default SignOut;
