import { ClearRounded as ClearRoundedIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Avatar from "../../../assets/images/avatar-df.png";
import useDateRange from "../../../hooks/useDateRange";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications } from "../../../models";
import { EmployeeService } from "../../../services/employee.service";
import { TimesheetService } from "../../../services/timesheet.service";
import { checkLinkImage, getMonday, renderErrorImage } from "../../../utils";
import ModalFooter from "../../components/ModalFooter";
import SimpleModalHeader from "../../components/SimpleModalHeader";
import { BASE_LINK_IMAGE, DEFAULT_IMG_ALT } from "../../constants";
import { SelectFieldsExport } from "../../constants/mock";
import RangePicker from "../RangePicker";
import "./index.scss";

type P_Props = {
  onClose: () => void;
  time?: string;
};

const ExportFile: React.FC<P_Props> = (props) => {
  const [pushNoti] = useNoti();
  const [range, setRange] = useDateRange();
  const [fields, setFields] = useState<string[]>(
    SelectFieldsExport.map((s) => {
      return s.value;
    })
  );
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [checkEmployee, setCheckEmployee] = useState<any[]>([]);

  const fetchData = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.time === "daily") {
      setRange(
        moment().set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    } else {
      setRange(
        moment(getMonday(new Date())),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.time]);

  const exportTimeSheet = async () => {
    const employeeIds = checkEmployee.map((e) => {
      return e._id;
    });

    if (employeeIds.length < 1) {
      pushNoti(AppNotifications.ERROR, "Please select employee");
      return;
    }

    try {
      const res = await TimesheetService.getReportTimeSheet({
        from: range?.startTime.format("YYYY-MM-DD") as string,
        to: range?.endTime.format("YYYY-MM-DD") as string,
        employeeIds: employeeIds,
        fields: fields,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        if (res.data?.data.fileName) {
          window.open(BASE_LINK_IMAGE + res.data?.data.fileName);
        }
        props.onClose();
      }
    } catch (error: any) {
      pushNoti(AppNotifications.ERROR, "Timesheet data export failed");
    }
  };

  return (
    <Dialog open={true} maxWidth='md'>
      <div className='export-modal'>
        <SimpleModalHeader
          title='Export'
          onClose={() => {
            props.onClose();
          }}
        />
        <div className='export-modal__content'>
          <div className='export-modal__label'>
            <div className='export-modal__num'>1</div>Time
          </div>
          <RangePicker
            startDate={range?.startTime}
            endDate={range?.endTime}
            onChangeDate={(val) => {
              if (val && val.length) {
                setRange(
                  moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                  moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                );
              }
            }}
          />
          <Divider style={{ borderColor: "#F0F3F8", margin: "2.2rem 0" }} />
          <div className='export-modal__label'>
            <div className='export-modal__num'>2</div>Select fields to export
          </div>
          <Grid container>
            {SelectFieldsExport.map((s, idx: number) => {
              return (
                <Grid item xs={6} key={idx}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={fields.includes(s.value)}
                        onChange={() => {
                          fields.some((e) => e === s.value)
                            ? fields.length > 1 &&
                              setFields(fields.filter((e) => e !== s.value))
                            : setFields([...fields, s.value]);
                        }}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                            color: "#10BED2",
                          },
                        }}
                        name={s.value}
                      />
                    }
                    label={
                      <span
                        className={`export-modal__check ${
                          fields.includes(s.value) &&
                          "export-modal__check__active"
                        }`}
                      >
                        {s.label}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Divider style={{ borderColor: "#F0F3F8", margin: "2.2rem 0" }} />
          <div className='export-modal__label'>
            <div className='export-modal__num'>3</div>Select employee
          </div>
          <Autocomplete
            multiple
            // open={true}
            disableClearable
            value={checkEmployee}
            defaultValue={listEmployee?.[0]}
            id='checkboxes-tags-demo'
            options={listEmployee}
            // getOptionLabel={(option) => option.fullName}
            getOptionLabel={(options) => options.fullName + " " + options?.code}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  avatar={
                    <img
                      src={checkLinkImage(option?.avatar as string) || Avatar}
                      alt={DEFAULT_IMG_ALT}
                      onError={renderErrorImage()}
                      style={{ borderRadius: "50%" }}
                    />
                  }
                  label={option.fullName}
                  style={{
                    borderRadius: 5,
                    fontSize: 14,
                    background: "#F0F3F8",
                    fontFamily: "Nunito Sans",
                  }}
                  deleteIcon={<ClearRoundedIcon style={{ color: "#BDC6D7" }} />}
                  onDelete={() => {
                    checkEmployee.some((e) => e.code === option.code)
                      ? setCheckEmployee(
                          checkEmployee.filter((e) => e.code !== option.code)
                        )
                      : setCheckEmployee([...checkEmployee, option]);
                  }}
                />
              ));
            }}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                onClick={() => {
                  checkEmployee.some((e) => e.code === option.code)
                    ? setCheckEmployee(
                        checkEmployee.filter((e) => e.code !== option.code)
                      )
                    : setCheckEmployee([...checkEmployee, option]);
                }}
              >
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                      color: "#10BED2",
                    },
                  }}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <div className='autocomplete-check'>
                  <img
                    src={checkLinkImage(option?.avatar as string) || Avatar}
                    alt={DEFAULT_IMG_ALT}
                    onError={renderErrorImage()}
                  />
                  <div className='autocomplete-check__name'>
                    {option.fullName}
                    <div className='autocomplete-check__code'>
                      {option.code}
                    </div>
                  </div>
                </div>
              </li>
            )}
            PaperComponent={({ children }) => {
              return (
                <Paper>
                  <div className='autocomplete-check__header'>
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          color: "#10BED2",
                        },
                      }}
                      style={{ marginRight: 8 }}
                      checked={checkEmployee.length === listEmployee?.length}
                      onMouseDown={() => {
                        if (checkEmployee.length !== listEmployee?.length) {
                          setCheckEmployee(listEmployee);
                        } else {
                          setCheckEmployee([]);
                        }
                      }}
                    />
                    All employee ({listEmployee?.length})
                  </div>
                  <Divider style={{ borderColor: "#F0F3F8", marginTop: 12 }} />
                  {children}
                </Paper>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder='Select employee'
                className='autocomplete-check__text-field'
              />
            )}
          />
        </div>
        <ModalFooter
          formId='export-modal-form'
          showDeleteButton={""}
          handleDeleteAction={() => {}}
          handleCancelAction={() => props.onClose()}
          title={"Export"}
          handleSubmit={() => {
            exportTimeSheet();
          }}
        />
      </div>
    </Dialog>
  );
};

export default ExportFile;
