import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const CalendarIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M6 2H3C1.89543 2 1 2.89543 1 4V16C1 17.1046 1.89543 18 3 18H17C18.1046 18 19 17.1046 19 16V4C19 2.89543 18.1046 2 17 2H6ZM6 2V0M6 2V4M14 0V2V4'
        stroke='#00529C'
        strokeWidth='2'
      />
      <rect
        x='3.69922'
        y='5.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='3.69922'
        y='9.2002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='3.69922'
        y='12.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='13.6992'
        y='5.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='13.6992'
        y='9.2002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='13.6992'
        y='12.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='8.69922'
        y='5.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='8.69922'
        y='9.2002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
      <rect
        x='8.69922'
        y='12.7002'
        width='2.6'
        height='2.6'
        rx='1.3'
        fill='#00529C'
      />
    </IconBase>
  );
};
