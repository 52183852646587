import { REQUEST_URL } from "../app/constants";
import { RequestUtil } from "../utils";

export const TimesheetService = {
  getReportTimeSheet: (data: {
    from: string;
    to: string;
    employeeIds: string[];
    fields: string[];
  }) => {
    const url = REQUEST_URL.REPORT_TIMEKEEPING;
    return RequestUtil.put({ url, data });
  },

  getHistory: (query: { period: string; employeeId?: string }) => {
    const url = REQUEST_URL.TIMEKEEPING_HISTORY;
    return RequestUtil.get({ url, query });
  },

  updateHistory: (data: any) => {
    const url = REQUEST_URL.TIMEKEEPING_HISTORY;
    return RequestUtil.put({ url, data });
  },

  getTimekeeping: (data: {
    date: string;
    employeeIds: string[];
    type: string;
  }) => {
    const url = REQUEST_URL.TIMEKEEPING_LIST;
    return RequestUtil.put({ url, data });
  },

  createTimekeeping: (data: any) => {
    const url = REQUEST_URL.TIMEKEEPING;
    return RequestUtil.post({ url, data });
  },

  updateTimekeeping: (data: any) => {
    const url = REQUEST_URL.TIMEKEEPING;
    return RequestUtil.put({ url, data });
  },

  delelteTimekeeping: (id: string) => {
    const url = REQUEST_URL.DELETE_TIMESHIFT.replace("{id}", id);
    return RequestUtil.delete({ url });
  },

  createAbsence: (data: any) => {
    const url = REQUEST_URL.TIMEKEEPING_ABSENCE;
    return RequestUtil.post({ url, data });
  },

  delelteAbsence: (id: string) => {
    const url = REQUEST_URL.DELETE_ABSENCE.replace("{id}", id);
    return RequestUtil.delete({ url });
  },
};
