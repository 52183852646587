import React from "react";
// import Avatar from "../../../../assets/images/avatar-df.png";
// import { renderErrorImage } from "../../../../utils";
// import { DEFAULT_IMG_ALT } from "../../../constants";
import "./index.scss";

const TemplateMail: React.FC = () => {
  return (
    <div className='setting-listing__content__right__list template-mail'>
      <div className='header-list-page'>
        <div style={{ width: "25%" }}>Email</div>
        <div style={{ width: "25%" }}>Type</div>
        <div style={{ width: "25%" }}>Created By</div>
        <div style={{ width: "25%" }}>Created On</div>
      </div>
      {/* <div className='item-list-page'>
        <div style={{ width: "25%" }}>Happy Women’s Day | 08.03</div>
        <div style={{ width: "25%" }}>Communication</div>
        <div className='item-name' style={{ width: "25%" }}>
          <img
            src={Avatar}
            alt={DEFAULT_IMG_ALT}
            onError={renderErrorImage()}
          />
          Jacob JonesO
        </div>
        <div style={{ width: "25%" }}>9:22 AM MM/DD/YYYY</div>
      </div> */}
    </div>
  );
};

export default TemplateMail;
