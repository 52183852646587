import {
  Clear as ClearIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { Dialog, Divider, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "../../../assets/images/avatar-df.png";
import ImportSc from "../../../assets/images/icons/import-employee-sc.png";
import Import from "../../../assets/images/icons/import-employee.png";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications } from "../../../models";
import { EmployeeService } from "../../../services/employee.service";
import { checkLinkImage, renderErrorImage } from "../../../utils";
import FloatingBtn from "../../components/FloatingBtn";
import { EditIcon } from "../../components/Icons/EditIcon";
import { ImportIcon } from "../../components/Icons/ImportIcon";
import { RemoveIcon } from "../../components/Icons/RemoveIcon";
import Loading from "../../components/Loading";
import ModalFooter from "../../components/ModalFooter";
import NotificationModal from "../../components/NotificationModal";
import SimpleModalHeader from "../../components/SimpleModalHeader";
import {
  BASE_LINK_IMAGE,
  DEFAULT_IMG_ALT,
  DEFAULT_STORE_INFOS,
  TEXT_HOLDER,
} from "../../constants";
import { MESSAGES } from "../../constants/mock";
import { APP_ROUTES } from "../../constants/module";
import { ACTIVE_COLOR, DEFAULT_COLOR } from "../../core/Layout/routes";
import "./index.scss";

const Staff: React.FC = () => {
  const history = useHistory();
  const [pushNoti] = useNoti();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [notiDelete, setNotiDelete] = useState<{
    id: string;
    fullName: string;
  }>({ id: "", fullName: "" });
  const [listEmployee, setListEmployee] = useState<any[]>([]);
  const [importFile, setImportFile] = useState<boolean>(false);
  const [importSc, setImportSc] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [checkErr, setCheckErr] = useState<boolean | string>(false);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const fetchData = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteEmployee = async (id: string) => {
    try {
      const res = await EmployeeService.deleteEmployee(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, MESSAGES.SUCCESS);
        setNotiDelete({ id: "", fullName: notiDelete.fullName });
        fetchData();
      } else if (res && res.status === 400) {
        pushNoti(AppNotifications.ERROR, res.data?.message);
      } else {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
      console.log(error);
    }
  };

  const onFileChange = (event: any) => {
    const fileExcel: File = event.target.files[0];
    setSelectedFile(fileExcel);
  };

  const checkFile = () => {
    const fileExcel = selectedFile?.name.slice(
      selectedFile?.name.lastIndexOf("."),
      selectedFile?.name.length
    );

    if (fileExcel !== ".xls" && fileExcel !== ".csv") {
      if (fileExcel !== ".xlsx") {
        return setCheckErr(
          "The uploaded file must have the extension .xls, .xlsx or .csv"
        );
      }
    }

    return setCheckErr(true);
  };

  useEffect(() => {
    selectedFile && checkFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const importEmployee = async () => {
    if (selectedFile === null) {
      return setCheckErr("You have not selected a file to upload");
    }
    if (checkErr) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const res = await EmployeeService.importEmployee(formData);
        if (res.status === 200 || res.status === 201) {
          setImportFile(false);
          setImportSc(res.data?.data);
          if (res.data?.data?.errorFilePath) {
            window.open(BASE_LINK_IMAGE + res.data?.data.errorFilePath);
          }
          fetchData();
        } else if (res && res.status === 400) {
          pushNoti(AppNotifications.ERROR, res.data?.message);
        } else {
          pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
        }
      } catch (error) {
        pushNoti(AppNotifications.ERROR, MESSAGES.ERROR);
        console.log(error);
      }
    }
  };

  return (
    <div className='staff-listing'>
      <div className='staff-listing__content'>
        <div className='staff-listing__content__action'>
          <div className='staff-listing__content__action__title'>
            All teams ({listEmployee?.length})
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder='Search by Name, ID, Email'
              variant='outlined'
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon fontSize='large' />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button
              className='btn-cancel-style btn--shadow'
              onClick={() =>
                // pushNoti(
                //   AppNotifications.WARNING,
                //   "The feature will be updated on 07/04/2023."
                // )
                setImportFile(true)
              }
            >
              <ImportIcon
                color={ACTIVE_COLOR}
                size={[24, 24]}
                viewBox={[24, 24]}
              />
              &emsp;Import
            </button>
          </div>
        </div>
        <div className='staff-listing__content__list'>
          <div className='header-list-page'>
            <div className='staff-listing__content__list__id'>Employee ID</div>
            <div className='staff-listing__content__list__name'>Name</div>
            <div className='staff-listing__content__list__email'>Email</div>
            <div className='staff-listing__content__list__phone'>Phone</div>
            <div className='staff-listing__content__list__edit'></div>
            <div className='staff-listing__content__list__del'></div>
          </div>
          {listEmployee?.length ? (
            (searchValue
              ? listEmployee?.filter(
                  (f) =>
                    f.code
                      ?.toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    f.fullName
                      ?.toLowerCase()
                      .includes(searchValue?.toLowerCase()) ||
                    f.email?.toLowerCase().includes(searchValue?.toLowerCase())
                )
              : listEmployee
            )?.map((employee: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className='item-list-page'
                  onClick={() => {
                    history.push(
                      APP_ROUTES.STAFF_DETAIL.replace(":staffId", employee._id)
                    );
                  }}
                >
                  <div className='staff-listing__content__list__id'>
                    {employee.code}
                  </div>
                  <div className='staff-listing__content__list__name item-name'>
                    <img
                      src={checkLinkImage(employee.avatar as string) || Avatar}
                      alt={DEFAULT_IMG_ALT}
                      onError={renderErrorImage()}
                    />
                    {employee.fullName}
                  </div>
                  <div className='staff-listing__content__list__email'>
                    {employee.email}
                  </div>
                  <div className='staff-listing__content__list__phone'>
                    {employee.phone || TEXT_HOLDER}
                  </div>
                  <div className='staff-listing__content__list__edit'>
                    <div
                      style={{ width: "fit-content" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(
                          APP_ROUTES.STAFF_EDIT.replace(
                            ":staffId",
                            employee._id
                          )
                        );
                      }}
                    >
                      <EditIcon
                        color={DEFAULT_COLOR}
                        size={[18, 18]}
                        viewBox={[18, 18]}
                      />
                    </div>
                  </div>
                  <div className='staff-listing__content__list__del'>
                    <div
                      style={{ width: "fit-content" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setNotiDelete({
                          id: employee._id,
                          fullName: employee.fullName,
                        });
                      }}
                    >
                      <RemoveIcon
                        color={"#272B2F"}
                        size={[18, 18]}
                        viewBox={[18, 18]}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <Loading />
          )}
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.STAFF_CREATE);
        }}
        btns={[]}
      />

      <NotificationModal
        visible={notiDelete.id !== ""}
        title={"Delete Employee"}
        subTitle={
          <span>
            Are you sure you want to delete employee
            <span style={{ fontWeight: 700 }}> {notiDelete?.fullName}</span>?
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon
              style={{ fontSize: "7rem", color: "#fff" }}
            />
          </div>
        }
        actions={
          <>
            <button
              className='btn-cancel-style'
              onClick={() => {
                setNotiDelete({ id: "", fullName: notiDelete.fullName });
              }}
            >
              Cancel
            </button>
            <button
              className='btn-confirm-style'
              onClick={() => {
                deleteEmployee(notiDelete.id);
              }}
            >
              Delete
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete({ id: "", fullName: notiDelete.fullName });
        }}
      />

      <Dialog open={importFile} maxWidth='md'>
        <div className='dialog-import-employee'>
          <SimpleModalHeader
            title={"Import Employees"}
            onClose={() => {
              setImportFile(false);
              setSelectedFile(null);
            }}
          />
          <div className='dialog-import-employee__content'>
            <div className='dialog-import-employee__content__file'>
              <div>
                Only Excel (.xls and .xlsx) and .csv file types are supported.
              </div>
              <a
                href={DEFAULT_STORE_INFOS.FILE_EXCEL_IMPORT_EMPLOYEE}
                download='sample-file.xlsx'
                target='_blank'
                rel='noreferrer'
                className='dialog-import-employee__content__file__link-color'
              >
                Download Sample File
              </a>
            </div>
            <div className='dialog-import-employee__content__upload'>
              <div className='dialog-import-employee__content__upload__img'>
                <img src={Import} alt={DEFAULT_IMG_ALT} />
                <input type='file' onChange={onFileChange} />
              </div>
              {selectedFile && (
                <div className='selected-file'>
                  <div className='selected-file__name'>
                    <InsertDriveFileOutlinedIcon
                      fontSize='large'
                      style={{ marginBottom: -3, marginRight: 10 }}
                    />
                    {selectedFile?.name}
                  </div>
                  <div
                    onClick={() => {
                      setSelectedFile(null);
                      setCheckErr(false);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon
                      fontSize='large'
                      style={{ marginBottom: -5, cursor: "pointer" }}
                    />
                  </div>
                </div>
              )}
              {!selectedFile && (
                <div className='dialog-import-employee__content__input'>
                  <div>Drag your file here to import employees</div>
                  <div className='dialog-import-employee__content__input__line'>
                    <Divider
                      style={{ borderColor: "#BDC6D7", padding: 1, width: 61 }}
                    />
                    &nbsp;or&nbsp;
                    <Divider
                      style={{ borderColor: "#BDC6D7", padding: 1, width: 61 }}
                    />
                  </div>
                  <button className='btn-cancel-style'>Browse files</button>
                  <input type='file' onChange={onFileChange} />
                </div>
              )}
            </div>
            {typeof checkErr === "string" && (
              <div className='err'>
                <ErrorIcon style={{ marginBottom: 2, marginRight: 8 }} />
                {checkErr}
              </div>
            )}
          </div>
          <ModalFooter
            formId='import-staff-form'
            showDeleteButton={""}
            handleDeleteAction={() => {}}
            handleCancelAction={() => {
              setImportFile(false);
              setSelectedFile(null);
            }}
            title={"Import"}
            handleSubmit={() => {
              importEmployee();
            }}
          />
        </div>
      </Dialog>

      <Dialog open={importSc !== null} maxWidth='sm'>
        <div className='dialog-import-sc'>
          <div
            className='dialog-import-sc__clear'
            onClick={() => {
              setImportSc(null);
            }}
          >
            <ClearIcon fontSize='large' />
          </div>
          <div className='dialog-import-sc__content'>
            <img src={ImportSc} alt={DEFAULT_IMG_ALT} />
            <div className='dialog-import-sc__content__title'>
              Successfully Imported
            </div>
            <div className='dialog-import-sc__content__table'>
              <div className='dialog-import-sc__content__table__row'>
                <div>Import successfully :</div>
                <div className='dialog-import-sc__content__table__row__val'>
                  {importSc?.succeed}
                </div>
              </div>
              <div className='dialog-import-sc__content__table__row'>
                <div>Duplicate employee :</div>
                <div className='dialog-import-sc__content__table__row__val'>
                  {importSc?.skip}
                </div>
              </div>
              <div className='dialog-import-sc__content__table__row'>
                <div>Import Error : </div>
                <div className='dialog-import-sc__content__table__row__val'>
                  {importSc?.error}
                </div>
              </div>
            </div>
          </div>
          <div className='dialog-import-sc__btn'>
            <button
              className='btn-cancel-style'
              onClick={() => {
                setImportSc(null);
                setImportFile(true);
                setSelectedFile(null);
              }}
            >
              Import an other file
            </button>
            <button
              className='btn-confirm-style'
              onClick={() => {
                setImportSc(null);
              }}
            >
              Done
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Staff;
