import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export type P_Props = {
  className?: string;
  style?: React.CSSProperties;
};

const Loading: React.FC<P_Props> = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(true);
  };

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: "rgba(255, 255, 255, 0.8)",
      }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress disableShrink size={50} sx={{ color: "#10BED2" }} />
    </Backdrop>
  );
};

export default Loading;
