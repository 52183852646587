import { AddRounded as AddRoundedIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DEFAULT_COLOR } from "../../core/Layout/routes";
import { RemoveIcon } from "../Icons/RemoveIcon";
import "./index.scss";

type P_Props = {
  data?: string[];
  onChangeOtherInsuranc: (others: any) => void;
};

const OtherInsuranc: React.FC<P_Props> = (props) => {
  const [value, setValue] = useState<number>(1);

  const [otherList, setOtherList] = useState<any[]>([
    {
      id: 0,
      text: "",
    },
    ...[],
  ]);

  useEffect(() => {
    if (props.data && props.data?.length > 0) {
      setOtherList(
        props.data.map((d, idx) => {
          return {
            id: idx,
            text: d,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data && props.data?.length > 0]);

  const onAddOther = (id: number, text: string, addNew: boolean = false) => {
    const idx = otherList.findIndex((item) => item.id === id);
    let data = [...otherList];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      data[idx] = {
        ...data[idx],
        id: id,
        text: text,
      };
    } else {
      data.push({ id, text });
    }

    setOtherList(data);
  };

  useEffect(() => {
    props.onChangeOtherInsuranc(
      otherList.map((m) => {
        return m.text;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherList]);

  return (
    <div className="other-insuranc">
      {otherList?.map((o, idx: number) => {
        return (
          <div key={idx} className="other-insuranc__item">
            <TextField
              fullWidth
              placeholder={"Place holder"}
              color="success"
              className="form-text-field"
              inputProps={{ className: "input" }}
              variant="outlined"
              onChange={(e) => onAddOther(o.id, e.target.value)}
              autoComplete="off"
              value={o.text}
            />
            {otherList?.length > 1 && (
              <div
                className="btn-remove"
                onClick={() => {
                  setOtherList(otherList.filter((_, index) => index !== idx));
                }}
              >
                <RemoveIcon
                  color={DEFAULT_COLOR}
                  size={[18, 18]}
                  viewBox={[18, 18]}
                />
                &ensp;Delete
              </div>
            )}
          </div>
        );
      })}
      <div
        className="other-insuranc__title-add"
        onClick={() => {
          const newValue = value + 1;
          setValue(newValue);
          onAddOther(value, "", true);
        }}
      >
        <AddRoundedIcon fontSize="large" />
        &ensp;Add more
      </div>
    </div>
  );
};

export default OtherInsuranc;
