export const REQUEST_URL = {
  //authen
  SIGN_IN: "/auth/login",

  //profile
  AUTH_PROFILE: "/auth/profile",

  //employee
  EMPLOYEE: "/employee",
  EMPLOYEE_TEAM: "/employee/team",
  EMPLOYEE_POSITION: "/employee/position",
  DELETE_EMPLOYEE: "/employee/_id",
  DELETE_EMPLOYEE_TEAM: "/employee/team/_id",
  DELETE_EMPLOYEE_POSITION: "/employee/position/_id",
  EMPLOYEE_AVATAR: "/employee/avatar",
  EMPLOYEE_IMPORT: "/employee/import",
  DELETE_AVATAR_EMPLOYEE: "/employee/avatar/{id}",

  //timesheet
  REPORT_TIMEKEEPING: "/report/timekeeping",
  TIMEKEEPING_HISTORY: "/timekeeping/history",
  TIMEKEEPING_LIST: "/timekeeping/list",
  TIMEKEEPING: "/timekeeping",
  DELETE_TIMESHIFT: "/timekeeping/timeshift/{id}",
  DELETE_ABSENCE: "/timekeeping/absence/{id}",
  TIMEKEEPING_ABSENCE: "/timekeeping/absence",

  //email
  EMAIL: "/email",
  DELETE_EMAIL: "/email/_id",
  ACTIVE_EMAIL: "/email/activate",
} as const;
