import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const ExportIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path d="M16 4V8C16 8.55228 16.4477 9 17 9H21L16 4Z" fill="#6C778D" />
      <path
        d="M16 4H6C5.44772 4 5 4.44772 5 5V12M16 4V8C16 8.55228 16.4477 9 17 9H21M16 4L21 9M21 9V19C21 19.5523 20.5523 20 20 20H13"
        stroke="#6C778D"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M5 16L5 20M5 20L9 20M5 20L11 14"
        stroke="#6C778D"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </IconBase>
  );
};
