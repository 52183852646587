import { AddRounded as AddRoundedIcon } from "@mui/icons-material";
import {
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormUIUtil } from "../../../utils";
import { AbsenceType } from "../../constants/mock";
import { RemoveIcon } from "../Icons/RemoveIcon";
import "./index.scss";

type P_Props = {
  data?: any;
  onChange: (absence: any) => void;
};

const ChooseAbsence: React.FC<P_Props> = (props) => {
  const [value, setValue] = useState<number>(1);
  const [absenceList, setAbsenceList] = useState<any[]>([]);

  useEffect(() => {
    setAbsenceList(props.data);
  }, [props.data]);

  const onChooseAbsence = (
    id: number,
    startTime: string,
    endTime: string,
    type: string,
    note: string,
    addNew: boolean = false
  ) => {
    const idx = absenceList.findIndex((item) => item.id === id);
    let data = [...absenceList];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      data[idx] = {
        ...data[idx],
        id: id,
        startTime: startTime,
        endTime: endTime,
        type: type,
        note: note,
      };
    } else {
      data.push({
        id,
        startTime,
        endTime,
        type,
        note,
      });
    }

    props.onChange(data);
  };

  return (
    <div className='choose-absence'>
      {absenceList?.map((o, idx: number) => {
        return (
          <div key={idx} className='choose-absence__item'>
            <Grid
              container
              className='choose-absence__item__form'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={5}>
                {FormUIUtil.renderFormItem(
                  "Start time",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      onChooseAbsence(
                        o.id,
                        e.target.value,
                        o.endTime,
                        o.type,
                        o.note
                      )
                    }
                    type='time'
                    defaultValue={
                      o.startTime ||
                      absenceList[idx]?.startTime ||
                      props.data[idx]?.startTime
                    }
                  />,
                  true
                )}
              </Grid>
              <Grid item xs={2}>
                &#8212;
              </Grid>
              <Grid item xs={5}>
                {FormUIUtil.renderFormItem(
                  "End time",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      onChooseAbsence(
                        o.id,
                        o.startTime,
                        e.target.value,
                        o.type,
                        o.note
                      )
                    }
                    type='time'
                    defaultValue={
                      o.endTime ||
                      absenceList[idx]?.endTime ||
                      props.data[idx]?.endTime
                    }
                  />,
                  true
                )}
              </Grid>
              <Grid item xs={12}>
                {FormUIUtil.renderFormItem(
                  "Absence type",
                  <Select
                    value={o.type}
                    displayEmpty
                    onChange={(e) =>
                      onChooseAbsence(
                        o.id,
                        o.startTime,
                        o.endTime,
                        e.target.value,
                        o.note
                      )
                    }
                    input={<OutlinedInput className='form-text-field' />}
                    variant='outlined'
                    className='form-select-field'
                    MenuProps={{
                      className: "select-menu-list",
                    }}
                  >
                    {AbsenceType.map((a, idx: number) => {
                      return (
                        <MenuItem key={idx} value={a.val}>
                          {a.label}
                        </MenuItem>
                      );
                    })}
                  </Select>,
                  true
                )}
              </Grid>
              <Grid item xs={12}>
                {FormUIUtil.renderFormItem(
                  "Note",
                  <TextField
                    fullWidth
                    placeholder={"Place holder"}
                    color='success'
                    className='form-text-field'
                    inputProps={{ className: "input" }}
                    variant='outlined'
                    onChange={(e) =>
                      onChooseAbsence(
                        o.id,
                        o.startTime,
                        o.endTime,
                        o.type,
                        e.target.value
                      )
                    }
                    autoComplete='off'
                    value={o.note}
                  />,
                  true
                )}
              </Grid>
            </Grid>
            {absenceList?.length > 1 && (
              <div className='choose-absence__item__group'>
                <div
                  className='choose-absence__item__group__btn'
                  onClick={() => {
                    setAbsenceList(
                      absenceList.filter((_, index) => index !== idx)
                    );
                  }}
                >
                  &emsp;
                  <RemoveIcon
                    color={"#6C778D"}
                    size={[18, 18]}
                    viewBox={[18, 18]}
                  />
                  &ensp;Delete timeshift
                </div>
              </div>
            )}
          </div>
        );
      })}
      <div
        className='choose-absence__title-add'
        onClick={() => {
          const newValue = value + 1;
          setValue(newValue);
          onChooseAbsence(value, "", "", "paid", "", true);
        }}
      >
        <AddRoundedIcon fontSize='large' />
        &ensp;Add more absence shift
      </div>
    </div>
  );
};

export default ChooseAbsence;
