import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const EditIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M8.60868 3.06442H3.41505C3.02149 3.06442 2.64406 3.22076 2.36578 3.49905C2.08749 3.77733 1.93115 4.15477 1.93115 4.54832V14.9356C1.93115 15.3291 2.08749 15.7066 2.36578 15.9849C2.64406 16.2631 3.02149 16.4195 3.41505 16.4195H13.8023C14.1959 16.4195 14.5733 16.2631 14.8516 15.9849C15.1299 15.7066 15.2862 15.3291 15.2862 14.9356V9.74196'
        stroke={props.color ? props.color : "#272B2F"}
        strokeWidth='1.78068'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.1733 1.9515C14.4684 1.65633 14.8688 1.49051 15.2862 1.49051C15.7036 1.49051 16.1039 1.65633 16.3991 1.9515C16.6943 2.24666 16.8601 2.64699 16.8601 3.06442C16.8601 3.48185 16.6943 3.88218 16.3991 4.17734L9.3506 11.2258L6.38281 11.9678L7.12476 9L14.1733 1.9515Z'
        stroke={props.color ? props.color : "#272B2F"}
        strokeWidth='1.78068'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconBase>
  );
};
