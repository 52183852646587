// export const saveState = (state: string) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("EXAMPLE-TOKEN", serializedState);
//   } catch (error) {
//     console.log(error);
//   }
// };

// export const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem("accessToken");

//     if (serializedState === null) {
//       return undefined;
//     }
//     return serializedState;
//   } catch (error) {
//     return undefined;
//   }
// };

const TOKEN_KEY = "face-id-access-token";

export const saveState = (state: string) => {
  try {
    localStorage.setItem(TOKEN_KEY, state);
  } catch (error) {
    console.log(error);
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(TOKEN_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (error) {
    return undefined;
  }
};

export const removeState = () => {
  try {
    const serializedState = localStorage.removeItem(TOKEN_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (error) {
    return undefined;
  }
};
