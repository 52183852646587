import { Dialog, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Avatar from "../../../../assets/images/avatar-df.png";
import { EmployeeService } from "../../../../services/employee.service";
import {
  checkLinkImage,
  formatMDY,
  minutes_to_hhmm,
  renderErrorImage,
} from "../../../../utils";
import Loading from "../../../components/Loading";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

const Staff: React.FC = () => {
  const history = useHistory();
  const [overtime, setOvertime] = useState<boolean>(true);
  const { staffId }: { staffId: string } = useParams();
  const [employeeDetail, setEmployeeDetail] = useState<any>(null);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await EmployeeService.getEmployeeDetailById(staffId);
      if (result.data.data) {
        setEmployeeDetail(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId]);

  return (
    <Dialog
      open
      fullScreen
      className='staff-detail'
      PaperProps={{
        style: {
          backgroundColor: "#f0f3f8",
        },
      }}
    >
      <SimpleModalHeader
        title='Employee Detail'
        onClose={() => {
          history.push(APP_ROUTES.STAFF);
        }}
      />
      {employeeDetail ? (
        <div className='staff-detail__content'>
          <div className='content-item staff-detail__content__left'>
            <div className='staff-detail__content__left__profile'>
              <img
                src={checkLinkImage(employeeDetail?.avatar as string) || Avatar}
                alt={DEFAULT_IMG_ALT}
                onError={renderErrorImage()}
              />
              <div>
                <div className='staff-detail__content__left__profile__name'>
                  {employeeDetail?.fullName || TEXT_HOLDER}
                </div>
                <div className='staff-detail__content__left__profile__text'>
                  {employeeDetail?.position?.name || TEXT_HOLDER}
                </div>
                {/* <div className="staff-detail__content__left__profile__status">
                  {StatusUIUtil.renderStatusStaffLabel("Active")}
                </div> */}
              </div>
            </div>
            <div className='staff-detail__content__left__info'>
              <div className='content-item__title'>General Information</div>
              <Grid container rowSpacing={1}>
                <Grid item xs={4} className='content-item__label'>
                  Employee ID
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.code || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Full name
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.fullName || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Email
                </Grid>
                <Grid item xs={8} style={{ color: "#0E76D9" }}>
                  {employeeDetail?.email || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Phone
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.phone || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Gender
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.gender || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Address
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.address || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Date of birth
                </Grid>
                <Grid item xs={8}>
                  {formatMDY(employeeDetail?.dateOfBirth) || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Team
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail.team?.name || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Position
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.position?.name || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  First day of work
                </Grid>
                <Grid item xs={8}>
                  {formatMDY(employeeDetail?.firstDayOfWork) || TEXT_HOLDER}
                </Grid>
              </Grid>
              <div className='content-item__title'>Insurance Information</div>
              <Grid container rowSpacing={1}>
                <Grid item xs={4} className='content-item__label'>
                  Social insurance
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.insurance.social || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Health insurance
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.insurance.health || TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={4} className='content-item__label'>
                  Other insurance policies
                </Grid>
                <Grid item xs={8}>
                  {employeeDetail?.insurance.others?.length
                    ? employeeDetail?.insurance.others.map((oth: string) => {
                        return oth;
                      })
                    : TEXT_HOLDER}
                </Grid>
              </Grid>
            </div>
          </div>
          <div className='staff-detail__content__right'>
            <div className='content-item'>
              <div className='content-item__title'>Salary Information</div>
              <Grid container spacing={2}>
                <Grid item xs={6} className='content-item__label'>
                  Current salary
                </Grid>
                <Grid item xs={6} className='content-item__money'>
                  ${employeeDetail?.salary.current || TEXT_HOLDER}/
                  {employeeDetail?.salary.timeUnit}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={6} className='content-item__label'>
                  Paid Leave Hours
                </Grid>
                <Grid item xs={6} className='content-item__money'>
                  {employeeDetail?.salary.paidLeaveHours
                    ? minutes_to_hhmm(employeeDetail?.salary.paidLeaveHours)
                    : TEXT_HOLDER}
                </Grid>
                <Grid item xs={12} className='content-item__label'>
                  <Divider style={{ borderColor: "#F0F3F8" }} />
                </Grid>
                <Grid item xs={6} className='content-item__label'>
                  Weekly work hours
                </Grid>
                <Grid item xs={6} className='content-item__money'>
                  {employeeDetail?.salary.weeklyWorkHours
                    ? minutes_to_hhmm(employeeDetail?.salary.weeklyWorkHours)
                    : TEXT_HOLDER}
                </Grid>
                <Grid item xs={12}>
                  <Grid container className='overtime-salary'>
                    <Grid
                      item
                      xs={6}
                      className='content-item__label'
                      style={{ paddingBottom: 8 }}
                    >
                      Overtime salary
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{ paddingBottom: 8, textAlign: "end" }}
                    >
                      <DefaultSwitch
                        checked={employeeDetail?.salary.overtimes?.length > 0}
                        onChange={() => {
                          setOvertime(!overtime);
                        }}
                      />
                    </Grid>
                    <div className='overtime-salary__list'>
                      {employeeDetail?.salary.overtimes?.map(
                        (over: any, idx: number) => {
                          return (
                            <Grid container key={idx}>
                              <Grid
                                item
                                xs={4}
                                className='overtime-salary__label'
                              >
                                On days
                              </Grid>
                              <Grid item xs={8}>
                                {over.onDays.map((day: string, idx: number) => {
                                  return (
                                    <span key={idx}>
                                      {day.charAt(0).toUpperCase() +
                                        day.slice(1)}
                                      {over.onDays?.length !== idx + 1 && (
                                        <span>,&nbsp;</span>
                                      )}
                                    </span>
                                  );
                                })}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className='overtime-salary__label'
                              >
                                Start after
                              </Grid>
                              <Grid item xs={8}>
                                {over.startAfter}&nbsp;hours/day
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                className='overtime-salary__label'
                              >
                                Multiplier
                              </Grid>
                              <Grid item xs={8}>
                                {over.multiplier} x Current salary
                              </Grid>
                              <Grid item xs={12}>
                                <Divider
                                  style={{
                                    borderColor: "#F0F3F8",
                                    margin: "2.2rem 0 2rem",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <button
              className='btn-confirm-style'
              onClick={() =>
                history.push(
                  APP_ROUTES.STAFF_EDIT.replace(":staffId", employeeDetail._id)
                )
              }
            >
              Edit
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </Dialog>
  );
};

export default Staff;
