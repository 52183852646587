import React from "react";
import { Route, Switch } from "react-router";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import { APP_ROUTES } from "./app/constants/module";
import { authRoute } from "./app/containers/Authentication/authRoutes";
import NotFound from "./app/containers/NotFound";
import Layout from "./app/core/Layout";
import { appRoutes } from "./app/core/Layout/routes";
import PrivateRoute from "./app/core/auth/PrivateRoute";

const SignInForm = React.lazy(() => import("./app/containers/Authentication/SignInForm"));

function App() {
  return (
    <React.Suspense >
        <Router>
          <Switch>
            <Route exact path={APP_ROUTES.SIGN_IN} component={SignInForm} />
            <Switch>
              {authRoute.map((route, index) => {
                return <Route key={index} {...route} />;
              })}
              <PrivateRoute>
                <Layout>
                  <Route
                    exact
                    path={APP_ROUTES.HOME}
                    render={() => <Redirect to={APP_ROUTES.STAFF} />}
                  />
                  <Switch>
                    {appRoutes.map((route, index) => {
                      return <Route key={index} {...route} />;
                    })}
                    <Route component={NotFound} />
                  </Switch>
                </Layout>
              </PrivateRoute>
            </Switch>
          </Switch>
        </Router>
      
    </React.Suspense>
  );
}

export default App;
