import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const RemoveIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M2.8479 4.54833H4.3318M4.3318 4.54833H16.203M4.3318 4.54833V14.9356C4.3318 15.3292 4.48814 15.7066 4.76642 15.9849C5.0447 16.2632 5.42214 16.4195 5.81569 16.4195H13.2352C13.6287 16.4195 14.0062 16.2632 14.2844 15.9849C14.5627 15.7066 14.7191 15.3292 14.7191 14.9356V4.54833H12.4932M4.3318 4.54833H6.55764M6.55764 4.54833H12.4932M6.55764 4.54833V3.06443C6.55764 2.67088 6.71398 2.29344 6.99226 2.01516C7.27055 1.73687 7.64798 1.58054 8.04154 1.58054H11.0093C11.4029 1.58054 11.7803 1.73687 12.0586 2.01516C12.3369 2.29344 12.4932 2.67088 12.4932 3.06443V4.54833'
        stroke={props.color}
        strokeWidth='1.78068'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </IconBase>
  );
};
