import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const ImportIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d='M16.5 4V8C16.5 8.55228 16.9477 9 17.5 9H21.5L16.5 4Z'
        fill='#6C778D'
      />
      <path
        d='M16.5 4H6.5C5.94772 4 5.5 4.44772 5.5 5V12M16.5 4V8C16.5 8.55228 16.9477 9 17.5 9H21.5M16.5 4L21.5 9M21.5 9V19C21.5 19.5523 21.0523 20 20.5 20H13.5'
        stroke='#6C778D'
        strokeWidth='1.8'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 18L11.5 14M11.5 14L7.5 14M11.5 14L5.5 20'
        stroke='#6C778D'
        strokeWidth='1.8'
        strokeLinecap='round'
      />
    </IconBase>
  );
};
