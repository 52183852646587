import {
  KeyboardDoubleArrowRightRounded as KeyboardDoubleArrowRightRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Divider, Drawer, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import IconMail from "../../../assets/images/icons/setting-mail.png";
// import IconSalary from "../../../assets/images/icons/setting-salary.png";
// import IconTime from "../../../assets/images/icons/setting-time.png";
import { DEFAULT_IMG_ALT } from "../../constants";
import "./index.scss";
// import { NewIcon } from "../../components/Icons/NewIcon";
// import { PayrollIcon } from "../../components/Icons/PayrollIcon";
import { NavHeaderMail } from "../../constants/mock";
import MailListing from "./Mail";
import TemplateMail from "./TemplateMail";

export const mockMenu = [
  {
    label: "Mail",
    icon: IconMail,
  },
  // {
  //   label: "Salary",
  //   icon: IconSalary,
  // },
  // {
  //   label: "Time zone",
  //   icon: IconTime,
  // },
];

const Setting: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [valMenu, setValMenu] = useState<string>("Mail");
  const [count, setCount] = useState<any[]>([0, 0]);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(true);
  const [selectTab, setSelectTab] = useState<string>("email");

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  return (
    <div className='setting-listing'>
      <div className='setting-listing__content'>
        <div
          className='setting-listing__content__left'
          style={{
            width: !visibleDrawer ? "3rem" : "",
            background: !visibleDrawer ? "#ffffff" : "",
          }}
        >
          <div
            className='setting-listing__content__left btn-cancel-style'
            onClick={() => {
              setVisibleDrawer(!visibleDrawer);
            }}
          >
            <KeyboardDoubleArrowRightRoundedIcon
              fontSize='large'
              style={{
                color: "#6C778D",
                transform: visibleDrawer ? "rotate(180deg)" : "",
              }}
            />
          </div>
          <Drawer
            sx={{
              width: !visibleDrawer ? "3rem" : 209,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 209,
                boxSizing: "border-box",
                position: "relative",
                height: "100vh",
              },
            }}
            variant='persistent'
            anchor='left'
            open={visibleDrawer}
          >
            <div className='setting-listing__content__left__title'>Menu</div>
            <Divider />
            <div className='setting-listing__content__left__search'>
              <TextField
                placeholder='Search'
                variant='outlined'
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon fontSize='large' />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
            </div>
            <div className='setting-listing__content__left__menu'>
              {mockMenu.map((m, idx) => {
                return (
                  <div
                    key={idx}
                    className={`setting-listing__content__left__menu__item ${
                      m.label === valMenu && "menu-item-active"
                    }`}
                    onClick={() => {
                      setValMenu(m.label);
                    }}
                  >
                    <img src={m.icon} alt={DEFAULT_IMG_ALT} />
                    {m.label}
                  </div>
                );
              })}
            </div>
          </Drawer>
        </div>
        <div
          className='setting-listing__content__right'
          style={{ width: visibleDrawer ? "calc(100% - 20.9rem)" : "100%" }}
        >
          <div className='setting-listing__content__right__action'>
            <div className='nav-header'>
              {valMenu === "Mail" &&
                NavHeaderMail.map((m, idx: number) => {
                  return (
                    <div
                      key={m.id + idx}
                      className={
                        m.value === selectTab
                          ? "tab tab-active btn--shiny"
                          : "tab"
                      }
                      onClick={() => {
                        setSelectTab(m.value);
                      }}
                    >
                      {m.label} ({count[idx]})
                    </div>
                  );
                })}
            </div>
            <TextField
              placeholder='Search by Name, Email'
              variant='outlined'
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon fontSize='large' />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
          {valMenu === "Mail" &&
            (selectTab === "email" ? (
              <MailListing
                setCount={(num: number) => {
                  setCount([num, count[1]]);
                }}
                searchValue={searchValue}
              />
            ) : (
              <TemplateMail />
            ))}
        </div>
      </div>

      {/* {valMenu === "Mail" && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            // setVisibleFloatingBtn(!visibleFloatingBtn);
            setMailModal(true);
          }}
          btns={
            [
              {
                color: "blue",
                title: "Create News",
                icon: (
                  <NewIcon
                    color={DEFAULT_COLOR}
                    size={[28, 28]}
                    viewBox={[28, 28]}
                  />
                ),
                onClick: (e) => {
                  setCreateMail(true);
                },
              },
              {
                color: "blueGreen",
                title: "Create Payroll",
                icon: (
                  <PayrollIcon
                    color={DEFAULT_COLOR}
                    size={[28, 28]}
                    viewBox={[28, 28]}
                  />
                ),
                onClick: (e) => {},
              },
            ]
          }
        />
      )} */}
    </div>
  );
};

export default Setting;
