import { Dialog, Divider, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "../../../../assets/images/avatar-df.png";
import { useQuery } from "../../../../hooks";
import { EmployeeService, TimesheetService } from "../../../../services";
import {
  checkLinkImage,
  currencyFormat,
  formatEnDE,
  formatMDY,
  minutes_to_hhmm,
  renderErrorImage,
} from "../../../../utils";
import { EditIcon } from "../../../components/Icons/EditIcon";
import { TimesheetSubIcon } from "../../../components/Icons/TimesheetSubIcon";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../../constants";
import { DEFAULT_COLOR } from "../../../core/Layout/routes";
import TimesheetModal from "../Modal";
import "./index.scss";

const TimesheetHistoryDetail: React.FC = () => {
  const query = useQuery();
  const [employeeDetail, setEmployeeDetail] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openEdit, setOpenEdit] = useState<any>(null);
  const [openDetail, setOpenDetail] = useState<any>(null);
  const [listEmployee, setListEmployee] = useState<any[]>([]);

  const fetchDataEmployee = async () => {
    try {
      const result = await EmployeeService.getEmployee();
      if (result.data.data) {
        setListEmployee(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      const result = await TimesheetService.getHistory({
        period: query.get("period") as string,
        employeeId: query.get("timeOfCycle")
          ? undefined
          : (query.get("id") as string),
      });
      if (result.data.data) {
        setEmployeeDetail(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataEmployee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line
    query.get("id"),
    // eslint-disable-next-line
    query.get("period"),
    // eslint-disable-next-line
    query.get("timeOfCycle"),
    openEdit,
  ]);

  const detail =
    query.get("id") && query.get("timeOfCycle") && query.get("period")
      ? employeeDetail
          ?.find((f) => f.timeOfCycle === query.get("timeOfCycle"))
          ?.employees.filter((e: any) => e._id === query.get("id"))?.[0]
      : employeeDetail?.[0]?.employees?.[0];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (detail?.salary.overtimes?.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const employee = listEmployee.find((f) => f._id === query.get("id"));

  return (
    <div className='time-sheet-history-detail'>
      <div>
        <div className='header-list-page time-sheet-history-detail-header'>
          <div
            className='header'
            style={{
              border: "0.1rem solid #E0E6F0",
              marginBottom: 9,
              borderRadius: 8,
            }}
          >
            <div className='time-sheet-history-detail-header__employee'>
              <div className='item-name'>
                <img
                  src={checkLinkImage(employee?.avatar) || Avatar}
                  alt={DEFAULT_IMG_ALT}
                  onError={renderErrorImage()}
                />
                <div>
                  {employee?.fullName || TEXT_HOLDER}
                  <div className='item-code'>
                    {employee?.code || TEXT_HOLDER}
                  </div>
                </div>
              </div>
            </div>
            <div className='time-sheet-history-detail-header__wage'>
              Wage($)
              <div className='val'>
                $
                {detail?.salary?.current
                  ? currencyFormat(detail?.salary?.current)
                  : TEXT_HOLDER}
              </div>
            </div>
            <div className='time-sheet-history-detail-header__ot'>
              OT Salary($)
              <button onClick={handleClick} className='val text-color'>
                View details
              </button>
            </div>
            <div className='time-sheet-history-detail-header__weekly'>
              Weekly Hours
              <div className='val'>
                {detail?.total?.weeklyHours
                  ? minutes_to_hhmm(detail?.total?.weeklyHours)
                  : TEXT_HOLDER}
              </div>
            </div>
            <div className='time-sheet-history-detail-header__paid'>
              Remaining Paid Leave Hours
              <div className='val'>
                {detail?.total?.paidLeaveHours
                  ? minutes_to_hhmm(detail?.total?.paidLeaveHours)
                  : "00:00"}
              </div>
            </div>
            <div className='time-sheet-history-detail-header__total'>
              Total Paid Absences
              <div className='val'>
                {detail?.total?.paidAbsence
                  ? minutes_to_hhmm(detail?.total?.paidAbsence)
                  : TEXT_HOLDER}
              </div>
            </div>
          </div>
          <div className='header'>
            <div className='time-sheet-history-detail__date'>Date</div>
            <div className='time-sheet-history-detail__checkin'>Checkin</div>
            <div className='time-sheet-history-detail__checkout'>Checkout</div>
            <div className='time-sheet-history-detail__break-time'>
              Break time
            </div>
            <div className='time-sheet-history-detail__regular'>
              Regular Hours
            </div>
            <div className='time-sheet-history-detail__overtime'>
              Overtime Hours
            </div>
            <div className='time-sheet-history-detail__total'>Total Hours</div>
            <div className='time-sheet-history-detail__edit'></div>
          </div>
        </div>

        {detail?.timekeepings?.map((t: any, idx: number) => {
          return (
            <div
              key={idx}
              className='item-list-page'
              onClick={() => setOpenDetail(t)}
            >
              <div className='time-sheet-history-detail__date'>
                {formatMDY(t?.date) || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__checkin'>
                {t?.checkin || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__checkout'>
                {t?.checkout || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__break-time'>
                {t?.breaktime?.total || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__regular'>
                {t?.regularHours || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__overtime'>
                {t?.overtimeHours || TEXT_HOLDER}
              </div>
              <div className='time-sheet-history-detail__total'>
                {t?.totalHours || TEXT_HOLDER}
              </div>
              <div
                className='time-sheet-history-detail__edit'
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenEdit(t);
                }}
              >
                <EditIcon
                  color={DEFAULT_COLOR}
                  size={[18, 18]}
                  viewBox={[18, 18]}
                />
              </div>
            </div>
          );
        })}

        <div className='time-sheet-history-detail__total-listing'>
          <div className='total'>
            <div className='time-sheet-history-detail__total-listing__title'>
              Total Time
            </div>
            <div className='time-sheet-history-detail__total-listing__col-1'>
              {detail?.total?.regularHours
                ? minutes_to_hhmm(detail?.total?.regularHours)
                : "00:00"}
            </div>
            <div className='time-sheet-history-detail__total-listing__col-2'>
              {detail?.total?.overtime
                ? minutes_to_hhmm(detail?.total?.overtime)
                : "00:00"}
            </div>
            <div className='time-sheet-history-detail__total-listing__col-3'>
              {detail?.total?.paidHours
                ? minutes_to_hhmm(detail?.total?.paidHours)
                : TEXT_HOLDER}
            </div>
          </div>
          <div className='total'>
            <div className='time-sheet-history-detail__total-listing__title'>
              Total Pay
            </div>
            <div className='time-sheet-history-detail__total-listing__col-1'>
              $
              {detail?.total?.weeklySalary
                ? currencyFormat(detail?.total?.weeklySalary)
                : TEXT_HOLDER}
            </div>
            <div className='time-sheet-history-detail__total-listing__col-2'>
              $
              {detail?.total?.overtimeSalary
                ? currencyFormat(detail?.total?.overtimeSalary)
                : TEXT_HOLDER}
            </div>
            <div className='time-sheet-history-detail__total-listing__col-3'>
              $
              {detail?.total?.salary
                ? currencyFormat(detail?.total?.salary)
                : TEXT_HOLDER}
            </div>
          </div>
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className='popover-ot'>
          {detail?.salary.overtimes.map((over: any, idx: number) => {
            return (
              <div key={idx} className='popover-ot__item'>
                <div className='popover-ot__item__ondays'>
                  {over.onDays.map((o: any, oIndex: number) => {
                    return (
                      <span key={oIndex}>
                        {o.charAt(0).toUpperCase() + o.slice(1)}
                        {over.onDays?.length !== oIndex + 1 && (
                          <span>,&nbsp;</span>
                        )}
                      </span>
                    );
                  })}
                </div>
                <div className='popover-ot__item__current'>
                  ${detail?.salary.current * over.multiplier}
                </div>
              </div>
            );
          })}
        </div>
      </Popover>

      {openEdit && (
        <TimesheetModal
          onClose={() => {
            setOpenEdit(null);
          }}
          data={openEdit}
          profile={detail}
        />
      )}

      <Dialog open={openDetail !== null} maxWidth='sm'>
        <div className='dialog-detail'>
          <SimpleModalHeader
            title='Details'
            onClose={() => {
              setOpenDetail(null);
            }}
          />
          <div className='dialog-detail__content'>
            <div className='dialog-detail__time'>
              <TimesheetSubIcon
                color={DEFAULT_COLOR}
                size={[18, 18]}
                viewBox={[20, 20]}
              />
              &emsp;{formatEnDE(openDetail?.date)}
            </div>
            <Divider
              style={{ borderColor: "#E0E6F0", margin: "1rem 0 1.8rem" }}
            />
            <div className='dialog-detail__table'>
              <div style={{ width: "70%" }}>
                <div className='dialog-detail__table__label'>Scan time</div>
                <div style={{ width: "70%", maxHeight: 200, overflow: "auto" }}>
                  {openDetail?.histories?.length > 0
                    ? openDetail?.histories?.map((h: any) => {
                        return (
                          <div className='dialog-detail__table__val'>{h}</div>
                        );
                      })
                    : TEXT_HOLDER}
                </div>
              </div>
              <div style={{ width: "30%" }}>
                <div className='dialog-detail__table__label'>Total Scan</div>
                <div className='dialog-detail__table__val'>
                  {openDetail?.histories?.length} times
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TimesheetHistoryDetail;
