import { Moment } from "moment";
import { useState } from "react";

const useDateRange = () => {
  const [value, setValue] = useState<{
    startTime: Moment;
    endTime: Moment;
  }>();

  const setTimerange = (start: Moment, end: Moment) => {
    if (start > end)
      throw new Error("start time must be earlier than end time");
    setValue(() => ({
      startTime: start,
      endTime: end,
    }));
  };
  return [value, setTimerange] as const;
};

export default useDateRange;
