import {
  MenuOpen as MenuOpenIcon,
  NotificationsNone as NotificationsNoneIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CSSObject,
  Theme,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material/styles";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/logo-n.png";
import Avatar from "../../../assets/images/avatar-df.png";
import { withProfile } from "../../../hoc/withProfile";
import { DEFAULT_IMG_ALT, TEXT_HOLDER } from "../../constants";
import "./index.scss";
import moduleList from "./routes";
import { APP_ROUTES } from "../../constants/module";
import SignOut from "../../containers/Authentication/SignOut";
import { renderErrorImage } from "../../../utils";
import SubNavbar from "./SubNavbar";

const openedMixin = (theme: Theme): CSSObject => ({
  width: 200,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - 200px)`,
    marginLeft: 200,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 200,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//eslint-disable-next-line
const LayoutComponent: React.FC = (props: any) => {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "rgba(0,0,0,0.75)",
            fontFamily: "Nunito Sans",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentRoute = React.useMemo(() => {
    const routes = moduleList.map(({ path }) => path).reverse();
    const selected = routes.find((route) => location.pathname.includes(route));
    return selected;
  }, [location.pathname]);

  const selectedRoute = React.useMemo(() => {
    const selected = moduleList.find((route) => currentRoute === route.path);
    return selected;
  }, [currentRoute]);

  //button logout
  const [anchorUserEl, setAnchorUserEl] = useState<HTMLElement | null>(null);
  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position='fixed' open={open} className='app-bar'>
        <Toolbar className='app-toolbar'>
          <div className='app-toolbar-left'>
            <IconButton
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                marginRight: "36px",
                ...(open && { display: "none !important" }),
              }}
            >
              <MenuOpenIcon style={{ fontSize: "2.8rem", color: "#EFFDFF" }} />
            </IconButton>
            <Typography variant='h6' noWrap component='div' className='title'>
              {selectedRoute?.title || TEXT_HOLDER}
            </Typography>
          </div>
          <div className='app-toolbar-right'>
            <div className='notification'>
              <NotificationsNoneIcon
                style={{
                  color: "#00529C",
                  transform: "rotate(35deg)",
                  fontSize: "3rem",
                }}
              />
            </div>
            <div className='menu-user'>
              <img
                src={Avatar}
                alt={DEFAULT_IMG_ALT}
                onClick={handleOpenUserMenu}
                onError={renderErrorImage()}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#00529C",
            color: "#ffffff",
          },
        }}
      >
        <DrawerHeader>
          <img className='DrawerHeader_img' src={Logo} alt={DEFAULT_IMG_ALT} />
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon style={{ fontSize: "2.8rem", color: "#EFFDFF" }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ul className='nav-list'>
          {moduleList.map(({ label, title, defaultIcon, activeIcon, path }) => {
            const selected = currentRoute === path;
            return (
              <Link key={label} to={path}>
                <li
                  className={`${"nav-item"} ${
                    path === APP_ROUTES.SETTING ? "away" : ""
                  }`}
                  style={{ padding: open ? "0.5rem" : "0" }}
                >
                  {open ? (
                    <button
                      role='tab'
                      className={
                        selected
                          ? "btn-icon btn--shiny btn-icon-active"
                          : "btn-icon"
                      }
                      aria-selected={selected}
                    >
                      <span>{selected ? activeIcon : defaultIcon}</span>
                    </button>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip
                        arrow
                        placement='right-end'
                        title={<span>{title}</span>}
                      >
                        <button
                          role='tab'
                          className={
                            selected
                              ? "btn-icon btn--shiny btn-icon-active"
                              : "btn-icon btn-hover"
                          }
                          aria-selected={selected}
                        >
                          <span>{selected ? activeIcon : defaultIcon}</span>
                        </button>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                  <div className='label'>{label}</div>
                </li>
              </Link>
            );
          })}
        </ul>
      </Drawer>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          background: "#F0F3F8",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <DrawerHeader />
        {selectedRoute?.subRoutes && selectedRoute?.subRoutes?.length > 0 && (
          <SubNavbar routes={selectedRoute?.subRoutes} />
        )}
        <div className='layout__content__body'>{props.children}</div>
      </Box>

      <SignOut
        onClose={handleCloseUserMenu}
        anchorEl={anchorUserEl}
        avatarImg={Avatar}
      />
    </Box>
  );
};
export default withProfile(LayoutComponent);
// export default LayoutComponent;
