export const APP_ROUTES = {
  HOME: "/",

  SIGN_IN: "/sign-in",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",

  PROFILE: "/profile",

  STAFF: "/staff",
  STAFF_DETAIL: "/staff-detail/:staffId",
  STAFF_CREATE: "/staff-create",
  STAFF_EDIT: "/staff-edit/:staffId",

  TIMESHEET: "/timesheet",
  TIMESHEET_TIMEKEEPING: "/timesheet-timekeeping",

  SETTING: "/setting",
} as const;
